
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import ContactUsButton from './ContactUsButton';
import HeroComponent from './HeroComponent';
import TechnologyAlliances from "./TechnologyAlliances";
import MarqueeComponent from "./MarqueeComponent";
import SuccessStoriesList from "./SuccessStoriesList";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.Manufacturing}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();


function Manufacturing () {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);
    const [activePanel, setActivePanel] = useState(null);
    const togglePanel = (panelId) => {
        setActivePanel(activePanel === panelId ? null : panelId);
    };
    var left_index = 0;
    return (
        <div data-aos="fade-up" className='manufacturing'>
            <Helmet>
                <meta name="description" content="Unlocking infinite value in Manufacturing with Digital Transformation and Automation" />
                <title>Manufacturing | Industries | Engineersmind</title>
            </Helmet>

            <HeroComponent
                bgImageMobile={textData.layer1.mobilebannerimg}
                bgImage={textData.layer1.img}
                textContent={
                    <h1>
                        {textData.layer1.heading.text1}{" "}
                        <span>
                            {textData.layer1.heading.text2}
                            <br /> {textData.layer1.heading.text3}
                        </span>
                        {textData.layer1.heading.text4}
                        <br />
                        {textData.layer1.heading.text5}
                    </h1>
                }
                breadcrumbs={
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home </a></li>
                        <li className="breadcrumb-item"><a href="/Industries/Financial-Services">Industries </a></li>
                        <li className="breadcrumb-item active" aria-current="page">Manufacturing</li>
                    </ol>
                }
            />

            <div className="container-fluid m-0 caseStudyContent">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <section>
                                <h1>{textData.introduction.title}</h1>
                                <div className="row introductionsection digitalPortals-left-img-row">
                                    <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                                        <img
                                            className="AIChatbot-introduction-img"
                                            src={textData.introduction.img}
                                            alt="overview-img"
                                        />
                                    </div>
                                    <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                                        <div className="p-0">
                                            <p text-20>{textData.introduction.description1}</p>
                                        </div>
                                    </div>
                                    </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid digitalPortals bg-color">
                <div className="container">
                    <div className="row digitalPortals-right-img-row">
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                            <h1>
                                <span>
                                    {textData.redefiningDigitalPortalsforManufacturers.title}
                                </span>
                            </h1>
                            <div className="p-0 text-20">
                                <p>
                                    {
                                        textData.redefiningDigitalPortalsforManufacturers
                                        .description1
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                            <img
                                src={textData.redefiningDigitalPortalsforManufacturers.img}
                                className="img-fluid digitalPortals-left-img"
                                alt="Resilient_solutions"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid digitalPortals">
                <div className="container">
                    <h1>
                        <span>{textData.selfServiceCustomerPortals.title}</span>
                    </h1>
                    <div className="row digitalPortals-left-img-row">
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                            <img
                                src={textData.selfServiceCustomerPortals.img}
                                className="img-fluid digitalPortals-left-img"
                                alt="Resilient_solutions"
                            />
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                            <div className="p-0 text-20">
                                <p>{textData.selfServiceCustomerPortals.description1}</p>
                            </div>
                            <div className="pt-2 text-20">
                                <ul className="digitalPortals-list">
                                    {textData.selfServiceCustomerPortals.points.map(
                                        (point, index) => {
                                            return (
                                                <li className="digitalPortals-list-point" key={index}>
                                                    <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                                                    <span dangerouslySetInnerHTML={{ __html: point.details }} />
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            </div>
                            <div className="pt-2 text-20">
                                <p>{textData.selfServiceCustomerPortals.description2}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid digitalPortals">
                <div className="container">
                    <h1>
                        <span>
                            {textData.selfServiceVendorPortals.title}
                        </span>
                    </h1>
                    <div className="row digitalPortals-right-img-row">
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                            <div className="p-0 text-20">
                                <p>{textData.selfServiceVendorPortals.description1}</p>
                            </div>
                            <div className="pt-2 text-20">
                                <ul className="digitalPortals-list">
                                {textData.selfServiceVendorPortals.points.map(
                                    (point, index) => {
                                    return (
                                        <li className="digitalPortals-list-point" key={index}>
                                            <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                                            <span dangerouslySetInnerHTML={{ __html: point.details }} />
                                        </li>
                                    );
                                    }
                                )}
                                </ul>
                            </div>
                            <div className="pt-2 text-20">
                                <p>{textData.selfServiceVendorPortals.description2}</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                            <img
                                src={textData.selfServiceVendorPortals.img}
                                className="img-fluid digitalPortals-left-img"
                                alt="Resilient_solutions"
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid digitalPortals">
                <div className="container">
                    <h1>
                        <span>{textData.employeePortals.title}</span>
                    </h1>
                    <div className="row digitalPortals-left-img-row">
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                            <img
                                src={textData.employeePortals.img}
                                className="img-fluid digitalPortals-left-img"
                                alt="Resilient_solutions"
                            />
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                            <div className="p-0 text-20">
                                <p>{textData.employeePortals.description1}</p>
                            </div>
                            <div className="pt-2 text-20">
                                <ul className="digitalPortals-list">
                                {textData.employeePortals.points.map(
                                    (point, index) => {
                                    return (
                                        <li className="digitalPortals-list-point" key={index}>
                                            <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                                            <span dangerouslySetInnerHTML={{ __html: point.details }} />
                                        </li>
                                    );
                                    }
                                )}
                                </ul>
                            </div>
                            <div className="pt-2 text-20">
                                <p>{textData.employeePortals.description2}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid digitalPortals">
                <div className="container">
                    <h1>
                        <span>
                            {textData.driveContinuousImprovement.title}
                        </span>
                    </h1>
                    <div className="row digitalPortals-right-img-row">
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                            <div className="p-0 text-20">
                                <p>{textData.driveContinuousImprovement.description1}</p>
                            </div>
                            <div className="pt-2 text-20">
                                <ul className="digitalPortals-list">
                                {textData.driveContinuousImprovement.points.map(
                                    (point, index) => {
                                    return (
                                        <li className="digitalPortals-list-point" key={index}>
                                            <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                                            <span dangerouslySetInnerHTML={{ __html: point.details }} />
                                        </li>
                                    );
                                    }
                                )}
                                </ul>
                            </div>
                            <div className="pt-2 text-20">
                                <p>{textData.driveContinuousImprovement.description2}</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                            <img
                                src={textData.driveContinuousImprovement.img}
                                className="img-fluid digitalPortals-left-img"
                                alt="Resilient_solutions"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid digitalPortals bg-color">
                <div className="container">
                    <div className="row digitalPortals-right-img-row">
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                            <h1>
                                <span>
                                    {textData.unlockingValuewithData.title}
                                </span>
                            </h1>
                            <div className="p-0 text-20">
                                <p>
                                {
                                    textData.unlockingValuewithData
                                    .description1
                                }
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                            <img
                                src={textData.unlockingValuewithData.img}
                                className="img-fluid digitalPortals-left-img"
                                alt="Resilient_solutions"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid digitalPortals">
                <div className="container">
                    <h1>
                        <span>{textData.dataAcquisition.title}</span>
                    </h1>
                    <div className="row digitalPortals-left-img-row">
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                            <img
                                src={textData.dataAcquisition.img}
                                className="img-fluid digitalPortals-left-img"
                                alt="Resilient_solutions"
                            />
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                            <div className="p-0 text-20">
                                <p>{textData.dataAcquisition.description1}</p>
                            </div>
                            <div className="pt-2 text-20">
                                <ul className="digitalPortals-list">
                                {textData.dataAcquisition.points.map(
                                    (point, index) => {
                                    return (
                                        <li className="digitalPortals-list-point" key={index}>
                                            <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                                            <span dangerouslySetInnerHTML={{ __html: point.details }} />
                                        </li>
                                    );
                                    }
                                )}
                                </ul>
                            </div>
                            <div className="pt-2 text-20">
                                <p>{textData.dataAcquisition.description2}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid digitalPortals">
                <div className="container">
                    <h1>
                        <span>
                            {textData.analyticsMachineLearning.title}
                        </span>
                    </h1>
                    <div className="row digitalPortals-right-img-row">
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                            <div className="p-0 text-20">
                                <p>{textData.analyticsMachineLearning.description1}</p>
                            </div>
                            <div className="pt-2 text-20">
                                <ul className="digitalPortals-list">
                                {textData.analyticsMachineLearning.points.map(
                                    (point, index) => {
                                    return (
                                        <li className="digitalPortals-list-point" key={index}>
                                            <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                                            <span dangerouslySetInnerHTML={{ __html: point.details }} />
                                        </li>
                                    );
                                    }
                                )}
                                </ul>
                            </div>
                            <div className="pt-2 text-20">
                                <p>{textData.analyticsMachineLearning.description2}</p>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                            <img
                                src={textData.analyticsMachineLearning.img}
                                className="img-fluid digitalPortals-left-img"
                                alt="Resilient_solutions"
                            />
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid digitalPortals">
                <div className="container">
                    <h1>
                        <span>{textData.enhancedAfterSalesService.title}</span>
                    </h1>
                    <div className="row digitalPortals-left-img-row">
                        <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                            <img
                                src={textData.enhancedAfterSalesService.img}
                                className="img-fluid digitalPortals-left-img"
                                alt="Resilient_solutions"
                            />
                        </div>
                        <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                            <div className="p-0 text-20">
                                <p>{textData.enhancedAfterSalesService.description1}</p>
                            </div>
                            <div className="pt-2 text-20">
                                <ul className="digitalPortals-list">
                                {textData.enhancedAfterSalesService.points.map(
                                    (point, index) => {
                                    return (
                                        <li className="digitalPortals-list-point" key={index}>
                                            <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                                            <span dangerouslySetInnerHTML={{ __html: point.details }} />
                                        </li>
                                    );
                                    }
                                )}
                                </ul>
                            </div>
                            <div className="pt-2 text-20">
                                <p>{textData.enhancedAfterSalesService.description2}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <SuccessStoriesList  StoriesList={textData.storiesList} />

            <div className="container-fluid whyChoose digitalPortals">
                <div className="container">
                    <h1>
                        <span>{textData.WhyChooseEngineersmind.title}</span>
                    </h1>
                    {/*<div className="d-flex justify-content-center">
                        <img
                            className="AIChatbot-introduction-img"
                            src={textData.WhyChooseEngineersmind.img}
                            alt="overview-img"
                        />
                    </div>*/}
                    <p className="mb-4 text-20">
                        {textData.WhyChooseEngineersmind.description1}
                    </p>
                </div>
            </div>

            <div className="alliances d-flex flex-column justify-content-center align-items-center ">
                <h1 className="text-white text-center">
                {textData.layer10.heading.text1}
                <span>{textData.layer10.heading.text2}</span>
                {textData.layer10.heading.text3}
                </h1>
                <ContactUsButton
                text={textData.layer10.button.text}
                link="#ContactForm"
                className="light"
                />
            </div>

            <TechnologyAlliances data={textData.alliacessection} />

            <MarqueeComponent assets={textData.clients} />

            <div className="container-fluid ContactForm" id="ContactForm">
                <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ContactForm />
                    </div>
                </div>
                </div>
            </div>

        </div>
    )
}

export default Manufacturing;

