import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import MarqueeComponent from "./MarqueeComponent";
import ReactPlayer from "react-player";
import HeroComponent from "./HeroComponent";
import ContactForm from "./ContactForm";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.Body}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Body() {
  useEffect(() => {
    AOS.init();
  }, []);

  const renderFullPageSection = (heading, data, index) => {
    return (
      <div className="full-page-container">
        <div className="container inner-container">
          <div className="content col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <p style={{ marginLeft: index == 2 && "auto" }} className="title">
              {heading}
            </p>
            <p data-is-light-text={index % 2 === 0}>{data.body.text1}</p>
            <p data-is-light-text={index % 2 === 0}>{data.body.text2}</p>
            <p data-is-light-text={index % 2 === 0}>{data.body.text3}</p>
            <ContactUsButton
              isLightText={index % 2 === 0 ? true : false}
              link={data.button.link}
              text={data.button.text}
              className={`${index % 2 === 0 ? 'dark' : 'light'} mt-3`}
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 image-wrapper">
            <img src={data.img} alt="img" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div data-aos="fade-up" className="homePage">
      <Helmet>
        <meta
          name="description"
          content="Reimagine and Transform your Enterprise, Empower your organization with Data and AI, EM Tech Talent Solutions Your gateway to innovation, excellence & growth"
        />
        <title>About Us Page</title>
      </Helmet>


      <HeroComponent isSlider={true} isBreadCumb={false} textData={textData} isBannerLogo={true} />
      
      {/* <div className="container-fluid p-0">
        <div id="carouselExampleAutoplaying" className="carousel slide">
          <div className="carousel-inner">
            {textData.editable_carousel_banner.map((item, index) => {
              if (index === 0) {
                return (
                  <div
                    className={"carousel-item carousel-item-"
                      .concat(String.toString(index + 1))
                      .concat(" active")}
                  >
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <img
                          src={item.img}
                          className="d-block w-100"
                          alt="banner-img"
                        />
                        <div className="banner-text">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <h1 className="animate__animated animate__fadeInLeft">
                                  <span>{item.heading.text1}</span>
                                  {item.heading.text2}
                                  <br /> <span>{item.heading.text3}</span>
                                  {item.heading.text4}
                                  <br />
                                  {item.heading.text5}
                                </h1>
                                <a
                                  href={item.button.url}
                                  className="animate__animated animate__fadeInLeft"
                                >
                                  {item.button.text}{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="banner-logos">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <img
                                  src={item.banner.img}
                                  className="img-fluid"
                                  alt="banner-logo"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className={"carousel-item carousel-item-".concat(
                      String.toString(index + 1)
                    )}
                  >
                    <div className="row">
                      <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <img
                          src={item.img}
                          className="d-block w-100"
                          alt="banner-img"
                        />
                        <div className="banner-text">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                <h1 className="animate__animated animate__fadeInLeft">
                                  <span>{item.heading.text1}</span>
                                  {item.heading.text2}
                                  <br /> <span>{item.heading.text3}</span>{" "}< br />
                                  {item.heading.text4}
                                  <br />
                                  {item.heading.text5}
                                </h1>
                                <a
                                  href={item.button.url}
                                  className="animate__animated animate__fadeInLeft"
                                >
                                  {item.button.text}{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="banner-logos">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                <img
                                  src={item.banner.img}
                                  className="img-dluid"
                                  alt="banner-logo"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
          <div className="control-btn-2">
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <i className="fa-solid fa-arrow-right"></i>
              <span
                className="carousel-control-next-icon visually-hidden"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon visually-hidden"
                aria-hidden="true"
              ></span>
              <i className="fa-solid fa-arrow-left"></i>
              <span className="visually-hidden">Previous</span>
            </button>
          </div>
        </div>
      </div> */}

      <div className="sticky-layers">
        {renderFullPageSection(
          textData.layer2.subheadings.subheading1,
          textData.layer2.details.detail1,
          1
        )}
        {renderFullPageSection(
          textData.layer2.subheadings.subheading2,
          textData.layer2.details.detail2,
          2
        )}
        {renderFullPageSection(
          textData.layer2.subheadings.subheading3,
          textData.layer2.details.detail3,
          3
        )}
      </div>

      <div className="services-container">
        <div className="video-wrapper">
          <ReactPlayer
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: -1,
              overflow: "hidden",
            }}
            url={textData.services.backgroundVideo}
            playing={true}
            muted={true}
            loop={true}
            controls={false}
            config={{
              file: {
                attributes: {
                  playsInline: true,
                },
              },
            }}
          />
        </div>
        <div className="blur-layer"></div>
        <div className="container list-container d-flex flex-column h-100">
          <h1 className="heading">{textData.services.heading}</h1>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-6 d-flex align-items-center">
              <div className="boxes">
                {textData.services.list.map((item, index) => (
                  <div
                    className="list-item"
                    onClick={() => window.open(item.link, "_blank")}
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-6 d-flex flex-column justify-content-center align-items-center">
              <h1 className="tagline">{textData.services.tagline}</h1>
              <ContactUsButton className="dark" isLightText={true} link={'#ContactForm'} />
            </div>
          </div>
        </div>
      </div>

      <div className="full-height-section alliances-container">
        <div className="d-flex flex-column h-100 py-5">

          <div className="sub-section">
            <h1>{textData.layer9.heading1}</h1>
            <img src={textData.layer9.img1} alt="logos" className="img-fluid" />
          </div>
          <div className="sub-section">
            <h1>{textData.layer9.heading2}</h1>
            <img src={textData.layer9.img2} alt="logos" className="img-fluid web" />
            <img src={textData.layer9.imgMobile} alt="logos" className="img-fluid mobile" />
          </div>
        </div>
      </div>

      <div className="alliances d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-white text-center">
          {textData.layer8.heading.text1}
          <span>{textData.layer8.heading.text2}</span>
          {textData.layer8.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.layer8.button.text}
          link="#ContactForm"
          className="light"
        />
      </div>


      {/* EM value */}
      <div className="container-fluid full-height-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
              <div className="value-text">
                <h1>
                  <span>{textData.layer7.heading}</span>
                </h1>
                <h3>
                  {textData.layer7.heading2.text1}
                  <br />
                  {textData.layer7.heading2.text2}
                </h3>
                &nbsp;
                <p>
                  {textData.layer7.heading3.text1}
                  <br />
                  {textData.layer7.heading3.text2}
                </p>
                <br />
                <a href={textData.layer7.button.link}>
                  {textData.layer7.button.text}{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
              <div className="value-img">
                <img
                  src={textData.layer7.img}
                  className="img-fluid"
                  alt="intro-img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid testi-home">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
              <h1 className="mb-5">{textData.layer4.heading}</h1>
            </div>
            <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
              <div id="carouselExampleCaptions" className="carousel slide">
                <div className="carousel-inner">
                  {textData.layer4.editable_carousel.map((item, index) => {
                    if (index === 0) {
                      return (
                        <div
                          className={"carousel-item carousel-item-"
                            .concat(String.toString(index + 1))
                            .concat(" active")}
                        >
                          <div>
                            <p className="main-text">
                              <i className="fa-solid fa-quote-left"></i>
                              {item.testimony}
                              <i className="fa-solid fa-quote-right"></i>
                            </p>
                            &nbsp;
                            <p className="bottom-text">
                              <b>{item.name}</b>
                              <br />
                              <i>{item.post}</i>
                            </p>
                            <p className="morecss">
                              <a href={item.button.link}>
                                {item.button.text}{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={"carousel-item carousel-item-".concat(
                            String.toString(index + 1)
                          )}
                        >
                          <div>
                            <p className="main-text">
                              <i className="fa-solid fa-quote-left"></i>
                              {item.testimony}
                              <i className="fa-solid fa-quote-right"></i>
                            </p>
                            &nbsp;
                            <p className="bottom-text">
                              <b>{item.name}</b>
                              <br />
                              <i>{item.post}</i>
                            </p>
                            <p className="morecss">
                              <a href={item.button.link}>
                                {item.button.text}{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                              </a>
                            </p>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="buttoncss">
                  <div className="control-btn-3">
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleCaptions"
                      data-bs-slide="next"
                    >
                      <i className="fa-solid fa-arrow-right"></i>
                      <span
                        className="carousel-control-next-icon visually-hidden"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleCaptions"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon visually-hidden"
                        aria-hidden="true"
                      ></span>
                      <i className="fa-solid fa-arrow-left"></i>
                      <span className="visually-hidden">Previous</span>
                    </button>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid ContactForm" id='ContactForm'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
    </div>
  );
}

export default Body;
