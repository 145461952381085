import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import { Helmet } from 'react-helmet';
import HeroComponent from "./HeroComponent";
import ZigzagCarousel from "./ZigzagCarousel";
import ReactPlayer from "react-player";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.AboutUs}?v=${config.VERSION}`;
//const textDataUrl = page_json.page_jsons.AboutUs;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

const contactLocsUrl = `${page_json.page_jsons.Contact}?v=${config.VERSION}`;
//const contactLocsUrl = page_json.page_jsons.Contact;
const contactLocsResponse = await fetch(contactLocsUrl);
const contactLocs = await contactLocsResponse.json();

const textDataUrlAlliances = `${page_json.page_jsons.Body}?v=${config.VERSION}`;
//const textDataUrlAlliances = page_json.page_jsons.Body;
const textDataResponseAlliances = await fetch(textDataUrlAlliances);
const textDataAlliances = await textDataResponseAlliances.json();

function AboutUs() {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);
    

    //var left_index = 0;
    
    return (
        <div data-aos="fade-up" className="aboutuspg">
            <Helmet>
                <meta name="description" content="Engineersmind is an American company specializiing in IT Solutions and Engineering services across industries. We customize solutions for businesses with digital engineering, automation, design and consulting, modernization and cloud transformation for organizations. Our solutions are core to your business, our integrated and tailored solutions advance your Enterprise to new heights. Our strategy and architecture fuels your competitive spirit and innovation. We forge robust partnership that will kindle a new future for you." />
                <title>About Us | Engineersmind</title>
            </Helmet>
            {/* <div className="container-fluid m-0 p-0 brd-crmb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div> */}

            <HeroComponent
                bgImageMobile={textData.layer1.mobilebannerimg}
                bgImage={textData.layer1.img}
                textContent={
                    <>
                        <p className='mb-4'>{textData.layer1.body}</p>
                        <h1>{textData.layer1.heading.text1}<br /> {textData.layer1.heading.text2} <span>{textData.layer1.heading.text3}<br /> {textData.layer1.heading.text4}</span> {textData.layer1.heading.text5}<br /> <span>{textData.layer1.heading.text6}</span></h1>
                        </>
                    }
                    breadcrumbs={
                        <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home </a></li>
                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                    </ol>
                }
            />

            {/* <div className="container-fluid p-0">
                <div className="inner-banner">
                    <img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
                    <div className="inner-banner-text about-us">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <p>{textData.layer1.body}</p>
                                    <h1 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1}<br /> {textData.layer1.heading.text2} <span>{textData.layer1.heading.text3}<br /> {textData.layer1.heading.text4}</span> {textData.layer1.heading.text5}<br /> <span>{textData.layer1.heading.text6}</span></h1>
                                    <ContactUsButton />
                                </div>
                                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/} 

            {/*<div className="container-fluid p-0">
                <img src={textData.layer2.img} className="d-block w-100" alt="banner-img" />
                <div className="container">
                    <div className="wwd">
                        <p>{textData.layer2.body}</p>
                        <h1><span>{textData.layer2.heading.text1}</span> {textData.layer2.heading.text2}</h1>
                    </div>
                </div>
            </div>*/} 
            
            <div className="container-fluid solution">
                <div className="container">
                    <div className="solutions">
                        <p><b>{textData.layer4.heading}</b></p>&nbsp;
                        <p>{textData.layer4.body1}</p>&nbsp;
                        <p>{textData.layer4.body2}</p>&nbsp;
                        <p>{textData.layer4.body3}</p>&nbsp;
                    </div>
                </div>
            </div>

            {/*<div className="alliances d-flex flex-column justify-content-center align-items-center">
                <h1 className="text-white text-center">
                    {textData.layer7.heading.text1}
                    <span>{textData.layer7.heading.text2}</span>
                    {textData.layer7.heading.text3}
                </h1>
                <ContactUsButton
                text={textData.layer7.button.text}
                link={textData.layer7.button.url}
                className="light"
                />
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="ai-text-4">
                            <p><b>{textData.layer3.body}</b></p>
                            <h1>{textData.layer3.heading}</h1>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div className="ai-img">
                            <img src={textData.layer3.img} className="img-fluid" alt="industry" />
                        </div>
                    </div>
                </div>
            </div> 

            <div className="container-fluid solution">
                <div className="container">
                    <div className="solutions">
                        <p>{textData.layer4.body1}</p>&nbsp;
                        <p>{textData.layer4.body2}</p>&nbsp;
                        <p>{textData.layer4.body3}</p>&nbsp;
                    </div>
                </div>
            </div>*/}

            <div className="container-fluid leadership">
                <div className="container">
                    <div className="leadership">
                        <h1>{textData.layer5.heading.text1} <span>{textData.layer5.heading.text2}</span></h1>
                    </div>
                    <div className="row">
                        {
                            //textData.layer5.editableList_leadership.map(function(item){
                            textData.layer5.editableList_leadership.slice(0, 3).map(function(item) {
                                return(
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                                        <div className="leader-img">
                                            <div class="image-section-main">
                                                <div class="linkedin-icon">
                                                    <a href={item.linkedin} target="_blank" rel="noreferrer">
                                                        <img alt="" className="linkedinonhover" src="https://engineersmind-websitecontent.s3.amazonaws.com/linkedin.svg" />
                                                    </a>
                                                </div>
                                                <figure>
                                                    <picture>
                                                        <img alt="" class="main-image webpexpress-processed" src={item.img} />
                                                    </picture>
                                                </figure>
                                            </div>
                                            {/* <img src={item.img} className="img-fluid" alt="industry" /> */}
                                            <h5 className='mt-2'>{item.name}</h5>
                                            <p className='leaderpost'><i>{item.post}</i></p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

            <div className="container-fluid Allleadership leadership">
                <div className="container">
                    <div className="row">
                        {
                            //textData.layer5.editableList_leadership.map(function(item){
                            textData.layer5.editableList_leadership.slice(3).map(function(item) {
                                return(
                                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 mb-3">
                                        <div className="leader-img">
                                            <div class="image-section-main">
                                                <div class="linkedin-icon">
                                                    <a href={item.linkedin} target="_blank" rel="noreferrer">
                                                        <img alt="" className="linkedinonhover" src="https://engineersmind-websitecontent.s3.amazonaws.com/linkedin.svg" />
                                                    </a>
                                                </div>
                                                <figure>
                                                    <picture>
                                                        <img alt="" class="main-image webpexpress-processed" src={item.img} />
                                                    </picture>
                                                </figure>
                                            </div>
                                            {/* <img src={item.img} className="img-fluid" alt="industry" /> */}
                                            <h5 className='mt-2'>{item.name}</h5>
                                            <p className='leaderpost'><i>{item.post}</i></p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <br/>
                    <a class="contact-submit-button" href={textData.layer5.clickbutton.url}>{textData.layer5.clickbutton.text} <i className="fa-solid fa-arrow-right"></i></a>
                </div>
            </div>

            <div className="container-fluid zigzagCarousel">
                <div className="container">
                    <div>
                        <h1>{textData.layer9.heading.text1} <span>{textData.layer9.heading.text2}</span></h1>
                    </div>
                    <div className="zigzagCarousel">
                        <ZigzagCarousel images={textData.layer9.carouselImages} />
                    </div>
                </div>
            </div>

            <div className="full-height-section alliances-container">
                <div className="d-flex flex-column h-100 py-5">
                    <div className="sub-section">
                        <h1>{textDataAlliances.layer9.heading1}</h1>
                        <img
                        src={textDataAlliances.layer9.img1}
                        alt="logos"
                        className="img-fluid"
                        />
                    </div>
                    {/*<div className="sub-section">
                        <h1>{textDataAlliances.layer9.heading2}</h1>
                        <img
                        src={textDataAlliances.layer9.img2}
                        alt="logos"
                        className="img-fluid web"
                        />
                        <img
                        src={textDataAlliances.layer9.imgMobile}
                        alt="logos"
                        className="img-fluid mobile"
                        />
                    </div> */}
                </div>
            </div>

<div className="services-container">
<div className="sub-section">
        <div className="video-wrapper">
          <ReactPlayer
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: 0,
              left: 0,
              zIndex: -1,
              overflow: "hidden",
            }}
            url={textData.services.backgroundVideo}
            playing={true}
            muted={true}
            loop={true}
            controls={false}
            config={{
              file: {
                attributes: {
                  playsInline: true,
                },
              },
            }}
          />
        </div>
        <div className="blur-layer"></div>
        <div className="container list-container d-flex flex-column h-100">
          <h1 className="heading">{textData.services.heading}</h1>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-6 d-flex align-items-center">
              <div className="boxes">
                {textData.services.list.map((item, index) => (
                  <div
                    className="list-item"
                  >
                    {item.title}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-6 d-flex flex-column justify-content-center align-items-center">
              <p className="tagline">{textData.services.tagline} <span className="taglinetxt">{textData.services.tagline1}</span></p>
            </div>
          </div>
        </div>
      </div>
      </div>

            <div className="container-fluid aboutusloc loc-img">
		    	<div className="container">
					<h5>{contactLocs.layer3.heading}</h5>
					<div className="row locationimages">
						{
							contactLocs.layer3.editableList_locations.map(function(item){
						    	return(
									<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
									    <div className="location">
											<img src={item.img} alt="gif" className="img-fluid" />
											<div class="hover-text">
												<div class="loc_cityname">{item.name}</div>
												<p><span>{item.address_heading}</span></p>
												<p>{item.address1}</p>
												<p>{item.address2}</p>
												<p>{item.address3}</p>
												<p>{item.address4}</p>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
            {/* <div className="container-fluid trust">
                <div className="container trusted-img">
                    <div className="row">
                    <h1>{textData.layer6.heading}</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <img src={textData.layer6.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div> */}
            

            {/*<MarqueeComponent assets={textDataAI.clients} />*/}
            {/*<div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                            <div className="heading">
                            <h1><span>{textData.layer7.heading.text1}</span> {textData.layer7.heading.text2} <span>{textData.layer7.heading.text3}</span></h1>
                            </div>
                            <div className="more-2">
                            <a href={textData.layer7.button.url}>{textData.layer7.button.text} <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                            <div className="gif">
                            <img src={textData.layer7.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid ContactForm" id="ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                        <h1>{textData.layer8.heading}</h1>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={textData.layer8.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid ContactForm" id="ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>*/}
        </div>
    )

}
export default AboutUs;