import React from "react";
//import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import App from "./App";
import Error from "./components/Error";
import Body from "./components/Body";
import Healthcare from "./components/healthcare";
import Salesforce from "./components/Salesforce";
import AboutUs from "./components/AboutUs";
import Resources from "./components/Resources";
import "./App.css";
import Contact from "./components/Contact";
import FinancialServices from "./components/FinancialServices";
import TechTalent from "./components/TechTalent";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfUse from "./components/TermsOfUse";
import Manufacturing from "./components/Maufacturing";
import DevOps from "./components/Devops";
import Stack from "./components/Stack";
import Blogs from "./components/Blogs";
import News from "./components/News";
import Liferay from "./components/Liferay";
import ItServices from "./components/ItServices";
import SecurityServices from "./components/SecurityServices";
import FinancialCs2 from "./components/FinancialCs2";
import FinancialCs1 from "./components/FinancialCs1";
import HealthcareCs1 from "./components/HealthcareCs1";
import Careers from "./components/Careers";
import DigitalTransformation from "./components/DigitalTransformation";
import AiDataServices from "./components/AiDataService";
import Leadership from "./components/Leadership";
import Team from "./components/Team";
import Alliances from "./components/Alliances";
// import TechServices from "./components/TechServices";
import ManufacturingEC from "./components/MaufacturingEC";
import ManufacturingPIM from "./components/ManufacturingPIM";
import CharitableOrganization from "./components/CharitableOrganisation";
import Testimonials from "./components/Testimonials";
import AdminPage from "./components/Admin";
import Login from "./components/login";
import EditorPage from "./components/Editor";
import ContentManagementSystem from "./components/ContentManagementSystem";
import LiferayDXP from "./components/LiferayDXP";
import AIChatbot from "./components/AIChatbot";
import HealthBus from "./components/HealthBus";
import MSAL from "./components/MSAL";
import JWKS from "./components/JWKS";
import LiferayDashboard from "./components/LiferayDashboard"
import AIInHealthcare from "./components/AIInHealthcare";

const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Body />, // dynamic ready
      },
      {
        path: "/about-us",
        element: <Navigate to="/About" replace />,
      },
      {
        path: "/about-us/our-projects",
        element: <Navigate to="/About" replace />,
      },
      {
        path: "/what-we-do",
        element: <Navigate to="/About" replace />,
      },
      {
        path: "/About",
        element: <AboutUs />, // dynamic ready
      },
      {
        path: "/what-we-do/product-engineering",
        element: <Navigate to="/About" replace />,
      },
      {
        path: "/ideas/cloud-migration-for-financial-services",
        element: <Navigate to="/Industries/Financial-Services" replace />,
      },
      {
        path: "/Industries/FinancialServices",
        element: <Navigate to="/Industries/Financial-Services" replace />,
      },
      {
        path: "/what-we-do/startup-it/fintech-platform",
        element: <Navigate to="/Industries/Financial-Services" replace />,
      },
      {
        path: "/Industries/Financial-Services",
        element: <FinancialServices />, // dynamic ready
      },
      {
        path: "/Resources",
        element: <Resources />, // dynamic Not Needed
      },
      {
        path: "/Contact",
        element: <Contact />, // dynamic ready
      },
      {
        path: "/FinancialServices/ContentManagementSystem",
        element: (
          <Navigate
            to="/Financial-Services/Financial-Services-Portal"
            replace
          />
        ),
      },
      {
        path: "/FinancialServices/Content-Management-System",
        element: (
          <Navigate
            to="/Financial-Services/Financial-Services-Portal"
            replace
          />
        ),
      },
      {
        path: "/Financial-Services/ContentManagementSystem",
        element: (
          <Navigate
            to="/Financial-Services/Financial-Services-Portal"
            replace
          />
        ),
      },
      {
        path: "/Financial-Services/Content-Management-System",
        element: (
          <Navigate
            to="/Financial-Services/Financial-Services-Portal"
            replace
          />
        ),
      },
      {
        path: "/Financial-Services/Financial-Services-Portal",
        element: <ContentManagementSystem />,
      },
      {
        path: "/Industries/Healthcare",
        element: <Healthcare />, // dynamic ready
      },
      {
        path: "/Manufacturing/EcommercePortal",
        element: <Navigate to="/Manufacturing/Ecommerce-Portal" replace />,
      },
      {
        path: "/Manufacturing/Ecommerce-Portal",
        element: <ManufacturingEC />, // dynamic ready
      },
      {
        path: "/Manufacturing/Plastics-Injection-Molding",
        element: <ManufacturingPIM />, // dynamic ready
      },
      {
        path: "/Healthcare/CharitableOrganization",
        element: <Navigate to="/Healthcare/Charitable-Organization" replace />,
      },
      {
        path: "/Healthcare/Charitable-Organization",
        element: <CharitableOrganization />, // dynamic ready
      },
      {
        path: "/about-us/technology-stack/salesforce",
        element: <Navigate to="/Technologies/Salesforce" replace />,
      },
      {
        path: "/services/technology/saleforce",
        element: <Navigate to="/Technologies/Salesforce" replace />,
      },
      {
        path: "/Technologies/Salesforce",
        element: <Salesforce />, // dynamic Not Needed
      },
      {
        path: "/Services/TechnologyTalent",
        element: <Navigate to="/Services/Staffing" replace />,
      },
      {
        path: "/solutions/digital-talent",
        element: <Navigate to="/Services/Staffing" replace />,
      },
      {
        path: "/services/staffing/nodejs-developers-on-contract",
        element: <Navigate to="/Services/Staffing" replace />,
      },
      {
        path: "/Services/Technology-Talent",
        element: <Navigate to="/Services/Staffing" replace />,
      },
      {
        path: "/Services/Staffing",
        element: <TechTalent />, // dynamic ready
      },
      {
        path: "/PrivacyPolicy",
        element: <Navigate to="/Privacy-Policy" replace />,
      },
      {
        path: "/Privacy-Policy",
        element: <PrivacyPolicy />, // dynamic Not Needed
      },
      {
        path: "/TermsOfUse",
        element: <Navigate to="/Terms-Of-Use" replace />,
      },
      {
        path: "/Terms-Of-Use",
        element: <TermsOfUse />, // dynamic Not Needed
      },
      {
        path: "/Industries/Manufacturing",
        element: <Manufacturing />, // dynamic ready
      },
      {
        path: "/Resources/News",
        element: <News />, // dynamic ready
      },
      {
        path: "/Resources/Blogs",
        element: <Blogs />, // dynamic ready
      },
      {
        path: "/Resources/Blogs/Liferay-client-extensions",
        element: <LiferayDXP />,
      },
      {
        path: "/Resources/SuccessStories",
        //element: <Navigate to="/Resources/Success-Stories" replace />,
        element: <Navigate to="/Resources" replace />,
      },
      {
        path: "/Resources/Success-Stories",
        //element: <Navigate to="/Resources/Success-Stories" replace />,
        element: <Navigate to="/Resources" replace />,
      },
      {
        path: "/Resources/Success-Stories",
        element: <Resources />, // dynamic Not Needed
      },
      {
        path: "/services/technology/liferay/",
        element: <Navigate to="/Technologies/Liferay" replace />,
      },
      {
        path: "/Technologies/Liferay",
        element: <Liferay />, // dynamic ready
      },
      {
        path: "/Technologies/FullStackDevelopment",
        element: <Navigate to="/Technologies/Full-Stack-Development" replace />,
      },
      {
        path: "/services/technology/react-js",
        element: <Navigate to="/Technologies/Full-Stack-Development" replace />,
      },
      {
        path: "/Technologies/Full-Stack-Development",
        element: <Stack />, // dynamic ready
      },
      {
        path: "/about-us/technology-stack/aws-managed-services-and-serverless-solution",
        element: <Navigate to="/Technologies/DevOps" replace />,
      },
      {
        path: "/ideas/advancing-operational-excellence-a-dev-ops-case-study-in-the-aviation-industry",
        element: <Navigate to="/Technologies/DevOps" replace />,
      },
      {
        path: "/solutions/cloud/cloud-1",
        element: <Navigate to="/Technologies/DevOps" replace />,
      },
      {
        path: "/solutions/cloud/cloud-managed-services",
        element: <Navigate to="/Technologies/DevOps" replace />,
      },
      {
        path: "/solutions/cloud/cloud-migration-and-deployment",
        element: <Navigate to="/Technologies/DevOps" replace />,
      },
      {
        path: "/solutions/cloud/devops",
        element: <Navigate to="/Technologies/DevOps" replace />,
      },
      {
        path: "/solutions/cloud/hybrid-and-multicloud-deployment",
        element: <Navigate to="/Technologies/DevOps" replace />,
      },
      {
        path: "/Technologies/DevOps",
        element: <DevOps />, // dynamic ready
      },
      {
        path: "/Services/ManagedITServices",
        element: <Navigate to="/Services/Managed-IT-Services" replace />,
      },
      {
        path: "/Services/Managed-IT-Services",
        element: <ItServices />, // dynamic ready
      },
      {
        path: "/Services/SecurityCertificationsServices",
        element: (
          <Navigate to="/Services/Security-Certifications-Services" replace />
        ),
      },
      {
        path: "/Services/Security-Certifications-Services",
        element: <SecurityServices />, // dynamic ready
      },
      {
        path: "/About/EngineersmindLeadership",
        element: <Navigate to="/About/Engineersmind-Leadership" replace />,
      },
      {
        path: "/about-us/our-team",
        element: <Navigate to="/About/Engineersmind-Leadership" replace />,
      },
      {
        path: "/About/Engineersmind-Leadership",
        //element: <Leadership />, // dynamic ready
        element: <Team />,
      },
      {
        path: "/About/Team",
        element: <Team />, // dynamic ready
      },
      {
        path: "/About/Alliances",
        element: <Alliances />, // dynamic Not Needed
      },
      {
        path: "/careers",
        element: <Navigate to="/About/Careers" replace />,
      },
      {
        path: "/About/Careers",
        element: <Careers />, // dynamic ready
      },
      {
        path: "/Healthcare/IntegratedUserExperiences",
        element: (
          <Navigate to="/Healthcare/Integrated-User-Experiences" replace />
        ),
      },
      {
        path: "/Healthcare/Integrated-User-Experiences",
        element: <HealthcareCs1 />, // dynamic Not Needed
      },
      {
        path: "/Financial-Services/FINRACompliance",
        element: <Navigate to="/Financial-Services/FINRA-Compliance" replace />,
      },
      {
        path: "/FinancialServices/FINRA-Compliance",
        element: <Navigate to="/Financial-Services/FINRA-Compliance" replace />,
      },
      {
        path: "/FinancialServices/FINRACompliance",
        element: <Navigate to="/Financial-Services/FINRA-Compliance" replace />,
      },
      {
        path: "/Financial-Services/FINRA-Compliance",
        element: <FinancialCs1 />, // dynamic ready
      },
      {
        path: "/Financial-Services/AutomatingTradeSystems",
        element: (
          <Navigate to="/Financial-Services/Automating-Trade-Systems" replace />
        ),
      },
      {
        path: "/FinancialServices/AutomatingTradeSystems",
        element: (
          <Navigate to="/Financial-Services/Automating-Trade-Systems" replace />
        ),
      },
      {
        path: "/FinancialServices/Automating-Trade-Systems",
        element: (
          <Navigate to="/Financial-Services/Automating-Trade-Systems" replace />
        ),
      },
      {
        path: "/Financial-Services/Automating-Trade-Systems",
        element: <FinancialCs2 />, // dynamic Not Needed
      },
      {
        path: "/Services/DigitalTransformation",
        element: <Navigate to="/Services/Digital-Transformation" replace />,
      },
      {
        path: "/services/technology/technology-services",
        element: <Navigate to="/Services/Digital-Transformation" replace />,
      },
      {
        path: "/solutions/delivery-transformation",
        element: <Navigate to="/Services/Digital-Transformation" replace />,
      },
      {
        path: "/solutions/delivery-transformation/open-source-for-enterprise-digital-transformation",
        element: <Navigate to="/Services/Digital-Transformation" replace />,
      },
      {
        path: "/solutions/null/open-source-for-enterprise-digital-transformation/",
        element: <Navigate to="/Services/Digital-Transformation" replace />,
      },
      {
        path: "/what-we-do/enterprise-software-development/",
        element: <Navigate to="/Services/Digital-Transformation" replace />,
      },
      {
        path: "/Services/Digital-Transformation",
        element: <DigitalTransformation />, // dynamic Not Needed
      },
      {
        path: "/Financial-Services/AI-Chatbot",
        element: <AIChatbot />, // dynamic Not Needed
      },
      {
        path: "/Healthcare/Digital-Experiences",
        element: <HealthBus />, // dynamic Not Needed
      },
      {
        path: "/Healthcare/AI-In-Healthcare",
        element: <AIInHealthcare />, // dynamic Not Needed
      },
      {
        path: "/Resources/Blogs/MSAL",
        element: <MSAL />, // dynamic Not Needed
      },
      {
        path: "/Resources/Blogs/JWKS",
        element: <JWKS />, // dynamic Not Needed
      },
      {
        path: "/Resources/Blogs/LiferayCustomDashboard",
        element: <LiferayDashboard />, // dynamic Not Needed
      },
      {
        path: "/Services/AIandData",
        element: <Navigate to="/Services/AI-and-Data" replace />,
      },
      {
        path: "/services/data-and-ai",
        element: <Navigate to="/Services/AI-and-Data" replace />,
      },
      {
        path: "/services/data-and-ai/bi-and-data-managed-services",
        element: <Navigate to="/Services/AI-and-Data" replace />,
      },
      {
        path: "/services/data-and-ai/bi-dashboards-and-visualizations",
        element: <Navigate to="/Services/AI-and-Data" replace />,
      },
      {
        path: "/services/data-and-ai/data-ai-and-powered-transformation",
        element: <Navigate to="/Services/AI-and-Data" replace />,
      },
      {
        path: "/services/data-and-ai/data-analytics-consulting",
        element: <Navigate to="/Services/AI-and-Data" replace />,
      },
      {
        path: "/services/data-and-ai/data-integration",
        element: <Navigate to="/Services/AI-and-Data" replace />,
      },
      {
        path: "/services/data-and-ai/data-management-and-data-governance",
        element: <Navigate to="/Services/AI-and-Data" replace />,
      },
      {
        path: "/services/technology/power-bi",
        element: <Navigate to="/Services/AI-and-Data" replace />,
      },
      {
        path: "/Services/AI-and-Data",
        element: <AiDataServices />, // dynamic ready
      },
      {
        path: "/about-us/our-clients",
        element: <Navigate to="/Resources/Testimonials" replace />,
      },
      {
        path: "/Resources/Testimonials",
        element: <Testimonials />, // dynamic ready
      },
      {
        path: "/AdminPage",
        element: <AdminPage />, // dynamic Not Needed
      },
      {
        path: "/EditorPage",
        element: <EditorPage />, // dynamic Not Needed
      },
      {
        path: "/login",
        element: <Login />, // dynamic Not Needed
      },
    ],
  },
]);

//const root = ReactDOM.createRoot(document.getElementById("root"));
//root.render(<RouterProvider router={appRouter} />);

const root = createRoot(document.getElementById("root"));
root.render(<RouterProvider router={appRouter} />);
