import React from "react";
import ContactUsButton from "./ContactUsButton";

const HeroComponent = ({
  bgImage,
  bgImageMobile,
  textContent,
  breadcrumbs,
  isSlider,
  isBreadCumb,
  textData,
  contactUsBtn,
  isBannerLogo,
}) => {
  return isSlider ? (
    <div className="container-fluid p-0 hero-container slider">
      <div id="carouselExampleAutoplaying" className="carousel slide">
        <div className="carousel-inner">
          {textData.editable_carousel_banner.map((item, index) => {
            if (index === 0) {
              return (
                <div
                  className={"carousel-item carousel-item-"
                    .concat(String.toString(index + 1))
                    .concat(" active")}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 slider-img">
                      <img
                        src={item.img}
                        className="d-block w-100 desktop-img"
                        alt="banner-img"
                      />
                      <img
                        src={item.mobileimg}
                        className="mobile-img"
                        alt="banner-img"
                      />
                      <div className="text-container">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              {isBreadCumb ? (
                                <h1 className="animate__animated animate__fadeInLeft">
                                  <span>{item.heading.text1}</span>
                                  {item.heading.text2}
                                  <br />
                                  <span>{item.heading.text3}</span>
                                  {item.heading.text4}
                                  <br />
                                  {item.heading.text5}
                                </h1>
                              ) : (
                                <h1 className="animate__animated animate__fadeInLeft">
                                  <span>{item.heading.text1}</span>
                                  {item.heading.text2}
                                  <br /> <span>{item.heading.text3}</span>
                                  {item.heading.text4}
                                  <br />
                                  {item.heading.text5}
                                </h1>
                              )}
                              {contactUsBtn ? (
                                <ContactUsButton className="dark" />
                              ) : (
                                <a
                                  href={item.button.url}
                                  className="animate__animated animate__fadeInLeft"
                                >
                                  {item.button.text}{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                        {isBannerLogo && (
                          <div className="banner-logos">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                  <img
                                    src={item.banner.img}
                                    className="img-fluid"
                                    alt="banner-logo"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className={"carousel-item carousel-item-".concat(
                    String.toString(index + 1)
                  )}
                >
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 slider-img">
                      <img
                        src={item.img}
                        className="d-block w-100 desktop-img"
                        alt="banner-img"
                      />
                      <img
                        src={item.mobileimg}
                        className="mobile-img"
                        alt="banner-img"
                      />
                      <div className="text-container">
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                              {isBreadCumb ? (
                                <h1 className="animate__animated animate__fadeInLeft">
                                  <span>{item.heading.text1}</span>
                                  {item.heading.text2}
                                  <span>{item.heading.text3}</span>
                                  {item.heading.text4}{' '}
                                  {item.heading.text5}
                                </h1>
                              ) : (
                                <h1 className="animate__animated animate__fadeInLeft">
                                  <span>{item.heading.text1}</span>
                                  {item.heading.text2}
                                  <br /> <span>{item.heading.text3}</span>{" "}
                                  <br />
                                  {item.heading.text4}
                                  <br />
                                  {item.heading.text5}
                                </h1>
                              )}
                              {!contactUsBtn && (
                                <a
                                  href={item.button.url}
                                  className="animate__animated animate__fadeInLeft"
                                >
                                  {item.button.text}{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                        {isBannerLogo && (
                          <div className="banner-logos">
                            <div className="container">
                              <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                  <img
                                    src={item.banner.img}
                                    className="img-dluid"
                                    alt="banner-logo"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
        {/* <div className="control-btn-div">
          <div className="control-btn-2 slider-btn">
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <i className="fa-solid fa-arrow-right"></i>
              <span
                className="carousel-control-next-icon visually-hidden"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon visually-hidden"
                aria-hidden="true"
              ></span>
              <i className="fa-solid fa-arrow-left"></i>
              <span className="visually-hidden">Previous</span>
            </button>
          </div>
        </div> */}
                <div className="control-btn-div">
          <div className="slider-btn">
          <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon visually-hidden"
                aria-hidden="true"
              ></span>
              <i className="fa-solid fa-arrow-left"></i>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleAutoplaying"
              data-bs-slide="next"
            >
              <i className="fa-solid fa-arrow-right"></i>
              <span
                className="carousel-control-next-icon visually-hidden"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>

      {isBreadCumb && (
        <div className="container-fluid m-0 p-0 brd-crmb">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <nav aria-label="breadcrumb">{breadcrumbs}</nav>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="container-fluid p-0 hero-container">
      <div className="sub-container">
        <img src={bgImage} alt="banner-img" />
        <img src={bgImageMobile} className="mobile-img" alt="banner-img" />
        <div className="text-container">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                {textContent}
                <ContactUsButton className="light" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid m-0 p-0 brd-crmb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <nav aria-label="breadcrumb">{breadcrumbs}</nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroComponent;
