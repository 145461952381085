import AOS from "aos";
import { useEffect } from "react";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import config from "../config";

const textDataUrl = `${page_json.page_jsons.Resources}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Resources() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const renderCard = (item, index) => {
    return (
      <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
        <div className="blogs-grid">
          <a href={item.link} target="blank">
            <img src={item.img} alt="logos" className="img-fluid" />
            <div className="blog-grid-content">
              <h3>{item.story}</h3>
              <p>{item.body}</p>
            </div>
          </a>
        </div>
      </div>
    );
  };

  return (
    <div data-aos="fade-up" className="resources">
      <Helmet>
        <meta
          name="description"
          content="Start your Digital transformation journey today"
        />
        <title>Resources | Engineersmind</title>
      </Helmet>
      <div className="container-fluid m-0 p-0 brd-crmb" id="crumb-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Resources
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid SS blogs">
        <div className="container">
          <div className="d-flex flex-column align-items-baseline">
            <h1>
              <span>{textData.layer1.heading.text1}</span>
              {textData.layer1.heading.text2}
            </h1>
          </div>
          <h3 className="mt-3">
            <span>{textData.layer1.details.data1.heading}</span>
          </h3>

          <div className="row container m-0 position-relative finance-slider">
            <div className="custom-swiper-button custom-prev">&#10094;</div>
            <div className="custom-swiper-button custom-next">&#10095;</div>

            <Swiper
              slidesPerView={3}
              spaceBetween={20}
              loop={true}
              navigation={{
                nextEl: ".custom-next",
                prevEl: ".custom-prev",
              }}
              modules={[Navigation]}
              className="financeSwiper"
            >
              {textData.layer1.details.data1.list.map((slider, index) => {
                return (
                  <SwiperSlide key={index} className="p-2">
                    <div className="blogs-grid">
                      <a
                        href={slider.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={slider.img}
                          alt="logos"
                          className="img-fluid"
                        />
                        <div className="blog-grid-content">
                          <h3>{slider.story}</h3>
                          <p>{slider.body}</p>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="row row-gap-4 finance-stories-row-mobile">
            {textData.layer1.details.data1.list.map(renderCard)}
          </div>

          <h3 className="mt-5">
            <span>{textData.layer1.details.data2.heading}</span>
          </h3>
          <div className="row container m-0 position-relative finance-slider">
            <div className="custom-swiper-button custom-prev">&#10094;</div>
            <div className="custom-swiper-button custom-next">&#10095;</div>

            <Swiper
              slidesPerView={3}
              spaceBetween={20}
              loop={true}
              navigation={{
                nextEl: ".custom-next",
                prevEl: ".custom-prev",
              }}
              modules={[Navigation]}
              className="financeSwiper"
            >
              {textData.layer1.details.data2.list.map((slider, index) => {
                return (
                  <SwiperSlide key={index} className="p-2">
                    <div className="blogs-grid">
                      <a
                        href={slider.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={slider.img}
                          alt="logos"
                          className="img-fluid"
                        />
                        <div className="blog-grid-content">
                          <h3>{slider.story}</h3>
                          <p>{slider.body}</p>
                        </div>
                      </a>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
          <div className="row row-gap-4 finance-stories-row-mobile">
            {textData.layer1.details.data2.list.map(renderCard)}
          </div>

          <h3 className="mt-5">
            <span>{textData.layer1.details.data3.heading}</span>
          </h3>
          <div className="row row-gap-4">
            {textData.layer1.details.data3.list.map(renderCard)}
          </div>
        </div>
      </div>

      <div className="alliances d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-white text-center">
          {textData.layer2.heading.text1}
          <span>{textData.layer2.heading.text2}</span>
          {textData.layer2.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.layer2.button.text}
          link="#ContactForm"
          className="light"
        />
      </div>

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Resources;
