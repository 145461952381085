import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import MarqueeComponent from "./MarqueeComponent";
import HeroComponent from "./HeroComponent";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.DigitalTransformation}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function DigitalTransformation() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <div data-aos="fade-up" className="dtPage">
      <Helmet>
        <meta
          name="description"
          content="Navigate complex business and IT environments with a robust Digital ecosystem"
        />
        <title>Digital Transformation | Services | Engineersmind</title>
      </Helmet>
      {/* <div className="container-fluid m-0 p-0 brd-crmb">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/Services/Digital-Transformation">Services </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Digital Transformation
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

      <HeroComponent
      bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.img}
        textContent={
          <h1>
            {textData.layer1.heading.text1}{" "}
            <span>
              {textData.layer1.heading.text2}
               {textData.layer1.heading.text3}
            </span>
            {textData.layer1.heading.text4}
            <br />
            {textData.layer1.heading.text5}
          </h1>
        }
        breadcrumbs={
          <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/">Home </a>
          </li>
          <li className="breadcrumb-item">
            <a href="/Services/Digital-Transformation">Services </a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Digital Transformation
          </li>
        </ol>
        }
      />

      {/* <div className="container-fluid p-0">
        <div className="inner-banner">
          <img
            src={textData.layer1.img}
            className="d-block w-100"
            alt="banner-img"
          />
          <div className="inner-banner-text">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                  <h1 className="animate__animated animate__fadeInLeft">
                    {textData.layer1.heading.text1}
                    <br />
                    {textData.layer1.heading.text2}
                    <span>{textData.layer1.heading.text3}</span>
                    <br />
                    {textData.layer1.heading.text4}
                  </h1>
                  <ContactUsButton className="mt-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid manufacture full-height-section">
        <div className="container">
          <div className="row manufacture-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
              <img
                src={textData.layer2.img}
                className="img-fluid wrapimg-with-text float-start me-4"
                alt="industry"
              />
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
              <p className="text-20 para">{textData.layer2.body.text1}</p>&nbsp;
              <p className="text-align-justify text-20">
                {textData.layer2.body.text2}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="alliances d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-white text-center">
          {textData.digitaltransformationjourneysection.heading.text1}
          <span>{textData.digitaltransformationjourneysection.heading.text2}</span>
          {textData.digitaltransformationjourneysection.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.digitaltransformationjourneysection.button.text}
          link="#ContactForm"
          className="light"
        />
      </div>

      <div className="container-fluid ModernPortals">
        <div className="container">
          <h1 className="mb-0"><span>{textData.layer3.heading}</span></h1>&nbsp;
          <div className="p-0 text-20">
            <p>{textData.layer3.body.text1}</p>&nbsp;
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.layer3.img}
                className="img-fluid ModernPortals-left-img midp1"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal">
                {textData.layer3.keyFeatures.map((feature, index) => {
                  return (
                    <div className="d-flex w-100 list-item" key={index}>
                      <img src={textData.bulletPoints.img} alt="bullet-point" />
                      <div>
                        <p className="fst-italic text-20">
                          <strong>{feature.title}</strong>
                        </p>
                        <p className="portal-desc text-20">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="pt-4 text-20">
            <p>{textData.layer3.body.text2}</p>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals second">
        <div className="container">  
          <h1 className="mb-0"><span>{textData.layer4.heading}</span></h1>&nbsp;
          <div className="p-0 text-20">
            <p>{textData.layer4.body.text1}</p>&nbsp;
          </div>
          <div className="row full-stack">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal">
                {textData.layer4.keyFeatures.map((feature, index) => {
                  return (
                    <div className="d-flex w-100 list-item" key={index}>
                      <img src={textData.bulletPoints.img} alt="bullet-point" />
                      <div>
                        <p className="fst-italic text-20">
                          <strong>{feature.title}</strong>
                        </p>
                        <p className="portal-desc text-20">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 full-stack  d-flex justify-content-center align-items-center">
              <img
                src={textData.layer4.img}
                className="img-fluid ModernPortals-left-img2"
                alt="Resilient_solutions"
              />
            </div>
          </div>
          <div className="pt-4 text-20">
            <p>{textData.layer4.body.text2}</p>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals third">
        <div className="container">
          <h1 className="mb-0"><span>{textData.layer5.heading}</span></h1>&nbsp;
          <div className="p-0 text-20">
            <p>{textData.layer5.body.text1}</p>&nbsp;
          </div>
          <div className="row full-stack">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 full-stack  d-flex justify-content-center align-items-center">
              <img
                src={textData.layer5.img}
                className="img-fluid ModernPortals-left-img2"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal">
                {textData.layer5.keyFeatures.map((feature, index) => {
                  return (
                    <div className="d-flex w-100 list-item" key={index}>
                      <img src={textData.bulletPoints.img} alt="bullet-point" />
                      <div>
                        <p className="fst-italic text-20">
                          <strong>{feature.title}</strong>
                        </p>
                        <p className="portal-desc text-20">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="pt-4 text-20">
            <p>{textData.layer5.body.text2}</p>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals second dataEngineering">
        <div className="container">
          <h1 className="mb-0"><span>{textData.layer6.heading.text1}</span></h1>&nbsp;
          <div className="p-0 text-20">
            <p>{textData.layer6.body.text1}</p>&nbsp;
          </div>
          <div className="row full-stack">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal">
                {textData.layer6.keyFeatures.map((feature, index) => {
                  return (
                    <div className="d-flex w-100 list-item" key={index}>
                      <img src={textData.bulletPoints.img} alt="bullet-point" />
                      <div>
                        <p className="fst-italic text-20">
                          <strong>{feature.title}</strong>
                        </p>
                            <p className="portal-desc text-20">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
          <div className="pt-4 text-20">
          <p className="mb-0  pb-2 AIuseCase"><strong>{textData.layer6.body.title}</strong></p>
          <ul>
            <li>
          <p className="pb-2 portal-desc text-20">{textData.layer6.body.description.desc1}</p>
            </li>
            <li>
          <p className="pb-2 portal-desc text-20">{textData.layer6.body.description.desc2}</p>
            </li>
          </ul>
          </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12   d-flex justify-content-center align-items-center">
              <img
                src={textData.layer6.img}
                className="img-fluid ModernPortals-left-img"
                alt="Resilient_solutions"
              />
            </div>
          </div>
          <div className="pt-4 text-20">
            <p className="pb-2 text-20">{textData.layer6.body.text2}</p>
          </div>
        </div>
      </div>

	  <div className="container-fluid ModernPortals">
        <div className="container">
          <h1 className="mb-0"><span>{textData.layer7.heading}</span></h1>&nbsp;
          <div className="p-0 text-20">
            <p>{textData.layer7.body.text1}</p>&nbsp;
          </div>
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.layer7.img}
                className="img-fluid ModernPortals-left-img2"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal">
                {textData.layer7.keyFeatures.map((feature, index) => {
                  return (
                    <div className="d-flex w-100 list-item" key={index}>
                      <img src={textData.bulletPoints.img} alt="bullet-point" />
                      <div>
                        <p className="fst-italic text-20">
                          <strong>{feature.title}</strong>
                        </p>
                        <p className="portal-desc text-20">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="pt-4 text-20">
            <p>{textData.layer7.body.text2}</p>
          </div>
        </div>
      </div>

	  <div className="container-fluid ModernPortals second">
        <div className="container">
          <h1 className="mb-0"><span>{textData.layer8.heading}</span></h1>&nbsp;
          <div className="p-0 text-20">
            <p>{textData.layer8.body.text1}</p>&nbsp;
          </div>
          <div className="row full-stack">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal">
                {textData.layer8.keyFeatures.map((feature, index) => {
                  return (
                    <div className="d-flex w-100 list-item" key={index}>
                      <img src={textData.bulletPoints.img} alt="bullet-point" />
                      <div>
                        <p className="fst-italic text-20">
                          <strong>{feature.title}</strong>
                        </p>
                        <p className="portal-desc text-20">{feature.description}</p>
                      </div>
                    </div>
                  );
                })}
                <p className="pt-4 portal-desc  text-20">{textData.layer8.body.text2}</p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 full-stack  d-flex justify-content-center align-items-center">
              <img
                src={textData.layer8.img}
                className="img-fluid ModernPortals-left-img"
                alt="Resilient_solutions"
              />
            </div>
          </div>
          {/* <div className="pt-4 text-20">
            <p>{textData.layer8.body.text2}</p>
          </div> */}
        </div>
      </div>
	  
	  <div className="container-fluid whyChoose mb-5 mt-4">
		<div className="container">
		<h1><span>{textData.layer9.heading.text1}</span></h1>
		<p className="text-20 mb-4">{textData.layer9.body.text1}</p>
		<p className="text-20">{textData.layer9.body.text2}</p>
		</div>
	  </div>

      {/* Tailored solutions */}
      {/* <div className="container-fluid Resilient">
				<div className="container">
					<h1>{textData.layer3.heading}</h1>&nbsp;
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<img src={textData.layer3.img} className="img-fluid" alt="Resilient_solutions" />
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<div className="card-1">
								<p>{textData.layer3.body.text1}</p>&nbsp;
								<p>{textData.layer3.body.text2}</p>&nbsp;
								<p>{textData.layer3.body.text3}</p>
							</div>
						</div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid transformation">
				<div className="container">
					<h1>{textData.layer4.heading}</h1>&nbsp;
					<div className="row">
						<div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
							<p>{textData.layer4.body}</p>&nbsp;
							<ul>
								<li><span>{textData.layer4.details.data1.serial_no}</span>{textData.layer4.details.data1.text}</li>
								<li><span>{textData.layer4.details.data2.serial_no}</span>{textData.layer4.details.data2.text}</li>
								<li><span>{textData.layer4.details.data3.serial_no}</span>{textData.layer4.details.data3.text}</li>
								<li><span>{textData.layer4.details.data4.serial_no}</span>{textData.layer4.details.data4.text}</li>
							</ul>
						</div>
						<div className="col-lg-5 col-md-5 col-sm-5 col-xs-5"></div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid experiences">
				<div className="container">
					<h1>{textData.layer5.heading}</h1>
					<div className="row">
						<div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
							<p>{textData.layer5.body.text1}</p>
							<p>{textData.layer5.body.text2}</p>&nbsp;
						</div>
					</div>
					<div className="col-lg-5 col-md-5 col-sm-5 col-xs-5"></div>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<ul>
								<li><span>{textData.layer5.details.data1.serial_no}</span>{textData.layer5.details.data1.text}</li>
								<li><span>{textData.layer5.details.data2.serial_no}</span>{textData.layer5.details.data2.text}</li>
								<li><span>{textData.layer5.details.data3.serial_no}</span>{textData.layer5.details.data3.text}</li>

							</ul>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<ul>
								<li><span>{textData.layer5.details.data4.serial_no}</span>{textData.layer5.details.data4.text}</li>
								<li><span>{textData.layer5.details.data5.serial_no}</span>{textData.layer5.details.data5.text}</li>
								<li><span>{textData.layer5.details.data6.serial_no}</span>{textData.layer5.details.data6.text}</li>
							</ul>
						</div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid regulatory">
				<div className="container">
					<h1><span>{textData.layer6.heading.text1}</span>{textData.layer6.heading.text2}</h1>
					<p>{textData.layer6.body}</p>&nbsp;
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<ul>
								<li><span>{textData.layer6.details.data1.serial_no}</span><b>{textData.layer6.details.data1.title}</b>&nbsp;&nbsp;{textData.layer6.details.data1.text}</li>
								<li><span>{textData.layer6.details.data2.serial_no}</span><b>{textData.layer6.details.data2.title}</b>&nbsp;&nbsp;{textData.layer6.details.data2.text}</li>
								<li><span>{textData.layer6.details.data3.serial_no}</span><b>{textData.layer6.details.data3.title}</b>&nbsp;&nbsp;{textData.layer6.details.data3.text}</li>
								<li><span>{textData.layer6.details.data4.serial_no}</span><b>{textData.layer6.details.data4.title}</b>&nbsp;&nbsp;{textData.layer6.details.data4.text}</li>
								<li><span>{textData.layer6.details.data5.serial_no}</span><b>{textData.layer6.details.data5.title}</b>&nbsp;&nbsp;{textData.layer6.details.data5.text}</li>
							</ul>
						</div>
					</div>

				</div>
			</div> */}

      {/* integration */}
      {/* <div className="container-fluid engineering">
				<div className="container">
					<h1>{textData.layer7.heading}</h1>
					<p>{textData.layer7.body.text1}</p>
					<p>{textData.layer7.body.text2}</p>&nbsp;
					<div className="row">
						<ul>
							<li><span>{textData.layer7.details.data1.serial_no}</span>{textData.layer7.details.data1.text}</li>
							<li><span>{textData.layer7.details.data2.serial_no}</span>{textData.layer7.details.data2.text}</li>
							<li><span>{textData.layer7.details.data3.serial_no}</span>{textData.layer7.details.data3.text}</li>
							<li><span>{textData.layer7.details.data4.serial_no}</span>{textData.layer7.details.data4.text}</li>
						</ul>
					</div>
				</div>&nbsp;
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<div className="card-5">
								<h1>{textData.layer8.heading}</h1>
								<p>{textData.layer8.body}</p>&nbsp;
								<ul className="ul-list-2">
									<li>{textData.layer8.points.point1}</li>
									<li>{textData.layer8.points.point2}</li>
									<li>{textData.layer8.points.point3}</li>
									<li>{textData.layer8.points.point4}</li>
								</ul>
							</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<img src={textData.layer8.img} className="img-fluid" alt="Resilient_solutions" />
						</div>
					</div>
				</div>&nbsp;
				<div className="container">
					<h1>{textData.layer9.heading.text1}<br />{textData.layer9.heading.text2} </h1>
					<div className="row">
						<div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
							<p>{textData.layer9.body.text1}</p>&nbsp;
							<p>{textData.layer9.body.text2}</p>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"></div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid services-home">
				<div className="container">
					<h1><span>{textData.layer10.heading}</span></h1>
					<p>{textData.layer10.body}</p>
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer10.modules.module1.link} id="pdng">{textData.layer10.modules.module1.text}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer10.modules.module2.link} id="pdng">{textData.layer10.modules.module2.text}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer10.modules.module3.link} id="pdng">{textData.layer10.modules.module3.text}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer10.modules.module4.link} id="pdng">{textData.layer10.modules.module4.text}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer10.modules.module5.link} id="pdng">{textData.layer10.modules.module5.text}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer10.modules.module6.link} id="pdng">{textData.layer10.modules.module6.text}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer10.modules.module7.link} id="pdng">{textData.layer10.modules.module7.text}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid trust">
        <div className="container trusted-img">
          <div className="row">
            <h1>{textData.layer11.heading}</h1>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img
                src={textData.layer11.img}
                alt="logos"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div> */}


      <div className="full-height-section alliances-container">
        <div className="d-flex flex-column h-100 py-5">

          <div className="sub-section">
            <h1>{textData.alliacessection.heading1}</h1>
            <img src={textData.alliacessection.img1} alt="logos" className="img-fluid" />
          </div>
          <div className="sub-section">
            <h1>{textData.alliacessection.heading2}</h1>
            <img src={textData.alliacessection.img2} alt="logos" className="img-fluid web" />
            <img src={textData.alliacessection.imgMobile} alt="logos" className="img-fluid mobile" />
          </div>
        </div>
      </div>

      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DigitalTransformation;
