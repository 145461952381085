
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import ContactUsButton from './ContactUsButton';
import HeroComponent from './HeroComponent';
import MarqueeComponent from "./MarqueeComponent";
import SuccessStoriesList from './SuccessStoriesList';
import TechnologyAlliances from './TechnologyAlliances';
import config from '../config';

const textDataUrl = `${page_json.page_jsons.AiDataServices}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function AiDataServices() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);
	const [activePanel, setActivePanel] = useState(null);
	const togglePanel = (panelId) => {
		setActivePanel(activePanel === panelId ? null : panelId);
	};
	var left_index = 0;
	return (
		<div data-aos="fade-up" className="aiAndDataPage">
			<Helmet>
                <meta name="description" content="Building integral models with AI and Data for your success and surge." />
                <title>AI and Data | Services | Engineersmind</title>
            </Helmet>
			{/* <div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/Services/Digital-Transformation">Services </a></li>
									<li className="breadcrumb-item active" aria-current="page">Data & AI</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div> */}

			<HeroComponent
      bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.img}
        textContent={
          <h1>
            {textData.layer1.heading1}
              <br />
            <span>
              {textData.layer1.heading2}
            </span>
          </h1>
        }
        breadcrumbs={
			<ol className="breadcrumb">
			<li className="breadcrumb-item"><a href="/">Home </a></li>
			<li className="breadcrumb-item"><a href="/Services/Digital-Transformation">Services </a></li>
			<li className="breadcrumb-item active" aria-current="page">AI & Data</li>
		</ol>
        }
      />

			{/* <div className="container-fluid p-0">
				<div className="inner-banner">
					<img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
					<div className="inner-banner-text">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
									<h1 className="animate__animated animate__fadeInLeft">{textData.layer1.heading1}<br /><span>{textData.layer1.heading2}</span></h1>
									<ContactUsButton className="mt-3" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid manufacture">
				<div className="container">
					<h1>{textData.layer2.heading}</h1>
					<div className="row">
						<div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
							<div className="data-text">
								<p>{textData.layer2.body}</p>
							</div>
						</div>
						<div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
							<div className="manufacture-img">
								<img src={textData.layer2.img} className="img-fluid" alt="industry" />
							</div>
						</div>
					</div>
				</div>
			</div> */}

      <div className="container full-height-section sub-header-section">
        <div className="container">
          <div className="row sub-header-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="content-container d-flex flex-column justify-content-center h-100">
                <h4 className="mb-4">{textData.introduction.heading}</h4>
                <p className="mb-4">{textData.introduction.body.text1}</p>
                <p className="mb-4">{textData.introduction.body.text2}</p>
                <p className="mb-4">{textData.introduction.body.text3}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center">
              <div className="image-container d-flex justify-content-center">
                <img
                  src={textData.introduction.img}
                  className="img-fluid rounded-3 "
                  alt="industry"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="alliances d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-white text-center">
          {textData.startyourjourneysection.heading.text1}
          <span>{textData.startyourjourneysection.heading.text2}</span>
          {textData.startyourjourneysection.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.startyourjourneysection.button.text}
          link="#ContactForm"
          className="light"
        />
      </div>

      <div className="container-fluid ModernPortals">
        <div className="container">
          <h1 className="mb-0">
            <span>{textData.dataintegrationandmanagement.heading}</span>
          </h1>
          &nbsp;
          <div className="row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 row-section-img  d-flex justify-content-start align-items-center">
              <img
                src={textData.dataintegrationandmanagement.img}
                className="img-fluid ModernPortals-left-img"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal text-20">
                <p className="pb-4 text-20">
                  {textData.dataintegrationandmanagement.body.text1}
                </p>
                <p className="pb-4 text-20">
                  {textData.dataintegrationandmanagement.body.text2}
                </p>
                <p className="pb-4 text-20">
                  {textData.dataintegrationandmanagement.body.text3}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals second">
        <div className="container">
          <h1 className="mb-0">
            <span>{textData.datalakes.heading}</span>
          </h1>
          &nbsp;
          <div className="row row-section">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal text-20">
                <p className="pb-4 text-20">{textData.datalakes.body.text1}</p>
                <p className="pb-4 text-20">{textData.datalakes.body.text2}</p>
                <p className="pb-4 text-20">{textData.datalakes.body.text3}</p>
              </div>
            </div>
            <div className="row-section-img col-lg-5 col-md-12 col-sm-12 col-xs-12 row-section d-flex justify-content-end align-items-center">
              <img
                src={textData.datalakes.img}
                className="img-fluid ModernPortals-left-img2"
                alt="Resilient_solutions"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals third">
        <div className="container">
          <h1 className="mb-0">
            <span>{textData.enhanceddatainsights.heading}</span>
          </h1>
          &nbsp;
          <div className="p-0 text-20">
            <p>{textData.enhanceddatainsights.body.text1}</p>&nbsp;
          </div>
          <div className="row full-stack">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 full-stack row-section-img  d-flex justify-content-start align-items-center">
              <img
                src={textData.enhanceddatainsights.img}
                className="img-fluid ModernPortals-left-img2"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal list-item">
                {textData.enhanceddatainsights.keyFeatures.map(
                  (feature, index) => {
                    return (
                      <div className="d-flex w-100 list-item" key={index}>
                        <img
                          src={textData.bulletPoints.img}
                          alt="bullet-point"
                        />
                        <div>
                          <p className="fst-italic text-20">
                            <strong>{feature.title}</strong>
                          </p>
                          <p className="portal-desc text-20">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className="pt-4 text-20">
            <p>{textData.enhanceddatainsights.body.text2}</p>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals second">
        <div className="container">
          <h1 className="mb-0">
            <span>{textData.rowlevelsecurity.heading}</span>
          </h1>
          &nbsp;
          <div className="row row-section">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal text-20">
                <p className="pb-4 text-20">
                  {textData.rowlevelsecurity.body.text1}
                </p>
                <p className="pb-4 text-20">
                  {textData.rowlevelsecurity.body.text2}
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 row-section-img row-section  d-flex justify-content-end align-items-center">
              <img
                src={textData.rowlevelsecurity.img}
                className="img-fluid ModernPortals-left-img2"
                alt="Resilient_solutions"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container ModernPortals full-height-section">
        <h1>{textData.predectiveanalytics.heading}</h1>
        <p
          className="text-20 mb-4"
          dangerouslySetInnerHTML={{ __html: textData.predectiveanalytics.text }}
        />
        <div className="row portal predectiveAnalyticsSection">
          {textData.predectiveanalytics.points.map((point, index) => {
            return (
              <div className="d-flex w-100 list-item  pb-3" key={index}>
                <img src={point.img} alt="bullet-point" />
                <div>
                  <h4 className="mb-2">{point.title}</h4>
                  <p className="portal-desc text-20">{point.description1}</p>
                  {point.description2 && (
                    <p className="portal-desc text-20">{point.description2}</p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="container-fluid ModernPortals third">
        <div className="container">
          <h1 className="mb-0">
            <span>{textData.chatbotsandvirtualassistants.heading}</span>
          </h1>
          &nbsp;
          <div className="p-0 text-20">
            <p>{textData.chatbotsandvirtualassistants.body.text1}</p>&nbsp;
          </div>
          <div className="row full-stack">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 full-stack  row-section-img d-flex justify-content-start align-items-center">
              <img
                src={textData.chatbotsandvirtualassistants.img}
                className="img-fluid ModernPortals-left-img2"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal list-item">
                {textData.chatbotsandvirtualassistants.keyFeatures.map(
                  (feature, index) => {
                    return (
                      <div className="d-flex w-100 list-item" key={index}>
                        <img
                          src={textData.bulletPoints.img}
                          alt="bullet-point"
                        />
                        <div>
                          <p className="fst-italic text-20">
                            <strong>{feature.title}</strong>
                          </p>
                          <p className="portal-desc text-20">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </div>
          <div className="pt-4 text-20">
            <p>{textData.chatbotsandvirtualassistants.body.text2}</p>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals second">
        <div className="container">
          <h1 className="mb-0">
            <span>{textData.aimodelsandsolutions.heading}</span>
          </h1>
          &nbsp;
          <div className="p-0 text-20">
            <p>{textData.aimodelsandsolutions.body.text1}</p>&nbsp;
          </div>
          <div className="row full-stack row-section">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex  align-items-center">
              <div className="portal list-item">
                {textData.aimodelsandsolutions.keyFeatures.map(
                  (feature, index) => {
                    return (
                      <div className="d-flex w-100 list-item" key={index}>
                        <img
                          src={textData.bulletPoints.img}
                          alt="bullet-point"
                        />
                        <div>
                          <p className="fst-italic text-20">
                            <strong>{feature.title}</strong>
                          </p>
                          <p className="portal-desc text-20">
                            {feature.description}
                          </p>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 full-stack row-section-img  d-flex justify-content-end align-items-center">
              <img
                src={textData.aimodelsandsolutions.img}
                className="img-fluid ModernPortals-left-img2"
                alt="Resilient_solutions"
              />
            </div>
          </div>
          <div className="pt-4 text-20">
            <p>{textData.aimodelsandsolutions.body.text2}</p>
          </div>
        </div>
      </div>

      <SuccessStoriesList StoriesList={textData.storiesList} />

      <div className="container full-height-section sub-header-section">
        <div className="container">
          <h1>
            <span>{textData.conclusion.heading}</span>
          </h1>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center">
              <div className="image-container d-flex justify-content-center align-items-center p-4">
                <img
                  src={textData.conclusion.img}
                  className="img-fluid rounded-3"
                  alt="industry"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="content-container d-flex flex-column justify-content-center h-100">
                <p className="mb-4 text-20">{textData.conclusion.body.text1}</p>
                <p className="mb-4 text-20">{textData.conclusion.body.text2}</p>
                <p className="mb-4 text-20">{textData.conclusion.body.text3}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid matrix">
				<div className="container">
					<h1>{textData.layer3.heading}</h1>
					<div className="row">
						<div className="col-lg-5 col-md-5 col-sm-5 col-xs-5">
							<div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
								<a className="nav-link active" id="unified" data-bs-toggle="pill" href="#UA" role="tab" aria-controls="v-pills-home" aria-selected="true">
									<span>{textData.layer3.links.link1} <i className="fa-solid fa-arrow-right"></i></span>
								</a>
								<a className="nav-link" id="streamlined" data-bs-toggle="pill" href="#SDI" role="tab" aria-controls="v-pills-profile" aria-selected="false">
									<span>{textData.layer3.links.link2} <i className="fa-solid fa-arrow-right"></i></span>
								</a>
								<a className="nav-link" id="scalabale" data-bs-toggle="pill" href="#SDW" role="tab" aria-controls="v-pills-messages" aria-selected="false">
									<span>{textData.layer3.links.link3} <i className="fa-solid fa-arrow-right"></i></span>
								</a>
								<a className="nav-link" id="visualization" data-bs-toggle="pill" href="#DEV" role="tab" aria-controls="v-pills-settings" aria-selected="false">
									<span>{textData.layer3.links.link4} <i className="fa-solid fa-arrow-right"></i></span>
								</a>
								<a className="nav-link" id="advanced" data-bs-toggle="pill" href="#AAML" role="tab" aria-controls="v-pills-settings" aria-selected="false">
									<span>{textData.layer3.links.link5} <i className="fa-solid fa-arrow-right"></i></span>
								</a>
							</div>
						</div>
						<div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
							<div className="tab-content" id="v-pills-tabContent">
								<div className="tab-pane fade show active" id="UA" role="tabpanel" aria-labelledby="unified">
									<h4>{textData.layer3.details.data1.heading}</h4>
									<p>{textData.layer3.details.data1.body}</p>
								</div>

								<div className="tab-pane fade" id="SDI" role="tabpanel" aria-labelledby="streamlined">
									<h4>{textData.layer3.details.data2.heading}</h4>
									<p>{textData.layer3.details.data2.body}</p>
								</div>

								<div className="tab-pane fade" id="SDW" role="tabpanel" aria-labelledby="scalabale">
									<h4>{textData.layer3.details.data3.heading}</h4>
									<p>{textData.layer3.details.data3.body}</p>
								</div>

								<div className="tab-pane fade" id="DEV" role="tabpanel" aria-labelledby="visualization">
									<h4>{textData.layer3.details.data4.heading}</h4>
									<p>{textData.layer3.details.data4.body}</p>
								</div>
								<div className="tab-pane fade" id="AAML" role="tabpanel" aria-labelledby="advanced">
									<h4>{textData.layer3.details.data5.heading}</h4>
									<p>{textData.layer3.details.data5.body}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
							<div className="data-img">
								<img src={textData.layer4.img} className="img-fluid" alt="industry" />
							</div>
						</div>
						<div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
							<div className="data-text-1">
								<h1>{textData.layer4.heading}</h1>
								<p>{textData.layer4.body}</p>
							</div>
						</div>
					</div>
					<div className="panel-group panel-2" id="accordion" role="tablist" aria-multiselectable="true">
						<h1>{textData.layer5.heading}</h1>
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                {
                                    textData.layer5.collapsibles_left.map((item, index) => {
                                        left_index = index
                                        return(
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id={ "heading" + index }>
                                                    <h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
                                                        aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
                                                </div>
                                                <div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
                                                    data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
                                                    <div className="panel-body">
                                                        <p>{item.body}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                {
                                    textData.layer5.collapsibles_right.map((item, index) => {
                                        index = index + left_index + 1
                                        return(
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id={ "heading" + index }>
                                                    <h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
                                                        aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
                                                </div>
                                                <div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
                                                    data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
                                                    <div className="panel-body">
                                                        <p>{item.body}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
							
						</div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid services-home">
				<div className="container">
					<h1><span>{textData.layer6.heading}</span></h1>
					<p>{textData.layer6.body}</p>
					<div className="row">
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.sevices.service1.url} id="pdng">{textData.layer6.sevices.service1.name}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.sevices.service2.url} id="pdng">{textData.layer6.sevices.service2.name}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.sevices.service3.url} id="pdng">{textData.layer6.sevices.service3.name}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.sevices.service4.url} id="pdng">{textData.layer6.sevices.service4.name}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.sevices.service5.url} id="pdng">{textData.layer6.sevices.service5.name}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.sevices.service6.url} id="pdng">{textData.layer6.sevices.service6.name}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<a href={textData.layer6.sevices.service7.url} id="pdng">{textData.layer6.sevices.service7.name}</a>
								</div>
							</div>
						</div>
						<div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid trust">
        <div className="container trusted-img">
          <div className="row">
            <h1>{textData.layer7.heading}</h1>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img
                src={textData.layer7.img}
                alt="logos"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="full-height-section alliances-container">
        <div className="d-flex flex-column h-100 py-5">
          <div className="sub-section">
            <h1>{textData.alliacessection.heading1}</h1>
            <img
              src={textData.alliacessection.img1}
              alt="logos"
              className="img-fluid"
            />
          </div>
          <div className="sub-section">
            <h1>{textData.alliacessection.heading2}</h1>
            <img
              src={textData.alliacessection.img2}
              alt="logos"
              className="img-fluid web"
            />
            <img
              src={textData.alliacessection.imgMobile}
              alt="logos"
              className="img-fluid mobile"
            />
          </div>
        </div>
      </div> */}

<TechnologyAlliances data={textData.alliacessection} />

      <MarqueeComponent assets={textData.clients} />

      {/* <div className="container-fluid alliance">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-7 col-xs-7">
              <div className="heading">
                <h1>
                  <span>{textData.layer8.heading.text1}</span>{" "}
                  {textData.layer8.heading.text2}{" "}
                  <span>{textData.layer8.heading.text3}</span>
                </h1>
              </div>
              <div className="more-2">
                <a href={textData.layer8.button.url}>
                  {textData.layer8.button.text}{" "}
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-5 col-xs-5">
              <div className="gif">
                <img
                  src={textData.layer8.img}
                  alt="gif"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer9.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer9.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default AiDataServices;
