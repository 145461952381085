import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import HeroComponent from "./HeroComponent";
import MarqueeComponent from "./MarqueeComponent";
import ContactUsButton from "./ContactUsButton";
import CodeBlock from "./CodeBlock";
import TechnologyAlliances from "./TechnologyAlliances";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.LiferayDXP}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function LiferayDXP() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  return (
    <div data-aos="fade-up" className="liferay-dxp">
      <Helmet>
        <meta name="description" content="Liferay DXP leverages Docker and OAuth 2.0 to enable secure, scalable client extensions, streamlining enterprise web development and deployment." />
        <title>Liferay-client-extensions | Engineersmind</title>
      </Helmet>

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.img}
        textContent={
          <>
            <p>Blog</p>
            <h1>
              <span>
                {textData.layer1.heading.text1}
                {textData.layer1.heading.text2}
                {textData.layer1.heading.text3}
              </span>
            </h1>
          </>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Resources">Resources </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Resources/Blogs">Blogs </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
            Liferay-client-extensions
            </li>
          </ol>
        }
      />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong dangerouslySetInnerHTML={{__html: textData.leftSectionContent.text2}} />
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section className="mb-5">
                <h3 className="mb-3">{textData.introduction.title}</h3>
                <p className="mb-3">{textData.introduction.description}</p>
              </section>

              <div className="alliances d-flex flex-column justify-content-center align-items-center rounded mt-5 mb-5 pl-2 pr-2 bg-white">
                <h1 className="text-black text-center">
                  {textData.layer7.heading.text1}
                  <span>{textData.layer7.heading.text2}</span>
                  {textData.layer7.heading.text3}
                </h1>
                <ContactUsButton
                  text={textData.layer7.button.text}
                  link="#ContactForm"
                  className="light"
                  isLightText = {false}
                />
              </div>

              <section className="overview mb-5">
                <h3 className="mb-3">{textData.integratingOAuth.title}</h3>
                <div className="d-flex justify-content-center">
                  <img
                    className="mb-3 w-50 integratingOAuth-img"
                    src={textData.integratingOAuth.img}
                    alt="overview-img"
                  />
                </div>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">{textData.enhancingLiferay.title}</h3>
                <p className="mb-3">{textData.enhancingLiferay.description}</p>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">
                  {textData.microserviceClientExtensions.title}
                </h3>
                <p className="mb-3">
                  {textData.microserviceClientExtensions.description}
                </p>
                <div className="mt-3">
                <p className="mb-3">
                  <strong>
                  {textData.authenticationProfiles.title}
                  </strong>
                </p>
                <p className="mb-3">
                  {textData.authenticationProfiles.description}
                </p>
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>
                      {textData.authenticationProfiles.keyFeatureTitle}
                    </strong>
                  </p>
                  <div>
                    {textData.authenticationProfiles.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p>{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <section className="overview mb-5">
                <h3 className="mb-3">{textData.oAuthProfiles.title}</h3>
                <div className="d-flex justify-content-center">
                  <img
                    className="mb-3"
                    src={textData.oAuthProfiles.img}
                    alt="overview-img"
                  />
                </div>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">
                  {textData.oAuthUserAgentClientExtensions.title}
                </h3>
                <div className="mt-3">
                  {textData.oAuthUserAgentClientExtensions.keyFeatures.map(
                    (feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <p>{feature.description}</p>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">
                  {textData.oAuthHeadlessServerClientExtensions.title}
                </h3>
                <div className="mt-3">
                  {textData.oAuthHeadlessServerClientExtensions.keyFeatures.map(
                    (feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <p>{feature.description}</p>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </section>

              <section className="overview mb-5">
                <h3 className="mb-3">{textData.typesOfClients.title}</h3>
                <div className="d-flex justify-content-center">
                  <img
                    className="mb-3"
                    src={textData.typesOfClients.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>{textData.typesOfClients.keyFeatureTitle1}</strong>
                  </p>
                  <div>
                    {textData.typesOfClients.keyFeatures1.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p>{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>{textData.typesOfClients.keyFeatureTitle2}</strong>
                  </p>
                  <div>
                    {textData.typesOfClients.keyFeatures2.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p>{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <section className="overview mb-5">
                <h3 className="mb-3">
                  {textData.applicationComponentsOverview.title}
                </h3>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.applicationComponentsOverview.keyFeatures1
                          .title
                      }
                    </strong>
                  </p>
                  <div>
                    {textData.applicationComponentsOverview.keyFeatures1.features.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p>{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.applicationComponentsOverview.keyFeatures1
                          .pointsTitle
                      }
                    </strong>
                  </p>
                  <ul>
                    {textData.applicationComponentsOverview.keyFeatures1.points.map(
                      (point, index) => {
                        return <li key={index}>{point}</li>;
                      }
                    )}
                  </ul>
                  <div className="d-flex justify-content-center">
                    <img
                      className="mb-3"
                      src={
                        textData.applicationComponentsOverview.keyFeatures1.img1
                      }
                      alt="overview-img"
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <img
                      className="mb-3"
                      src={
                        textData.applicationComponentsOverview.keyFeatures1.img2
                      }
                      alt="overview-img"
                    />
                  </div>
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.applicationComponentsOverview.keyFeatures2
                          .title
                      }
                    </strong>
                  </p>
                  <div>
                    {textData.applicationComponentsOverview.keyFeatures2.features.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p>{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.applicationComponentsOverview.keyFeatures2
                          .pointsTitle
                      }
                    </strong>
                  </p>
                  <ul>
                    {textData.applicationComponentsOverview.keyFeatures2.points.map(
                      (point, index) => {
                        return <li key={index}>{point}</li>;
                      }
                    )}
                  </ul>
                </div>
              </section>

              <section className="overview mb-5">
                <h3 className="mb-3">
                  {textData.extendingLiferayWithClientExtensions.title}
                </h3>
                <div className="d-flex justify-content-center">
                  <img
                    className="mb-3"
                    src={textData.extendingLiferayWithClientExtensions.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.extendingLiferayWithClientExtensions.overview
                          .title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.extendingLiferayWithClientExtensions.overview
                        .description
                    }
                  </p>
                  <div className="mt-3">
                    <p className="mb-3">
                      <strong>
                        {
                          textData.extendingLiferayWithClientExtensions
                            .keyBenefits.title
                        }
                      </strong>
                    </p>
                    <div>
                      {textData.extendingLiferayWithClientExtensions.keyBenefits.keyBenefitsPoints.map(
                        (feature, index) => {
                          return (
                            <div className="d-flex w-100 list-item" key={index}>
                              <img
                                src={textData.bulletPoints.img}
                                alt="bullet-point"
                              />
                              <div>
                                <p>
                                  <strong>{feature.title}</strong>
                                </p>
                                <p className="mb-2">{feature.description}</p>
                              </div>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.extendingLiferayWithClientExtensions
                          .roleOfPartners.title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.extendingLiferayWithClientExtensions
                        .roleOfPartners.description1
                    }
                  </p>
                  <ul>
                    {textData.extendingLiferayWithClientExtensions.roleOfPartners.points.map(
                      (point, index) => {
                        return <li key={index}>{point}</li>;
                      }
                    )}
                  </ul>
                  <p className="mb-3">
                    {
                      textData.extendingLiferayWithClientExtensions
                        .roleOfPartners.description2
                    }
                  </p>
                </div>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">
                  {textData.deployingLiferayClientExtensions.title}
                </h3>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.deployingLiferayClientExtensions.introduction
                          .title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.deployingLiferayClientExtensions.introduction
                        .description
                    }
                  </p>
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.deployingLiferayClientExtensions.prerequisites
                          .title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.deployingLiferayClientExtensions.prerequisites
                        .description
                    }
                  </p>
                  <div>
                    {textData.deployingLiferayClientExtensions.prerequisites.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p
                                className=""
                                dangerouslySetInnerHTML={{
                                  __html: feature.description,
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-5">
                  <p className="mb-3">
                    <strong>

                    {
                      textData.deployingLiferayClientExtensions
                        .step1CloneTheRepository.title
                    }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.deployingLiferayClientExtensions
                        .step1CloneTheRepository.description1
                    }
                  </p>
                  <CodeBlock
                    language="shell"
                    code={
                      textData.deployingLiferayClientExtensions
                        .step1CloneTheRepository.code
                    }
                  />
                  <p className="mb-3 mt-2">
                    {
                      textData.deployingLiferayClientExtensions
                        .step1CloneTheRepository.description2
                    }
                  </p>
                </div>
                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.deployingLiferayClientExtensions
                          .settingUpTheProject.title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.deployingLiferayClientExtensions
                        .settingUpTheProject.description1
                    }
                  </p>
                  <CodeBlock
                    language="shell"
                    showLineNumbers={true}
                    code={
                      textData.deployingLiferayClientExtensions
                        .settingUpTheProject.code
                    }
                  />
                  <p
                    className="mb-3 mt-2"
                    dangerouslySetInnerHTML={{
                      __html:
                        textData.deployingLiferayClientExtensions
                          .settingUpTheProject.description2,
                    }}
                  />
                </div>
                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.deployingLiferayClientExtensions
                          .deployingTheActivationKey.title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.deployingLiferayClientExtensions
                        .deployingTheActivationKey.description1
                    }
                  </p>
                  <CodeBlock
                    language="shell"
                    code={
                      textData.deployingLiferayClientExtensions
                        .deployingTheActivationKey.code
                    }
                  />
                  <p
                    className="mb-3 mt-2"
                    dangerouslySetInnerHTML={{
                      __html:
                        textData.deployingLiferayClientExtensions
                          .deployingTheActivationKey.description2,
                    }}
                  />
                </div>
                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.deployingLiferayClientExtensions
                          .buildingTheProject.title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.deployingLiferayClientExtensions
                        .buildingTheProject.description1
                    }
                  </p>
                  <CodeBlock
                    language="shell"
                    code={
                      textData.deployingLiferayClientExtensions
                        .buildingTheProject.code
                    }
                  />
                  <p className="mb-3 mt-2">
                    {
                      textData.deployingLiferayClientExtensions
                        .buildingTheProject.description2
                    }{" "}
                  </p>
                </div>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">
                  {textData.reactAndNodejsApplications.title}
                </h3>
                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.reactAndNodejsApplications
                          .buildAndDeployReactApplication.title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.reactAndNodejsApplications
                        .buildAndDeployReactApplication.description1
                    }
                  </p>
                  <CodeBlock
                    language="shell"
                    code={
                      textData.reactAndNodejsApplications
                        .buildAndDeployReactApplication.code1
                    }
                  />
                  <p className="mb-3 mt-2">
                    {
                      textData.reactAndNodejsApplications
                        .buildAndDeployReactApplication.description2
                    }{" "}
                  </p>
                  <CodeBlock
                    language="shell"
                    code={
                      textData.reactAndNodejsApplications
                        .buildAndDeployReactApplication.code2
                    }
                  />
                  <p className="mb-3 mt-2">
                    {
                      textData.reactAndNodejsApplications
                        .buildAndDeployReactApplication.description3
                    }{" "}
                  </p>
                  <CodeBlock
                    language="shell"
                    code={
                      textData.reactAndNodejsApplications
                        .buildAndDeployReactApplication.code3
                    }
                  />
                </div>
              </section>

              <section className="mb-5">
                <h3 className="mb-3">{textData.nodejs.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: textData.nodejs.description,
                  }}
                />
                <div className="mt-4">
                  <p className="mb-3">
                    <strong>
                      {textData.nodejs.buildAndDeployNodeApplication.title}
                    </strong>
                  </p>
                  <p className="mb-3">
                    {textData.nodejs.buildAndDeployNodeApplication.description1}
                  </p>
                  <CodeBlock
                    language="shell"
                    code={textData.nodejs.buildAndDeployNodeApplication.code1}
                  />
                  <p className="mb-3 mt-2">
                    {textData.nodejs.buildAndDeployNodeApplication.description2}{" "}
                  </p>
                  <CodeBlock
                    language="shell"
                    code={textData.nodejs.buildAndDeployNodeApplication.code2}
                  />
                </div>
                <div className="mt-4">
                  <p className="mb-3">
                    <strong>
                      {textData.nodejs.localDevelopmentWithoutDocker.title}
                    </strong>
                  </p>
                  <p className="mb-3">
                    {textData.nodejs.localDevelopmentWithoutDocker.description}
                  </p>
                  <ol>
                    {textData.nodejs.localDevelopmentWithoutDocker.points.map(
                      (point, index) => {
                        return (
                          <li key={index}>
                            <p dangerouslySetInnerHTML={{ __html: point }} />
                          </li>
                        );
                      }
                    )}
                  </ol>
                </div>
                <div className="mt-4">
                  <p className="mb-3">
                    <strong>{textData.nodejs.runningWithDocker.title}</strong>
                  </p>
                  <p className="mb-3">
                    {textData.nodejs.runningWithDocker.description}
                  </p>
                  <ol>
                    {textData.nodejs.runningWithDocker.points.map(
                      (point, index) => {
                        return <li key={index}>{point}</li>;
                      }
                    )}
                  </ol>
                </div>
                <div className="mt-4">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.nodejs.updatingDomainConfigurationOnTheFly
                          .title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.nodejs.updatingDomainConfigurationOnTheFly
                        .description
                    }
                  </p>
                  <ol>
                    {textData.nodejs.updatingDomainConfigurationOnTheFly.points.map(
                      (point, index) => {
                        return <li key={index}>{point}</li>;
                      }
                    )}
                  </ol>
                  <CodeBlock
                    language="shell"
                    code={
                      textData.nodejs.updatingDomainConfigurationOnTheFly.code
                    }
                  />
                </div>
              </section>

              <section className="mb-5 deployingserver-section">
                <h3 className="mb-3">
                  {textData.deployingOnLiferaySaaSServer.title}
                </h3>
                <p className="mb-3">
                  {textData.deployingOnLiferaySaaSServer.description}
                </p>
                <CodeBlock
                  language="shell"
                  code={textData.deployingOnLiferaySaaSServer.code}
                />
              </section>

              <section className="overview mb-5">
                <div className="row conclusion-row">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-8">
                    <h3 className="mb-3">{textData.conclusion.title}</h3>
                    <p className="mb-3">{textData.conclusion.description}</p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-4 d-flex justify-content-center align-items-center">
                    <img
                      className="mb-3 w-75"
                      src={textData.conclusion.img}
                      alt="overview-img"
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <TechnologyAlliances data={textData.alliacessection} />
      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer8.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer8.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
export default LiferayDXP;
