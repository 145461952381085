

import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import ContactUsButton from './ContactUsButton';
import HeroComponent from './HeroComponent';
import MarqueeComponent from './MarqueeComponent';
import SuccessStoriesList from './SuccessStoriesList';
import TechnologyAlliances from './TechnologyAlliances';
import config from '../config';

const textDataUrl = `${page_json.page_jsons.Liferay}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Liferay() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
    <div data-aos="fade-up" className="liferay">
      <Helmet>
        <meta
          name="description"
          content="Building a robust Digital Experience Platform with Liferay"
        />
        <title>Liferay | Technologies | Engineersmind</title>
      </Helmet>
      {/* <div className="container-fluid m-0 p-0 brd-crmb liferaypage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/">Home </a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/Technologies/Salesforce">Technologies </a>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Liferay
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container-fluid p-0">
				<div id="carouselExampleAutoplaying" className="carousel slide liferaypage">
                    <div className="carousel-inner">
                        {
                            textData.editable_carousel_banner.map((item, index) => {
                                if (index === 0 ) {
                                    return( 
                                        <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                    <div className="inner-banner-text">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                    <h1 className="animate__animated animate__fadeInLeft">
																		<span>{item.heading.text1}</span>
																		{item.heading.text2}<br /> 
																		<span>{item.heading.text3}</span> 
																		{item.heading.text4}<br />{item.heading.text5}
																	</h1>
																	<ContactUsButton className="dark" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>)
                                    } else {
                                        return( 
                                        <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                            <div className="row">
                                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                                    <img src={item.img} className="d-block w-100" alt="banner-img" />
                                                    <div className="inner-banner-text">
                                                        <div className="container">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                                                    <h1 className="animate__animated animate__fadeInLeft">
																		<span>{item.heading.text1}</span>
																		{item.heading.text2}<br /> 
																		<span>{item.heading.text3}</span> 
																		{item.heading.text4}<br />
																		{item.heading.text5}
																	</h1>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                            })
                                
                        }
                    </div>
                    <div className="control-btn-2">
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                            <i className="fa-solid fa-arrow-right"></i>
                            <span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
                            <i className="fa-solid fa-arrow-left"></i>
                            <span className="visually-hidden">Previous</span>
                        </button>
                    </div>
                </div>
			</div> */}

      <HeroComponent
        isSlider={true}
        isBreadCumb={true}
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Technologies/Salesforce">Technologies </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Liferay
            </li>
          </ol>
        }
        textData={textData}
        isBannerLogo={false} contactUsBtn={true}
      />

      <div className="container-fluid liferay-sub-header-section">
        <div className="container">
          <div className="row sub-header-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="left-content d-flex flex-column justify-content-center h-100">
                <h4 className="mb-4">{textData.layer11.heading}</h4>
                <p>{textData.layer11.body2}</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
              <div className="right-img d-flex justify-content-center">
                <img
                  src={textData.layer11.img}
                  className="img-fluid"
                  alt="industry"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="alliances d-flex flex-column justify-content-center align-items-center mb-5">
        <h1 className="text-white text-center">
          {textData.layer9.heading.text1}
          <span>{textData.layer9.heading.text2}</span>
          {textData.layer9.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.layer9.button.text}
          link="#ContactForm"
          className="light"
        />
      </div>

      <div className="container-fluid matrix liferaypage">
        <div className="container">
          <h1>{textData.layer12.heading}</h1>
          <p>{textData.layer12.heading_details}</p>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 tabs">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="UA"
                  role="tabpanel"
                  aria-labelledby="unified"
                  data-is-left-layout={true}
                >
                  <div className="tab-content-detailsflex">
                    <div className="tab-content-details col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <h4>{textData.layer12.details.data1.heading}</h4>
                      <p>{textData.layer12.details.data1.body}</p>
                    </div>
                    <div className="tab-content-img col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <img
                        src={textData.layer12.details.data1.img}
                        className="d-block w-100"
                        alt="banner-img"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="SDI"
                  role="tabpanel"
                  aria-labelledby="streamlined"
                >
                  <div className="tab-content-detailsflex">
                    <div className="tab-content-details col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <h4>{textData.layer12.details.data2.heading}</h4>
                      <p>{textData.layer12.details.data2.body}</p>
                    </div>
                    <div className="tab-content-img col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <img
                        src={textData.layer12.details.data2.img}
                        className="d-block w-100"
                        alt="banner-img"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="SDW"
                  role="tabpanel"
                  aria-labelledby="scalabale"
                  data-is-left-layout={true}
                >
                  <div className="tab-content-detailsflex">
                    <div className="tab-content-details col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <h4>{textData.layer12.details.data3.heading}</h4>
                      <p>{textData.layer12.details.data3.body}</p>
                    </div>
                    <div className="tab-content-img col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <img
                        src={textData.layer12.details.data3.img}
                        className="d-block w-100"
                        alt="banner-img"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="DEV"
                  role="tabpanel"
                  aria-labelledby="visualization"
                >
                  <div className="tab-content-detailsflex">
                    <div className="tab-content-details col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <h4>{textData.layer12.details.data4.heading}</h4>
                      <p>{textData.layer12.details.data4.body}</p>
                    </div>
                    <div className="tab-content-img col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <img
                        src={textData.layer12.details.data4.img}
                        className="d-block w-100"
                        alt="banner-img"
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="tab-pane fade show active"
                  id="AAML"
                  role="tabpanel"
                  aria-labelledby="advanced"
                  data-is-left-layout={true}
                >
                  <div className="tab-content-detailsflex">
                    <div className="tab-content-details col-lg-8 col-md-12 col-sm-12 col-xs-12">
                      <h4>{textData.layer12.details.data5.heading}</h4>
                      <p>{textData.layer12.details.data5.body}</p>
                    </div>
                    <div className="tab-content-img col-lg-4 col-md-12 col-sm-12 col-xs-12">
                      <img
                        src={textData.layer12.details.data5.img}
                        className="d-block w-100"
                        alt="banner-img"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid globally liferaypage">
        <div className="container">
          <h1>
            <span>{textData.layer13.heading.text1} </span> <br />{" "}
            {textData.layer13.heading.text2}
          </h1>
          <p class="globally-liferaypage-p">
            {textData.layer13.heading_details}
          </p>
        </div>
      </div>
      <div className="py-5 container-fluid">
        <div className="container p-0">
          {textData.layer13.details.map((item, index) => {
            if (index % 2 === 0) {
              return (
                <div className="container-fluid liferaypage experiences">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="boosting">
                        <div class="boostingtxtnontitle">
                          {/* <span>0{index+1}</span> */}
                          <h5>{item.heading}</h5>
                        </div>
                        <p>{item.body}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="boosting-img">
                        <img
                          src={item.img}
                          className="d-block w-100"
                          alt="banner-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <div className="container-fluid liferaypage experiences">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="boosting-img">
                        <img
                          src={item.img}
                          className="d-block w-100"
                          alt="banner-img"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                      <div className="boosting">
                        <div class="boostingtxtnontitle">
                          {/* <span>0{index+1}</span> */}
                          <h5>{item.heading}</h5>
                        </div>
                        <p>{item.body}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>

      {/*------------------------------------------------------------------------------------- 

			<div className="container-fluid manufacture">
				<div className="container">
					<div className="row">
						<div className="col-lg-5 col-md-12 col-sm-12 col-xs-12">
							<div className="card-9">
								<h1>{textData.layer2.heading.text1}</h1>
								<h5>{textData.layer2.heading.text2}</h5>&nbsp;
								<p>{textData.layer2.body.text1}</p>&nbsp;
								<p>{textData.layer2.body.text2}</p>
							</div>
						</div>
						<div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">&nbsp;
							<div className="manufacture-img">
								<img src={textData.layer2.img} className="img-fluid" alt="industry" />
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid key-features">
				<div className="container">
					<h1>{textData.layer3.heading.text1} <span>{textData.layer3.heading.text2}</span></h1>
					<div className="row">
						{
							textData.layer3.data.map((item, index) => {
								return(
									<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
										<div className="module-border-wrap">
											<div className="module">
												<p id="pdng">{item}</p>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>

			<div className="container-fluid liferay-inner">
				<div className="container">
					<h1>{textData.layer4.heading.text1}<br /> {textData.layer4.heading.text2}</h1>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<p>{textData.layer4.body.text1}</p>&nbsp;
							<p>{textData.layer4.body.text1}</p>
						</div>
					</div>
				</div>
				<div className="container">
					<h1>{textData.layer5.heading.text1}<br />{textData.layer5.heading.text2}</h1>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<p>{textData.layer5.body}</p>&nbsp;
							<div id="carouselCaptionsInner" className="carousel slide">
								<div className="carousel-inner">
								{
									textData.layer5.editable_details.map((item, index) => {
										if ( index === 0 ) {
											return (
												<div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
													<div className="card-10">
														<div className="row">
															<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																<ul className="liferay-slide">
																	<li><h5>{item.left.serial_no}</h5></li>
																	<li>{item.left.text}</li>
																</ul>
															</div>
															<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																<ul className="liferay-slide">
																	<li><h5>{item.right.serial_no}</h5></li>
																	<li>{item.right.text}</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											)
										} else {
											return (
												<div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
													<div className="card-10">
														<div className="row">
															<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																<ul className="liferay-slide">
																	<li><h5>{item.left.serial_no}</h5></li>
																	<li>{item.left.text}</li>
																</ul>
															</div>
															<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																<ul className="liferay-slide">
																	<li><h5>{item.right.serial_no}</h5></li>
																	<li>{item.right.text}</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											)
										}
									})
								}
								</div>
								<div className="control-btn-11">
									<button className="carousel-control-next" type="button" data-bs-target="#carouselCaptionsInner" data-bs-slide="next">
										<i className="fa-solid fa-arrow-right"></i>
										<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
										<span className="visually-hidden">{textData.layer5.button_next}</span>
									</button>
									<button className="carousel-control-prev" type="button" data-bs-target="#carouselCaptionsInner" data-bs-slide="prev">
										<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
										<i className="fa-solid fa-arrow-left"></i>
										<span className="visually-hidden">{textData.layer5.button_previous}</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="container">
					<h1>{textData.layer6.heading}</h1>
					<p>{textData.layer6.body}</p>&nbsp;
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<ul className="liferay-slide">
								<img src={textData.layer6.details.data1.img} alt="logos" className="img-fluid" />
								<li>{textData.layer6.details.data1.text}</li>
							</ul>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<ul className="liferay-slide">
								<img src={textData.layer6.details.data2.img} alt="logos" className="img-fluid" />
								<li>{textData.layer6.details.data2.text}</li>
							</ul>
						</div>
						&nbsp;
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<ul className="liferay-slide">
								<img src={textData.layer6.details.data3.img} alt="logos" className="img-fluid" />
								<li>{textData.layer6.details.data3.text}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div className="container">
				<h1>{textData.layer7.heading}</h1>
				<div className="row">
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
						<ul className="liferay-slide-1">
							<li><h5>{textData.layer7.details.data1.serial_no}</h5></li>
							<li>{textData.layer7.details.data1.text}</li>
						</ul>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
						<ul className="liferay-slide-1">
							<li><h5>{textData.layer7.details.data2.serial_no}</h5></li>
							<li>{textData.layer7.details.data2.text}</li>
						</ul>
					</div>
					<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
						<ul className="liferay-slide-1">
							<li><h5>{textData.layer7.details.data3.serial_no}</h5></li>
							<li>{textData.layer7.details.data3.text}</li>
						</ul>
					</div>
				</div>
			</div>
*/}
      {/* <div className="container-fluid trust">
        <div className="container trusted-img">
          <div className="row">
            <h1>{textData.layer8.heading}</h1>
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <img
                src={textData.layer8.img}
                alt="logos"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container-fluid alliance">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
              <div className="heading">
                <h1>
                  <span>{textData.layer9.heading.text1}</span>
                  {textData.layer9.heading.text2}
                  <span>{textData.layer9.heading.text3}</span>
                </h1>
              </div>
              <div className="more-2">
                <a href="#ContactForm">
                  {textData.layer9.button.text}
                  <i className="fa-solid fa-arrow-right"></i>
                </a>
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
              <div className="gif">
                <img
                  src={textData.layer9.button.img}
                  alt="gif"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}

<SuccessStoriesList StoriesList={textData.storiesList} />

{/* <div className="full-height-section alliances-container">
        <div className="d-flex flex-column h-100 py-5">
          <div className="sub-section">
            <h1>{textData.alliacessection.heading1}</h1>
            <img
              src={textData.alliacessection.img1}
              alt="logos"
              className="img-fluid"
            />
          </div>
          <div className="sub-section">
            <h1>{textData.alliacessection.heading2}</h1>
            <img
              src={textData.alliacessection.img2}
              alt="logos"
              className="img-fluid web"
            />
            <img
              src={textData.alliacessection.imgMobile}
              alt="logos"
              className="img-fluid mobile"
            />
          </div>
        </div>
      </div> */}

    <TechnologyAlliances data={textData.alliacessection} />

    <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer10.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer10.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default Liferay;