import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import HeroComponent from "./HeroComponent";
import MarqueeComponent from "./MarqueeComponent";
import CodeBlock from "./CodeBlock";
import TechnologyAlliances from "./TechnologyAlliances";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.LiferayDashboard}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function LiferayDashboard() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" className="caseStudyPage liferayDashboard">
      <Helmet>
        <meta
          name="description"
          content="Creating a dynamic and personalized dashboard using Liferay Client Extensions and React, enabling users to customize layouts, add widgets, and manage data efficiently."
        />
        <title>Liferay Custom Dashboard | Engineersmind</title>
      </Helmet>

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.banner}
        textContent={
          <>
            <p>Case Study</p>
            <h1>
              <span>{textData.layer1.title}</span>
            </h1>
          </>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Resources">Resources </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Resources/Blogs">Blogs </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Liferay Custom Dashboard
            </li>
          </ol>
        }
      />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: textData.leftSectionContent.text2,
                    }}
                  />
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h3>{textData.introduction.title}</h3>
                <p className="mb-3">{textData.introduction.description}</p>
              </section>

              <div className="alliances d-flex flex-column justify-content-center align-items-center rounded mt-5 mb-5 pl-2 pr-2 bg-white">
                <h1 className="text-black text-center">
                  {textData.layer7.heading.text1}
                  <span>{textData.layer7.heading.text2}</span>
                  {textData.layer7.heading.text3}
                </h1>
                <ContactUsButton
                  text={textData.layer7.button.text}
                  link="#ContactForm"
                  className="light"
                  isLightText={false}
                />
              </div>

              <section>
                <h3>{textData.whyADashboardIsNeeded.title}</h3>
                <p className="mb-3">
                  {textData.whyADashboardIsNeeded.description1}
                </p>

                <div className="row case-study-img-row">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <>
                      <ul>
                        {textData.whyADashboardIsNeeded.keyFeatures.map(
                          (point, index) => {
                            return (
                              <li key={index}>
                                <strong>{point.title}</strong>:{" "}
                                {point.description}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 d-flex justify-content-center align-items-center">
                    <img
                      src={textData.whyADashboardIsNeeded.img}
                      alt=""
                      width={"100%"}
                      height={"auto"}
                    />
                  </div>
                </div>
                <p className="mb-3">
                  {textData.whyADashboardIsNeeded.description2}
                </p>
              </section>

              <hr />

              <section>
                <h3>{textData.whatUsersCanDo.title}</h3>

                <div className="row case-study-img-row">
                  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                    <p className="mb-3">
                      {textData.whatUsersCanDo.description1}
                    </p>
                    <ul>
                      {textData.whatUsersCanDo.points.map((point, index) => {
                        return (
                          <li key={index}>
                            <strong>{point}</strong>
                          </li>
                        );
                      })}
                    </ul>
                    <p className="mb-3">
                      {textData.whatUsersCanDo.description2}
                    </p>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                    <img
                      src={textData.whatUsersCanDo.img}
                      alt=""
                      width={"100%"}
                      height={"auto"}
                    />
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.projectArchitecture.title}</h3>
                <p className="mb-3">
                  {textData.projectArchitecture.description}
                </p>
                {textData.projectArchitecture.steps.map((step, index) => {
                  return (
                    <div>
                      <p>
                        <strong>{step.title}</strong>
                      </p>
                      <ul>
                        {step.points.map((point, index) => {
                          return <li key={index}>{point}</li>;
                        })}
                      </ul>
                    </div>
                  );
                })}
              </section>

              <hr />

              <section>
                <h3 className="mt-5">
                  {textData.managingTheDashboardInLiferay.title}
                </h3>

                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {textData.managingTheDashboardInLiferay.step1.title}
                    </strong>
                  </p>
                  <p>
                    {textData.managingTheDashboardInLiferay.step1.description}
                  </p>
                  <ul className="ml-5 mt-3">
                    {textData.managingTheDashboardInLiferay.step1.points.map(
                      (point, index) => {
                        return (
                          <li
                            className="mt-2"
                            key={index}
                            style={{ listStyle: "none" }}
                          >
                            <div>{point.title}</div>
                            {point.code && (
                              <CodeBlock
                                language="javascript"
                                code={point.code}
                              />
                            )}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>

                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {textData.managingTheDashboardInLiferay.step2.title}
                    </strong>
                  </p>
                  <p>
                    {textData.managingTheDashboardInLiferay.step2.description}
                  </p>
                  <ul className="ml-5 mt-3">
                    {textData.managingTheDashboardInLiferay.step2.points.map(
                      (point, index) => {
                        return (
                          <li className="mt-5" key={index}>
                            <div className="mb-3">{point.title}</div>
                            {point.code && (
                              <CodeBlock
                                language="javascript"
                                code={point.code}
                              />
                            )}
                          </li>
                        );
                      }
                    )}
                  </ul>
                </div>

                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {textData.managingTheDashboardInLiferay.step3.title}
                    </strong>
                  </p>
                  <p>
                    {textData.managingTheDashboardInLiferay.step3.description}
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={textData.managingTheDashboardInLiferay.step3.code}
                  />
                </div>
              </section>

              <hr />

              <section>
                <h3 className="mt-5">
                  {textData.managingTheDashboardInLiferay2.title}
                </h3>
                <p className="mb-3">
                  {textData.managingTheDashboardInLiferay2.description}
                </p>

                <div className="">
                  <p className="mb-3">
                    <strong>
                      {textData.managingTheDashboardInLiferay2.step1.title}
                    </strong>
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={textData.managingTheDashboardInLiferay2.step1.code}
                  />
                </div>

                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {textData.managingTheDashboardInLiferay2.step2.title}
                    </strong>
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={textData.managingTheDashboardInLiferay2.step2.code}
                  />
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.conclusion.title}</h3>
                <p className="mb-3">{textData.conclusion.description}</p>
              </section>
            </div>
          </div>
        </div>
      </div>

      <TechnologyAlliances data={textData.alliacessection} />

      <MarqueeComponent assets={textData.clients} />

      {/* <div className="alliances d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-white text-center">
          {textData.layer7.heading.text1}
          <span>{textData.layer7.heading.text2}</span>
          {textData.layer7.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.layer7.button.text}
          link="#ContactForm"
          className="light"
        />
      </div> */}
      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer8.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer8.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default LiferayDashboard;
