
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import ContactUsButton from './ContactUsButton';
import config from '../config';

const textDataUrl = `${page_json.page_jsons.FinancialCs1}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();


function 	FinancialCs1() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);

	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Accelerated & seamless FINRA COMPLIANCE implementation in 45 days" />
                <title>FINRA Compliance | Financial Services | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home</a></li>
									<li className="breadcrumb-item"><a href="/Industries/Financial-Services">Financial Services</a></li>
									<li className="breadcrumb-item active" aria-current="page">Case Study 1 FINRA Compliance</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid casestudy">
				<div className="container">
					<h1>{textData.layer1.heading.text1}<span>{textData.layer1.heading.text2}<br />{textData.layer1.heading.text3}</span>{textData.layer1.heading.text4}<br />{textData.layer1.heading.text5}</h1><br /><br />
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data1.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data1.heading}</h4>
								<p>{textData.layer1.details.data1.body}</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data2.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data2.heading}</h4>
								<p>{textData.layer1.details.data2.body}</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer1.details.data3.img} alt="gif" className="img-fluid" />
								<h4>{textData.layer1.details.data3.heading}</h4>
								<p>{textData.layer1.details.data3.body}</p>
							</div>
						</div>
						<br />
						<h5><span>{textData.layer2.heading}</span></h5>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer2.details.data1.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data1.body}</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer2.details.data2.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data2.body}</p>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<img src={textData.layer2.details.data3.img} alt="gif" className="img-fluid" />
								<p>{textData.layer2.details.data3.body}</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid cs-solution-1">
				<div className="container">
					<h1><span>{textData.layer3.heading.text1}</span>{textData.layer3.heading.text2}<br />{textData.layer3.heading.text3}<span>{textData.layer3.heading.text4}</span></h1>
					<div className="row">
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module1}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module2}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p>{textData.layer3.data.module3}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module4}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module5}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module6}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p>{textData.layer3.data.module7}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer3.data.module8}</p>
								</div>
							</div>
						</div>
					</div>
					<div className="ul-list">
						<h1>{textData.layer4.heading.text1}<span>{textData.layer4.heading.text2}</span></h1>
						<p>{textData.layer4.body}</p>&nbsp;
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<ul>
									<li><span>{textData.layer4.details.data1.serial_no}</span>{textData.layer4.details.data1.text}</li>
									<li><span>{textData.layer4.details.data2.serial_no}</span>{textData.layer4.details.data2.text}</li>
									<li><span>{textData.layer4.details.data3.serial_no}</span>{textData.layer4.details.data3.text}</li>
								</ul>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<ul>
									<li><span>{textData.layer4.details.data4.serial_no}</span>{textData.layer4.details.data4.text}</li>
									<li><span>{textData.layer4.details.data5.serial_no}</span>{textData.layer4.details.data5.text}</li>
								</ul>
							</div>
						</div>
					</div>
					<ContactUsButton className="light mt-2" />
				</div>
			</div>


			<div className="container-fluid build">
				<div className="container">
					<h1>{textData.layer5.heading}</h1>
					<p>{textData.layer5.body}</p>&nbsp;
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
							<img src={textData.layer5.img} alt="gif" className="img-fluid" />
						</div>
						<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
							<ul>
								<li><span>{textData.layer5.details.data1.serial_no}</span>{textData.layer5.details.data1.text}</li>
								<li><span>{textData.layer5.details.data2.serial_no}</span>{textData.layer5.details.data2.text}</li>
								<li><span>{textData.layer5.details.data3.serial_no}</span>{textData.layer5.details.data3.text}</li>
								<li><span>{textData.layer5.details.data4.serial_no}</span>{textData.layer5.details.data4.text}</li>
								<li><span>{textData.layer5.details.data5.serial_no}</span>{textData.layer5.details.data5.text}</li>
							</ul>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
							<h1>{textData.layer6.heading}</h1>
							<div id="carouselExample1" className="carousel slide">
								<div className="carousel-inner">
								{
                                    textData.layer6.details.map((item, index) => {
                                        if (index === 0 ) {
                                            return( 
                                                <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active") }>
                                                    <div className="row">
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<div className="card-12">
																<h5>{item.serial_no}</h5>
																<p>{item.text}</p>
															</div>
														</div>
													</div>
                                                </div>)
                                            } else {
                                                return( 
                                                    <div className={ "carousel-item carousel-item-".concat(String.toString(index + 1)) }>
                                                    <div className="row">
														<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
															<div className="card-12">
																<h5>{item.serial_no}</h5>
																<p>{item.text}</p>
															</div>
														</div>
													</div>
                                                </div>
                                            )}
									})
                                        
                                }
								</div>&nbsp;
								<div className="control-btn-14">
									<button className="carousel-control-next" type="button" data-bs-target="#carouselExample1" data-bs-slide="next">
										<i className="fa-solid fa-arrow-right"></i>
										<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
										<span className="visually-hidden">Next</span>
									</button>
									<button className="carousel-control-prev" type="button" data-bs-target="#carouselExample1" data-bs-slide="prev">
										<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
										<i className="fa-solid fa-arrow-left"></i>
										<span className="visually-hidden">Previous</span>
									</button>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
							<img src={textData.layer6.img} alt="gif" className="img-fluid" />
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid status">
				<div className="container">
					<h1>{textData.layer7.heading}</h1>
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<ul>
								<li><span>{textData.layer7.details.data1.serial_no}</span>{textData.layer7.details.data1.text}</li>
								<li><span>{textData.layer7.details.data2.serial_no}</span>{textData.layer7.details.data2.text}</li>
								<li><span>{textData.layer7.details.data3.serial_no}</span>{textData.layer7.details.data3.text}</li>
							</ul>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
							<ul>
								<li><span>{textData.layer7.details.data4.serial_no}</span>{textData.layer7.details.data4.text}</li>
								<li><span>{textData.layer7.details.data5.serial_no}</span>{textData.layer7.details.data5.text}</li>
							</ul>
						</div>
					</div><br /><br />
					<h5><span>{textData.layer7.body.text1}</span>{textData.layer7.body.text2}<br />{textData.layer7.body.text3}</h5>&nbsp;
					<div className="more-2">
						<a href="#ContactForm">{textData.layer7.button.text}<i className="fa-solid fa-arrow-right"></i></a>
					</div>

				</div>
			</div>
			<div className="container-fluid ContactForm" id='ContactForm'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
			<div className="TA-bg">
				<div className="container">
					<div className="row">
						<h1>{textData.layer8.heading}</h1>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer8.img} alt="logos" className="img-fluid" />

						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default FinancialCs1;