import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import HeroComponent from "./HeroComponent";
import MarqueeComponent from "./MarqueeComponent";
import TechnologyAlliances from "./TechnologyAlliances";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.AIChatbot}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function AIChatbot() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" className="caseStudyPage AIChatbot">
      <Helmet>
        <meta name="description" content="Building an AI-Powered Chatbot Solution by leveraging advanced AI technology to enhance customer interactions, improve efficiency, and deliver seamless user experiences." />
        <title>AIChatbot | Engineersmind</title>
      </Helmet>

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.banner}
        textContent={
          <>
            <p>Case Study</p>
            <h1>
              <span>{textData.layer1.title}</span>
            </h1>
          </>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
            <a href="/Industries/Financial-Services">
              Financial Services
            </a>
          </li>
            <li className="breadcrumb-item active" aria-current="page">
              AI Chatbot
            </li>
          </ol>
        }
      />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong dangerouslySetInnerHTML={{__html: textData.leftSectionContent.text2}} />
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section >
                <h3>{textData.introduction.title}</h3>
                  <p className="mb-3">{textData.introduction.description}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.introduction.img}
                    alt="overview-img"
                  />
                </div>
                <ul>
                  {textData.introduction.points.map((point, index) => {
                    return <li key={index}>{point}</li>;
                  })}
                </ul>
              </section>

              {/* <hr /> */}

              <div className="alliances d-flex flex-column justify-content-center align-items-center rounded mt-5 mb-5 pl-2 pr-2 bg-white">
                <h1 className="text-black text-center">
                  {textData.layer7.heading.text1}
                  <span>{textData.layer7.heading.text2}</span>
                  {textData.layer7.heading.text3}
                </h1>
                <ContactUsButton
                  text={textData.layer7.button.text}
                  link="#ContactForm"
                  className="light"
                  isLightText = {false}
                />
              </div>

              <section>
                <h3>{textData.challenges.title}</h3>
                <p className="mb-3">{textData.challenges.description}</p>
                <div className="mt-3">
                  <div>
                    {textData.challenges.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <p>{feature.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.aIChatbotImplementation.title}</h3>
                <p className="mb-3">
                  {textData.aIChatbotImplementation.description}
                </p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.aIChatbotImplementation.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <div>
                    {textData.aIChatbotImplementation.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p>{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <hr />

              <section className="overview">
                <h3>{textData.technicalArchitecture.title}</h3>
                <p className="mb-3">
                  {textData.technicalArchitecture.description}
                </p>
                <div className="d-flex justify-content-center">
                  <img
                    className="w-100"
                    src={textData.technicalArchitecture.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <div>
                    {textData.technicalArchitecture.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <ul>
                                <li>{feature.description1}</li>
                                <li>{feature.description2}</li>
                              </ul>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <hr />

              <section className="overview">
                <h3>{textData.dataStrategyAndImplementation.title}</h3>
                <p className="mb-3">
                  {textData.dataStrategyAndImplementation.description}
                </p>
                <div className="d-flex justify-content-center">
                  <img
                    className="w-50 AIChatbot-introduction-img"
                    src={textData.dataStrategyAndImplementation.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <div>
                    {textData.dataStrategyAndImplementation.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <ul>
                                <li>{feature.description1}</li>
                                <li>{feature.description2}</li>
                              </ul>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <hr />

              <section >
                <h3>{textData.resultsAndKeyInsights.title}</h3>
                <p className="mb-3">
                  {textData.resultsAndKeyInsights.description}
                </p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.resultsAndKeyInsights.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <div>
                    {textData.resultsAndKeyInsights.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <ul>
                                <li>{feature.description1}</li>
                                <li>{feature.description2}</li>
                                {feature?.description3 && (
                                  <li>{feature.description3}</li>
                                )}
                              </ul>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <hr />

              <section >
                <h3>{textData.conclusion.title}</h3>
                  <p className="mb-3">{textData.conclusion.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.conclusion.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <div>
                    {textData.conclusion.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <ul>
                              <li>{feature.description1}</li>
                              <li>{feature.description2}</li>
                              <li>{feature.description3}</li>
                              {feature?.description4 && (
                                <li>{feature.description4}</li>
                              )}
                              {feature?.description5 && (
                                <li>{feature.description5}</li>
                              )}
                              {feature?.description6 && (
                                <li>{feature.description6}</li>
                              )}
                              {feature?.description7 && (
                                <li>{feature.description7}</li>
                              )}
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p className="mb-3">{textData.conclusion.description2}</p>
              </section>
            </div>
          </div>
        </div>
      </div>

      <TechnologyAlliances data={textData.alliacessection} />
      <MarqueeComponent assets={textData.clients} />

      {/* <div className="alliances d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-white text-center">
          {textData.layer7.heading.text1}
          <span>{textData.layer7.heading.text2}</span>
          {textData.layer7.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.layer7.button.text}
          link="#ContactForm"
          className="light"
        />
      </div> */}
      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer8.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer8.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default AIChatbot;
