import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import HeroComponent from "./HeroComponent";
import MarqueeComponent from "./MarqueeComponent";
import TechnologyAlliances from "./TechnologyAlliances";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.ManufacturingPIM}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function ManufacturingPIM() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" className="caseStudyPage ManufacturingPIM">
      <Helmet>
        <meta
          name="description"
          content=" Transforming Plastics Injection Molding with Predictive Analytics."
        />
        <title>Transforming Plastics Injection Molding | Engineersmind</title>
      </Helmet>

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.banner}
        textContent={
          <>
            <p>Case Study</p>
            <h1>
              <span>{textData.layer1.title}</span>
            </h1>
          </>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
            <a href="/Industries/Manufacturing">Manufacturing</a>
          </li>
            <li className="breadcrumb-item active" aria-current="page">
            Transforming Plastics Injection Molding
            </li>
          </ol>
        }
      />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong dangerouslySetInnerHTML={{__html: textData.leftSectionContent.text2}} />
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h3>{textData.introduction.title}</h3>
                <p className="mb-3">{textData.introduction.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.introduction.img}
                    alt="overview-img"
                  />
                </div>
                <p className="mb-3">{textData.introduction.description2}</p>
              </section>


              <div className="alliances d-flex flex-column justify-content-center align-items-center rounded mt-5 mb-5 pl-2 pr-2 bg-white">
                <h1 className="text-black text-center">
                  {textData.layer7.heading.text1}
                  <span>{textData.layer7.heading.text2}</span>
                  {textData.layer7.heading.text3}
                </h1>
                <ContactUsButton
                  text={textData.layer7.button.text}
                  link="#ContactForm"
                  className="light"
                  isLightText = {false}
                />
              </div>


              <section>
                <h3>{textData.problemStatement.title}</h3>
                <p className="mb-3">{textData.problemStatement.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-ps-img"
                    src={textData.problemStatement.img}
                    alt="overview-img"
                  />
                </div>
                {/*<div className="mt-3">
                  <p className="mb-3">
                      {
                        textData.problemStatement
                          .pointsTitle
                      }
                  </p>
                  <ul>
                    {textData.problemStatement.points.map(
                      (point, index) => {
                        return <li key={index}>{point}</li>;
                      }
                    )}
                  </ul>
                  </div> */}
                <p className="mb-3">{textData.problemStatement.description2}</p>
              </section>

              <hr />

              <section className="overview">
                <h3>{textData.engineersmindsApproaches.title}</h3>
                <div className="helthcare-steps-container">
                  {textData.engineersmindsApproaches.engineersmindApproaches.map(
                    (engineersmindsApproach, index) => {
                      const hasImage = engineersmindsApproach.img && engineersmindsApproach.img.trim() !== "";
                      return (
                        <div key={index}>
                          {/* Approach Step */}
                          <div
                            className={`d-flex ${index % 2 === 0 ? "flex-row" : "flex-row-reverse"} step`}
                          >
                            {/* Conditional class for full width */}
                            {hasImage ? (
                              <>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                  <div className="image-wrapper">
                                    <img src={engineersmindsApproach.img} alt="step-img" />
                                  </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 text-content">
                                  <p className="title">{engineersmindsApproach.title}</p>
                                  <div>{engineersmindsApproach.details}</div>
                                  <div>
                                    <ul className="pl-1">
                                      {Array.isArray(engineersmindsApproach?.points) &&
                                        engineersmindsApproach.points.map((point, pointIndex) => (
                                          <div className="d-flex w-100 list-item" key={pointIndex}>
                                            <div>
                                              <li className="point">
                                                <b>{point.pointTitle}</b>: {point.pointDetails}
                                              </li>
                                            </div>
                                          </div>
                                        ))}
                                    </ul>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-content">
                                <p className="title">{engineersmindsApproach.title}</p>
                                <div>{engineersmindsApproach.details}</div>
                                <div>
                                  <ul className="pl-1">
                                    {Array.isArray(engineersmindsApproach?.points) &&
                                      engineersmindsApproach.points.map((point, pointIndex) => (
                                        <div className="d-flex w-100 list-item" key={pointIndex}>
                                          <div>
                                            <li className="point">
                                              <b>{point.pointTitle}</b>: {point.pointDetails}
                                            </li>
                                          </div>
                                        </div>
                                      ))}
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* Description 1 after the third approach */}
                          {index === 2 && (
                            <div className="full-width-description">
                              <p>{textData.engineersmindsApproaches.engineersmindApproachesDesc1}</p>
                            </div>
                          )}

                          {/* Description 2 after the fourth approach */}
                          {index === 3 && (
                            <div className="full-width-description">
                              <p>{textData.engineersmindsApproaches.engineersmindApproachesDesc2}</p>
                            </div>
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.keyRequirements.title}</h3>
                <div className="mt-3">
                  <div>
                    {textData.keyRequirements.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <p className="mb-3">{feature.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.businessImpact.title}</h3>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img2"
                    src={textData.businessImpact.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                    {textData.businessImpact.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <ul>
                              <li>{feature.point1}</li>
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </section>

              <hr />
              
            </div>
          </div>
        </div>
      </div>

      <TechnologyAlliances data={textData.alliacessection} />

      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer8.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer8.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default ManufacturingPIM;
