import React from 'react'

function TechnologyAlliances({data}) {
  return (
<div className="full-height-section alliances-container component">
        <div className="d-flex flex-column h-100 py-5">
          <div className="sub-section">
            <h1>{data.heading1}</h1>
            <img
              src={data.img1}
              alt="logos"
              className="img-fluid"
            />
          </div>
          <div className="sub-section">
            <h1>{data.heading2}</h1>
            <img
              src={data.img2}
              alt="logos"
              className="img-fluid web"
            />
            <img
              src={data.imgMobile}
              alt="logos"
              className="img-fluid mobile"
            />
          </div>
        </div>
      </div>
  )
}

export default TechnologyAlliances
