import React, { useState, useEffect, useRef } from "react";

const ZigzagCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(true);
  const carouselRef = useRef(null);

  const totalSlides = images.length;
  const slidesToShow = 3; // Number of images visible at once
  const totalSlidesWithClones = totalSlides + slidesToShow; // Extra clones at the end

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 3000); // Auto-slide every 3 seconds

    return () => clearInterval(interval);
  }, [currentIndex]);

  const nextSlide = () => {
    if (currentIndex >= totalSlides) {
      setIsTransitioning(false);
      setCurrentIndex(0); // Reset instantly
      setTimeout(() => {
        setIsTransitioning(true);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 50);
    } else {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const prevSlide = () => {
    if (currentIndex === 0) {
      setIsTransitioning(false);
      setCurrentIndex(totalSlides); // Jump to cloned set
      setTimeout(() => {
        setIsTransitioning(true);
        setCurrentIndex(totalSlides - 1);
      }, 50);
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  return (
    <div className="zigzag-carousel">
        <button className="carousel-btn left" onClick={prevSlide}>
            ❮
        </button>
      <div className="carousel-container">
        <div
          ref={carouselRef}
          className="carousel-track"
          style={{
            transform: `translateX(-${currentIndex * (100 / slidesToShow)}%)`,
            transition: isTransitioning ? "transform 0.5s ease-in-out" : "none",
          }}
        >
          {[...images, ...images.slice(0, slidesToShow)].map((image, index) => (
            <div key={index} className="carousel-item">
              <img src={image.src} alt={image.alt} />
              <div className="carousel-text">{image.alt}</div>
            </div>
          ))}
        </div>
      </div>
      <button className="carousel-btn right" onClick={nextSlide}>
            ❯
          </button>
    </div>
  );
};

export default ZigzagCarousel;
