import React from "react";
import ContactUsButton from "./ContactUsButton";

function SuccessStoriesList({ StoriesList }) {
  return (
    <div className="container-fluid success-stories-list">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="alliances">
              <h3 className="mb-3">
                <span>Success </span>
                Stories
              </h3>
              <div className="story-card mt-2">
                <div className="row row-gap-4">
                  {StoriesList.slice(0, 3).map((item, index) => {
                    return (
                      <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                        <div className="blogs-grid" key={index}>
                          <a href={item.link} target="blank">
                            <img
                              src={item.img}
                              alt="logos"
                              className="img-fluid"
                            />
                            <div className="blog-grid-content">
                              <h3>{item.heading}</h3>
                              <p>{item.body}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              {StoriesList?.length > 3 && (
                <ContactUsButton
                  text="Explore more"
                  link="/Resources"
                  className="light mt-4"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessStoriesList;
