import React from 'react';
import { atomOneLight, CopyBlock, dracula } from 'react-code-blocks';
import he from 'he';

const CodeBlock = ({
  language = 'powershell',
  code = '',
  theme = atomOneLight,
  showLineNumbers = false,
  wrapLongLines = false,
  codeBlock = true,
}) => {
  return (
    <div style={{ fontFamily: 'monospace', borderRadius: '5px'}}>
      <CopyBlock
        text={he.decode(code)}
        language={language}
        theme={theme}
        wrapLongLines={wrapLongLines}
        showLineNumbers={showLineNumbers}
        codeBlock={codeBlock}
      />
    </div>
  );
};

export default CodeBlock;
