
import React from "react";
//
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from './ContactUsButton';
import HeroComponent from './HeroComponent';
import TechnologyAlliances from "./TechnologyAlliances";
import MarqueeComponent from "./MarqueeComponent";
import SuccessStoriesList from "./SuccessStoriesList";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.FinancialServices}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function FinancialServices() {
  // useEffect(() => {
  //     AOS.init(); // Initialize AOS
  // }, []);

  return (
    <div data-aos="fade-up" className="financial-service">
      <Helmet>
        <meta
          name="description"
          content="Bespoke Digital Solutions to augment your business"
        />
        <title>Financial Services | Engineersmind</title>
      </Helmet>

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.img}
        textContent={
          <h1>
            {textData.layer1.heading.text1}{" "}
            <span>
              {textData.layer1.heading.text2}
              <br /> {textData.layer1.heading.text3}
            </span>
            {textData.layer1.heading.text4}
            <br />
            {textData.layer1.heading.text5}
          </h1>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Industries/Financial-Services">Industries </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Financial Services
            </li>
          </ol>
        }
      />
      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h1>{textData.introduction.title}</h1>
                <div className="row introductionsection digitalPortals-left-img-row">
                  <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                    <img
                      className="AIChatbot-introduction-img"
                      src={textData.introduction.img}
                      alt="overview-img"
                    />
                  </div>
                  <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                    <div className="p-0">
                      <p text-20>{textData.introduction.description1}</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid m-0 atTheForefrontOfTechnology caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <section>
                <div className="row introductionsection digitalPortals-left-img-row">
                  <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                    <h1>{textData.atTheForefrontOfTechnology.title}</h1>
                    <div className="">
                      <p text-20>{textData.atTheForefrontOfTechnology.description1}</p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                    <img
                      className="AIChatbot-introduction-img"
                      src={textData.atTheForefrontOfTechnology.img}
                      alt="overview-img"
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid digitalPortals bg-color">
        <div className="container">
          <div className="row digitalPortals-right-img-row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <h1>
                <span>
                  {textData.portalsforClients.title}
                </span>
              </h1>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <div className="p-0 text-20">
                <p>
                  {textData.portalsforClients.description1}
                </p>
              </div>
            </div>
          </div>
          <div className="row digitalPortals-right-img-row">
            <div className="container list-container d-flex flex-column h-100">
              <div className="row">
                <div className="finportalsforClients col-lg-12 col-md-12 col-sm-12 col-xs-6 d-flex">
                  {textData.portalsforClients.points.map(
                    (point, index) => {
                      return (
                        <div className="list-item col-lg-3 col-md-6 col-sm-6 col-xs-6" key={index}><div className="finportalsforClientsList">
                          <strong dangerouslySetInnerHTML={{ __html: point.heading }} />
                          <span dangerouslySetInnerHTML={{ __html: point.details }} />
                        </div></div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container-fluid digitalPortals">
        <div className="container">
          <h1>
            <span>{textData.hedgeFundManagerPortal.title}</span>
          </h1>
          <div className="row digitalPortals-left-img-row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.hedgeFundManagerPortal.img}
                className="img-fluid digitalPortals-left-img"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <div className="p-0 text-20">
                <p>{textData.hedgeFundManagerPortal.description1}</p>
              </div>
              <div className="pt-2 text-20">
                <ul className="digitalPortals-list">
                  {textData.hedgeFundManagerPortal.points.map(
                    (point, index) => {
                      return (
                        <li className="digitalPortals-list-point" key={index}>
                          <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                          <span dangerouslySetInnerHTML={{ __html: point.details }} />
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="pt-2 text-20">
                <p>{textData.hedgeFundManagerPortal.description2}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid digitalPortals bg-color">
        <div className="container">
          <div className="row digitalPortals-right-img-row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <h1>
                <span>
                  {textData.privateEquityFunds.title}
                </span>
              </h1>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <div className="p-0 text-20">
                <p>
                  {textData.privateEquityFunds.description1}
                </p>
              </div>
            </div>
          </div>
          <div className="row digitalPortals-right-img-row">
            <div className="container list-container d-flex flex-column h-100">
              <div className="row">
                <div className="finportalsforClients col-lg-12 col-md-12 col-sm-12 col-xs-6 d-flex">
                  {textData.privateEquityFunds.points.map(
                    (point, index) => {
                      return (
                        <div className="list-item col-lg-4 col-md-6 col-sm-6 col-xs-6" key={index}><div className="finportalsforClientsList">
                          <strong dangerouslySetInnerHTML={{ __html: point.heading }} />
                          <span dangerouslySetInnerHTML={{ __html: point.details }} />
                        </div></div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid digitalPortals">
        <div className="container">
          <h1>
            <span>
              {textData.aIChatbotIntegration.title}
            </span>
          </h1>
          <div className="row digitalPortals-right-img-row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <div className="p-0 text-20">
                <p>{textData.aIChatbotIntegration.description1}</p>
              </div>
              <div className="pt-2 text-20">
                <ul className="digitalPortals-list">
                  {textData.aIChatbotIntegration.points.map(
                    (point, index) => {
                      return (
                        <li className="digitalPortals-list-point" key={index}>
                          <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                          <span dangerouslySetInnerHTML={{ __html: point.details }} />
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="pt-2 text-20">
                <p>{textData.aIChatbotIntegration.description2}</p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.aIChatbotIntegration.img}
                className="img-fluid digitalPortals-left-img"
                alt="Resilient_solutions"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid digitalPortals bg-color">
        <div className="container">
          <div className="row digitalPortals-right-img-row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <h1>
                <span>
                  {textData.comprehensiveTechnologyIntegrations.title}
                </span>
              </h1>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <div className="p-0 text-20">
                <p>
                  {textData.comprehensiveTechnologyIntegrations.description1}
                </p>
              </div>
            </div>
          </div>
          <div className="row digitalPortals-right-img-row">
            <div className="container list-container d-flex flex-column h-100">
              <div className="row">
                <div className="finportalsforClients col-lg-12 col-md-12 col-sm-12 col-xs-6 d-flex">
                  {textData.comprehensiveTechnologyIntegrations.points.map(
                    (point, index) => {
                      return (
                        <div className="list-item col-lg-4 col-md-6 col-sm-6 col-xs-6" key={index}><div className="finportalsforClientsList">
                          <strong dangerouslySetInnerHTML={{ __html: point.heading }} />
                          <span dangerouslySetInnerHTML={{ __html: point.details }} />
                        </div></div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid m-0 ourApproach caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h1>{textData.ourApproach.title}</h1>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.ourApproach.img}
                    alt="overview-img"
                  />
                </div>
                <div className="row introductionsection digitalPortals-left-img-row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                    <div className="p-0">
                      <p text-20>{textData.ourApproach.description1}</p>
                    </div>
                  </div>
                  <div className="pt-2 text-20">
                    <div className="digitalPortals-list">
                      {textData.ourApproach.points.map(
                        (point, index) => {
                          return (
                            <div className="digitalPortals-list-point" key={index}>
                              <strong dangerouslySetInnerHTML={{ __html: point.heading }} />{" - "}
                              <span dangerouslySetInnerHTML={{ __html: point.details }} />
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <SuccessStoriesList  StoriesList={textData.storiesList} />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h1>{textData.WhyChooseEngineersmind.title}</h1>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.WhyChooseEngineersmind.img}
                    alt="overview-img"
                  />
                </div>
                <div className="row introductionsection digitalPortals-left-img-row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
                    <div className="p-0">
                      <p text-20>{textData.WhyChooseEngineersmind.description1}</p>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div className="alliances d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-white text-center">
          {textData.layer8.heading.text1}
          <span>{textData.layer8.heading.text2}</span>
          {textData.layer8.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.layer8.button.text}
          link="#ContactForm"
          className="light"
        />
      </div>

      <TechnologyAlliances data={textData.alliacessection} />

      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinancialServices;
