
import React, { useEffect } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import config from '../config';

const textDataUrl = `${page_json.page_jsons.Leadership}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Leadership() {
    useEffect(() => {
        AOS.init(); // Initialize AOS
    }, []);

    return (
        <div data-aos="fade-up">
            <Helmet>
                <meta name="description" content="Engineersmind Leadership" />
                <title>Leadership | About | Engineersmind</title>
            </Helmet>
            <div className="container-fluid m-0 p-0 brd-crmb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home </a></li>
                                    <li className="breadcrumb-item"><a href="/About">About Us </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Leadership</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid leadership leader-inner">
                <div className="container">
                    <h1>{textData.layer1.heading}</h1>
                    <div className="row">
                            {
                                textData.editableList_leadership.map(function (item) {
                                    return (<div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <img src={item.img} className="img-fluid" alt="leader-img" />
                                        <p>{item.name}</p>
                                        <i>{item.post}</i>
                                    </div>)
                                })
                            }
                    </div>
                    <div className='mt-5'>
                        <a href={textData.layer5.button.url}>{textData.layer5.button.text} <i class="fa-solid fa-arrow-right"></i></a>
                    </div>
                </div>
            </div>
            <div className="container-fluid trust">
                <div className="container trusted-img">
                    <div className="row row-gap-3">
                        <h1>{textData.layer2.heading}</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={textData.layer2.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                            <div className="heading">
                                <h1><span>{textData.layer3.heading.text1}</span> {textData.layer3.heading.text2} <span>{textData.layer3.heading.text3}</span></h1>
                            </div>
                            <div className="more-2">
                                <a href={textData.layer3.button.url}>{textData.layer3.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                            <div className="gif">
                                <img src={textData.layer3.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h1>{textData.layer4.heading}</h1>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={textData.layer4.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Leadership;