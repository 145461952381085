
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json'
import ReCAPTCHA from 'react-google-recaptcha';
import useLocation from "../utils/useLocation";
import { Helmet } from 'react-helmet';
import config from '../config';

var textDataUrl = `${page_json.page_jsons.Countries}?v=${config.VERSION}`;
var textDataResponse = await fetch(textDataUrl);
const countryList = await textDataResponse.json();

textDataUrl = `${page_json.page_jsons.Contact}?v=${config.VERSION}`;
textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();


function Contact() {

	const [formData, setFormData] = useState({
		firstname: '',
		//lastname: '',
		email: '',
		phone: '',
		organisation: "",
		city: '',
		country: '',
		comment: '',
		check1: false,
		check2: false,
	  });
	
	  //const [isCaptchaValid, setIsCaptchaValid] = useState(false);
	  const [recaptchaToken, setRecaptchaToken] = useState(null);
	  const [selectedOption, setSelectedOption] = useState('');
	  const [message, setMessage] = useState('');
	  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  	  const { location, error, loading } = useLocation();

	useEffect(() => {    
		if(location && !loading && !error){
			setFormData({
				...formData,
				city: location.city || '',
				state: location.state || '',
				country: location.country || ''
			})
		}
	}, [location, error, loading, formData])

	useEffect(() => {
		AOS.init(); // Initialize AOS
	},[])
	
	useEffect(() => {
		// Enable/disable the button based on form validation
		//alert("firstname-"+formData.firstname+"---lastname - "+formData.lastname+"---email - "+formData.email+"---phone - "+formData.phone+"---city - "+formData.city+"---country - "+formData.country+"---selectedOption - "+selectedOption+"---check1 - "+formData.check1+"---isCaptchaValid - "+isCaptchaValid);
		const isValid = 
		  formData.firstname && formData.email &&
		  selectedOption && formData.comment && formData.check1 && recaptchaToken;
		
		setIsButtonDisabled(!isValid);
	  }, [formData, selectedOption, recaptchaToken]);
	
	const handleRecaptchaChange = (token) => {
        setRecaptchaToken(token);  // Store the reCAPTCHA token
    };
	  const handleChange = (event) => {
		const { name, value, type, checked } = event.target;
	
		// Phone validation
		if (name === 'phone') {
		  const regex = /^[0-9()+-,]*$/;
		  if (value.length === 0 || regex.test(value)) {
			setMessage('');
		  } else {
			setMessage('Please enter a valid phone number.');
		  }
		}
		if (name === 'Information') {
			setSelectedOption(value);
		}	
		// Update form data dynamically
		setFormData((prevData) => ({
		  ...prevData,
		  [name]: type === 'checkbox' ? checked : value,
		}));
	
		
	  };

	const btnclick = async (e) => {
		e.preventDefault();
		
		// Prepare the data to be sent in the API request
		const requestData = {
		  name: `${formData.firstname}`,
		  department: selectedOption,
		  email: formData.email,
		  phone: formData.phone,
		  comment: formData.comment,
		  recaptchaToken: recaptchaToken,
		  city: formData.city,
		  state: formData.state,
		  country: formData.country
		};
		let bodytext = `<div className="application-container"><h1></h1><div className="applicant-info"><p><strong>Name:</strong> ${requestData.name} </p><p><strong>Email:</strong> ${requestData.email} </p><p><strong>Phone:</strong> ${requestData.phone} </p><p><strong>Organization:</strong> ${requestData.organisation} </p><p><strong>City:</strong> ${requestData.city} </p><p><strong>State:</strong> ${requestData.state} </p><p><strong>Country:</strong> ${requestData.country} </p></div><div className="comment"><h1>Comments</h1><p>${requestData.comment}</p></div></div>`;
	  let emailData={
		//sender: 'no-reply@em.engineersmind.com',
		sender: page_json.contact_api.fromEmail,
		recipient: page_json.contact_api.toEmail,
		subject: `Enquiry for ${selectedOption}`,
		body_text: bodytext,
		body_html: `<html><body>${bodytext}</body></html>`,
		//attachments: [{filename: 'example.txt', content: btoa('This is the content of the test file.')} ], // Array of objects with { filename: 'example.txt', content: 'base64string' }
	  };
		// Define the API endpoint
		const emailAPI = page_json.backend_apis.email_api;
		//const apiUrl = emailAPI;
	  console.log(emailData);
		try {
		  // Make the API call using fetch
		  const response = await fetch(emailAPI, {
			method: 'POST', // Use the appropriate HTTP method
			headers: {
			  'Content-Type': 'application/json', // Set the content type to JSON
			},
			body: JSON.stringify(emailData), // Convert the data to JSON
		  });
	  
		  // Check if the response is successful
		  if (response.ok) {
			// Optionally, parse the JSON response
			const responseData = await response.json();
			console.log('Response:', responseData);
			
			alert('Query sent successfully');
			window.location.reload(); // Reload the page on success
		  } else {
			// Handle errors
			console.error('Error:', response.statusText);
			alert('Query failed, try again!');
		  }
		} catch (error) {
		  // Handle exceptions
		  console.error('Exception:', error);
		  alert('An error occurred, please try again!');
		}
	  };
	
	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Engineersmind - Contact Us" />
                <title>Contact Us | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb-contact brd-crmb" id="crumb-2">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item active" aria-current="page">Contact</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
			<div className="container-fluid contactouter">
				<div className="container">
					<div className="row">
						<div className="col-lg-6 col-md-6 col-sm-8 col-xs-8">
						<div className="container-fluid contact">
				<div className="container">
					{/*<h1>{textData.layer1.heading}</h1>
					<p><span>{textData.layer1.body.text1}</span></p>
					<p>{textData.layer1.body.text2}</p>
					<p>{textData.layer1.body.text3}</p>
					<p>{textData.layer1.body.text4}</p>&nbsp;
					<p><a href={`mailto:${textData.layer1.body.text5}`}>{textData.layer1.body.text5}</a></p>
					<p><a href={`tel:${textData.layer1.body.text6.replace(/[\s-]/g, '')}`}>{textData.layer1.body.text6}</a></p>&nbsp;
					&nbsp;*/}
					{/* <form id="myForm" action=" " method="post"> */}
					<form onSubmit={btnclick}> 
						<h1>{textData.layer2.heading}</h1>
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-2">
								<div className="success1">
									<select id="Information" name="Information" value={selectedOption} className="form-control" required onChange={handleChange}>
										<option value="" disabled>{textData.layer2.body.text1}</option>
										<option value="Sales">Sales</option>
										<option value="Consulting">Consulting</option>
										<option value="HR">HR</option>
									</select>
									{/*<label htmlFor="Information" className="form-label">{textData.layer2.body.text1}</label> */}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-2">
								<div className="success1">
									<input type="text" value={formData.firstname} onChange={handleChange} className="form-control" placeholder={textData.layer2.body.text2} name="firstname" id="firstName" required />
									{/*<label htmlFor="firstname" className="form-label">{textData.layer2.body.text2}</label>*/}
								</div>
							</div>
							{/*<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								<div className="success1">
									<input type="text" value={formData.lastname} onChange={handleChange} className="form-control" name="lastname" id="lastname" required />
									<label htmlFor="lastname" className="form-label">{textData.layer2.body.text3}</label>
								</div>

							</div> */}
							<div className="l-lg-6 col-md-6 col-sm-6 col-xs-6 p-2">
								<div className="success1">
								<input
									type="text"
									value={formData.organisation}
									onChange={handleChange}
									className="form-control"
									name="organisation"
									id="Organisation"
									placeholder={textData.layer2.body.text3}
								/>
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-2">
								<div className="success1">
									<input type="number" maxLength="10" className="form-control" value={formData.phone} onChange={handleChange}  placeholder={textData.layer2.body.text5} name="phone" id="phone" title="Only numbers" pattern="[0-9]{10}" required />
									{/*<label htmlFor="phone" className="form-label">{textData.layer2.body.text5}</label>*/}
									{message && (
										<p style={{ color: message === 'Mobile number is valid.' ? 'green' : 'red' }}>
										{message}
										</p>
									)}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-2">
								<div className="success1">
									<input type="text" value={formData.city} onChange={handleChange}  placeholder={textData.layer2.body.text6} className="form-control" name="city" id="city" />
									{/*<label htmlFor="city" className="form-label">{textData.layer2.body.text6}</label> */}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-2">
								<div className="success1">
									<input type="text" value={formData.state} onChange={handleChange}  placeholder={textData.layer2.body.text13} className="form-control" name="state" id="state" />
									{/*<label htmlFor="city" className="form-label">{textData.layer2.body.text6}</label> */}
								</div>
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-2">
								<div className="success1">
									<select id="country" name="country" className="form-control" onChange={handleChange} required>
										<option value="">{textData.layer2.body.text7}</option>
										{
											countryList.data.map(function(item){
												return(<option value={item.name}>{item.name}</option>)
											  })
										}
									</select>
									{/*<label htmlFor="country" className="form-label">{textData.layer2.body.text8}</label> */}
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-2">
								<div className="success1">
									<input type="email" value={formData.email} onChange={handleChange} className="form-control" placeholder={textData.layer2.body.text4} name="email" id="email" required />
									{/*<label htmlFor="email" className="form-label">{textData.layer2.body.text4}</label>*/}
								</div>
							</div>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-2">
								<div className="success1">
									<textarea className="form-control" value={formData.comment} onChange={handleChange} placeholder={textData.layer2.body.text9} name="comment" id="comment" rows="3"></textarea>
									{/*<label htmlFor="comment" className="form-label">{textData.layer2.body.text9} </label> */}
								</div>
							</div>&nbsp;
							<div className="mb-12 form-check">
								<input type="checkbox"  value={formData.agree1} onClick={handleChange} name="check1" className="form-check-input" id="agree1" required />
								<label className="form-check-label" htmlFor="agree1">{textData.layer2.body.text10}</label>
							</div>
							<div className="mb-12 form-check">
								<input type="checkbox"  value={formData.agree2} onClick={handleChange} name="check2" className="form-check-input" id="agree2"/>
								<label className="form-check-label" htmlFor="agree2">{textData.layer2.body.text11}</label>
							</div>
							<div className="mb-12 recaptchadiv">
								<ReCAPTCHA
                                    sitekey={page_json.contact_api.recaptcha_site_key}  // Replace with your actual site key from Google reCAPTCHA
                                    onChange={handleRecaptchaChange}
                                />
							</div>
							<button type="submit" id="clickButton" className="contact-submit-button mt-5 light" disabled={isButtonDisabled} >{textData.layer2.body.text12}</button>
						</div>
					</form>

				</div>
			</div>
						</div>
						<div className="col-lg-6 col-md-6 col-sm-4 col-xs-4">
							<div className="container-fluid contact loc-img">
								<div className="container">
									<h1>{textData.layer3.heading}</h1>
									<div className="row locationimages">
										{
											textData.layer3.editableList_locations.map(function(item){
												return(
													<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
														<div className="location">
															<img src={item.img} alt="gif" className="img-fluid" />
															<div class="hover-text">
																<div class="loc_cityname">{item.name}</div>
																<p><span>{item.address_heading}</span></p>
																<p>{item.address1}</p>
																<p>{item.address2}</p>
																<p>{item.address3}</p>
																<p>{item.address4}</p>
															</div>
														</div>
													</div>
												)
											})
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)


}

export default Contact;



