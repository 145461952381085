
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import page_json from './config/config.json';
import ContactForm from './ContactForm';
import { Helmet } from 'react-helmet';
import ContactUsButton from './ContactUsButton';
import config from '../config';

const textDataUrl = `${page_json.page_jsons.ManufacturingEC}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function ManufacturingEC() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);
	const [activePanel, setActivePanel] = useState(null);
	const togglePanel = (panelId) => {
		setActivePanel(activePanel === panelId ? null : panelId);
	};
	var left_index = 0;
	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Solving challenges of E-commerce customer portals for a global manufacturer" />
                <title>Manufacturing Ecommerce Portal | Manufacturing | Engineersmind</title>
            </Helmet>
			<div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home</a></li>
									<li className="breadcrumb-item"><a href="/Industries/Manufacturing">Manufacturing</a></li>
									<li className="breadcrumb-item active" aria-current="page">E-Commerce Customer Portals</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>

			<div className="container-fluid casestudy">
				<div className="container">
					<h1><span>{textData.layer1.heading1.text1}</span>{textData.layer1.heading1.text2}<span>{textData.layer1.heading1.text3}</span></h1>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<p><b>{textData.layer1.body1.text1}</b></p>
								<p>{textData.layer1.body1.text2}</p>&nbsp;
								<p>{textData.layer1.body1.text3}</p>&nbsp;
								<p>{textData.layer1.body1.text4}</p>
							</div>
						</div>
						<br />
						<h5><span>{textData.layer1.heading2}</span></h5>
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<p>{textData.layer1.body2}</p>
							</div>
						</div>
						<ContactUsButton className="light" />
					</div>
				</div>
			</div>

			<div className="container-fluid e-commerce">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer2.img} alt="gif" className="img-fluid" />
							{/* <img src="content/public/images/E-commerce.webp" alt="E-commerce" className="img-fluid" /> */}
							<p>{textData.layer2.body1} </p>
						</div>
					</div>
					<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
						<div className="row">
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								{
                                    textData.layer2.collapsibles_left.map((item, index) => {
                                        left_index = index
                                        return(
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id={ "heading" + index }>
                                                    <h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
                                                        aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
                                                </div>
                                                <div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
                                                    data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
                                                    <div className="panel-body">
                                                        <p>{item.body}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
								{/* <div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingOne">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseOne' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseOne')}
											aria-expanded={activePanel === 'collapseOne' ? 'true' : 'false'} aria-controls="collapseOne"> {textData.layer2.collapsibles.collapsible1.heading}</a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseOne' ? 'show' : ''}`} id="collapseOne" aria-labelledby="headingOne"
										data-parent="#accordion"> <div id="collapseOne" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible1.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingTwo">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseTwo' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseTwo')}
											aria-expanded={activePanel === 'collapseTwo' ? 'true' : 'false'} aria-controls="collapseTwo"> {textData.layer2.collapsibles.collapsible2.heading} </a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseTwo' ? 'show' : ''}`} id="collapseTwo" aria-labelledby="headingTwo"
										data-parent="#accordion"> <div id="collapseTwo" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible2.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingThree">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseThree' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseThree')}
											aria-expanded={activePanel === 'collapseThree' ? 'true' : 'false'} aria-controls="collapseThree"> {textData.layer2.collapsibles.collapsible3.heading} </a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseThree' ? 'show' : ''}`} id="collapseThree" aria-labelledby="headingThree"
										data-parent="#accordion"> <div id="collapseThree" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible3.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingFour">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseFour' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseFour')}
											aria-expanded={activePanel === 'collapseFour' ? 'true' : 'false'} aria-controls="collapseFour"> {textData.layer2.collapsibles.collapsible4.heading}</a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseFour' ? 'show' : ''}`} id="collapseFour" aria-labelledby="headingFour"
										data-parent="#accordion"> <div id="collapseFour" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible4.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingFive">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseFive' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseFive')}
											aria-expanded={activePanel === 'collapseFive' ? 'true' : 'false'} aria-controls="collapseFive"> {textData.layer2.collapsibles.collapsible5.heading}</a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseFive' ? 'show' : ''}`} id="collapseFive" aria-labelledby="headingFive"
										data-parent="#accordion"> <div id="collapseFive" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible5.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingSix">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseSix' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseSix')}
											aria-expanded={activePanel === 'collapseSix' ? 'true' : 'false'} aria-controls="collapseSix"> {textData.layer2.collapsibles.collapsible6.heading} </a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseSix' ? 'show' : ''}`} id="collapseSix" aria-labelledby="headingSix"
										data-parent="#accordion"> <div id="collapseSix" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible6.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingSeven">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseSeven' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseSeven')}
											aria-expanded={activePanel === 'collapseSeven' ? 'true' : 'false'} aria-controls="collapseSeven"> {textData.layer2.collapsibles.collapsible7.heading}</a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseSeven' ? 'show' : ''}`} id="collapseSeven" aria-labelledby="headingSeven"
										data-parent="#accordion"> <div id="collapseSeven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible7.body}</p>
										</div>
									</div>
								</div> */}
							</div>
							<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
								{
                                    textData.layer2.collapsibles_right.map((item, index) => {
                                        index = index + left_index + 1
                                        return(
                                            <div className="panel panel-default">
                                                <div className="panel-heading" role="tab" id={ "heading" + index }>
                                                    <h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
                                                        aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
                                                </div>
                                                <div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
                                                    data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
                                                    <div className="panel-body">
                                                        <p>{item.body}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

								{/* <div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingEight">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseEight' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseEight')}
											aria-expanded={activePanel === 'collapseEight' ? 'true' : 'false'} aria-controls="collapseEight"> {textData.layer2.collapsibles.collapsible8.heading} </a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseEight' ? 'show' : ''}`} id="collapseEight" aria-labelledby="headingEight"
										data-parent="#accordion"> <div id="collapseEight" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible8.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingNine">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseNine' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseNine')}
											aria-expanded={activePanel === 'collapseNine' ? 'true' : 'false'} aria-controls="collapseNine"> {textData.layer2.collapsibles.collapsible9.heading} </a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseNine' ? 'show' : ''}`} id="collapseNine" aria-labelledby="headingNine"
										data-parent="#accordion"> <div id="collapseNine" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible9.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingTen">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseTen' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseTen')}
											aria-expanded={activePanel === 'collapseTen' ? 'true' : 'false'} aria-controls="collapseTen"> {textData.layer2.collapsibles.collapsible10.heading}</a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseTen' ? 'show' : ''}`} id="collapseTen" aria-labelledby="headingTen"
										data-parent="#accordion"> <div id="collapseTen" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTen"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible10.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingEleven">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseEleven' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseEleven')}
											aria-expanded={activePanel === 'collapseEleven' ? 'true' : 'false'} aria-controls="collapseEleven">{textData.layer2.collapsibles.collapsible11.heading} </a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseEleven' ? 'show' : ''}`} id="collapseEleven" aria-labelledby="headingEleven"
										data-parent="#accordion"> <div id="collapseEleven" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEleven"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible11.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingTwelve">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseTwelve' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseTwelve')}
											aria-expanded={activePanel === 'collapseTwelve' ? 'true' : 'false'} aria-controls="collapseTwelve">{textData.layer2.collapsibles.collapsible12.heading} </a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseTwelve' ? 'show' : ''}`} id="collapseTwelve" aria-labelledby="headingTwelve"
										data-parent="#accordion"> <div id="collapseTwelve" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwelve"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible12.body}</p>
										</div>
									</div>
								</div>
								<div className="panel panel-default">
									<div className="panel-heading" role="tab" id="headingThirteen">
										<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapseThirteen' ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapseThirteen')}
											aria-expanded={activePanel === 'collapseThirteen' ? 'true' : 'false'} aria-controls="collapseThirteen">{textData.layer2.collapsibles.collapsible13.heading} </a></h4>
									</div>
									<div className={`collapse ${activePanel === 'collapseThirteen' ? 'show' : ''}`} id="collapseThirteen" aria-labelledby="headingThirteen"
										data-parent="#accordion"> <div id="collapseThirteen" className="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirteen"></div>
										<div className="panel-body">
											<p>{textData.layer2.collapsibles.collapsible13.body}</p>
										</div>
									</div>
								</div> */}
							</div>
						</div>&nbsp;
						<p>{textData.layer2.body2}</p>
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
							<div className="heading">
								<h1><span>{textData.layer3.heading.text1}</span>{textData.layer3.heading.text2}<span>{textData.layer3.heading.text3}</span></h1>
							</div>
							<div className="more-2">
								<a href="#ContactForm">{textData.layer3.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
							<div className="gif">
								<img src={textData.layer3.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className="container-fluid ContactForm" id='ContactForm'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h1>{textData.layer4.heading}</h1>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<img src={textData.layer4.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ManufacturingEC;

