import React, { useEffect, useState } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import config from "../config";
import HeroComponent from "./HeroComponent";
import TechnologyAlliances from "./TechnologyAlliances";
import MarqueeComponent from "./MarqueeComponent";

const textDataUrl = `${page_json.page_jsons.CharitableOrganization}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function CharitableOrganization() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const [activePanel, setActivePanel] = useState(null);
  const togglePanel = (panelId) => {
    setActivePanel(activePanel === panelId ? null : panelId);
  };
  var left_index = 0;

  return (
    <div data-aos="fade-up" className="charitable-organisation">
      <Helmet>
        <meta
          name="description"
          content="Enhancing engagement, communication, and scheduling for a large charitable organization through a user-friendly and scalable volunteer management platform."
        />
        <title>
          Digital Platform for Charitable Organisation | Healthcare |
          Engineersmind
        </title>
      </Helmet>
      {/* <div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home</a></li>
									<li className="breadcrumb-item"><a href="/Industries/Healthcare">Healthcare</a></li>
									<li className="breadcrumb-item active" aria-current="page">Chartiable Organization</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div> */}

      <HeroComponent
        bgImageMobile={textData.heroSection.mobilebannerimg}
        bgImage={textData.heroSection.banner}
        textContent={
          <>
            <p>Case Study</p>
            <h1>
              <span>{textData.heroSection.title}</span>
            </h1>
          </>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Industries/Healthcare">Healthcare</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Chartiable Organization
            </li>
          </ol>
        }
      />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: textData.leftSectionContent.text2,
                    }}
                  />
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h3>{textData.introduction.title}</h3>
                <div className="row introduction-section">
                  <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                    <div className="d-flex justify-content-center">
                      <img
                        className="w-100 mt-3"
                        src={textData.introduction.img}
                        alt="overview-img"
                      />
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center justify-content-center">
                    <p className="">{textData.introduction.description}</p>
                  </div>
                </div>
              </section>

              <div className="alliances d-flex flex-column justify-content-center align-items-center rounded mt-5 mb-5 pl-2 pr-2 bg-white">
                <h1 className="text-black text-center">
                  {textData.layer3.heading.text1}
                  <span>{textData.layer3.heading.text2}</span>
                  {textData.layer3.heading.text3}
                </h1>
                <ContactUsButton
                  text={textData.layer3.button.text}
                  link="#ContactForm"
                  className="light"
                  isLightText={false}
                />
              </div>

              <section>
                <h3>{textData.challenge.title}</h3>
                <p className="mb-3">{textData.challenge.description}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="w-100 mt-3 mb-3"
                    src={textData.challenge.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mb-3 overallstructure">
                  {textData.challenge.keyFeatures.map((feature, index) => {
                    return (
                      <div className="d-flex w-100 list-item" key={index}>
                        <img
                          src={textData.bulletPoints.img}
                          alt="bullet-point"
                        />
                        <div>
                          <p>
                            <strong>{feature.title}</strong>
                          </p>
                          <p className="mb-3">{feature.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.solution.title}</h3>
                <p className="mb-3">{textData.solution.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="w-100 mt-3"
                    src={textData.solution.img1}
                    alt="overview-img"
                  />
                </div>
                <p className="mb-3">{textData.solution.description2}</p>
                <p className="mb-3">{textData.solution.keyFeaturesTitle}</p>
                <div className="mb-2 overallstructure">
                  {textData.solution.keyFeatures.map((feature, index) => {
                    return (
                      <div className="d-flex w-100 list-item" key={index}>
                        <img
                          src={textData.bulletPoints.img}
                          alt="bullet-point"
                        />
                        <div>
                          <p>
                            <strong>{feature.title}</strong>
                          </p>
                          <p className="mb-3">{feature.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <p className="mb-3">{textData.solution.description3}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="w-100 mt-3"
                    src={textData.solution.img2}
                    alt="overview-img"
                  />
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.engineersmindsCapabilities.title}</h3>
                <p className="mb-3">
                  {textData.engineersmindsCapabilities.description}
                </p>
                <div className="helthcare-steps-container">
                  {textData.engineersmindsCapabilities.keyFeatures.map(
                    (step, index) => {
                      return (
                        <div
                          className={`d-flex ${
                            index % 2 === 0 ? "flex-row" : "flex-row-reverse"
                          } step`}
                          key={index}
                        >
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div className="image-wrapper">
                              <img src={step.img} alt="step-img" />
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 text-content">
                            <p className="title">{step.title}</p>
                            <ul className="pl-1">
                              {step.points.map((point, index) => {
                                return (
                                  <div
                                    className="d-flex w-100 list-item"
                                    key={index}
                                  >
                                    <img
                                      src={textData.bulletPoints.img}
                                      alt="bullet-point"
                                    />
                                    <div>
                                      <p className="point">{point}</p>
                                    </div>
                                  </div>
                                );
                              })}
                            </ul>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.results.title}</h3>
                <p className="mb-3">{textData.results.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img mt-3"
                    src={textData.results.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mb-2 overallstructure">
                  {textData.results.keyFeatures.map((feature, index) => {
                    return (
                      <div className="d-flex w-100 list-item" key={index}>
                        <img
                          src={textData.bulletPoints.img}
                          alt="bullet-point"
                        />
                        <div>
                          <p>
                            <strong>{feature.title}</strong>
                          </p>
                          <ul>
                            {feature.points.map((point, index) => {
                              return <li key={index}>{point}</li>;
                            })}
                          </ul>
                          <p className="mb-3">{feature.description}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <p className="mb-3">{textData.results.description2}</p>
              </section>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="container-fluid casestudy">
				<div className="container">
					<h1>{textData.layer1.heading1.text1} <span>{textData.layer1.heading1.text2} </span></h1><br />
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<div className="casestudy1">
								<p><b>{textData.layer1.body1.text1} </b></p>
								<p>{textData.layer1.body1.text2} </p>
							</div>
							<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
									{
										textData.layer1.collapsibles_left.map((item, index) => {
											left_index = index
											return(
												<div className="panel panel-default">
													<div className="panel-heading" role="tab" id={ "heading" + index }>
														<h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
															aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
													</div>
													<div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
														data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
														<div className="panel-body">
															<p>{item.body}</p>
														</div>
													</div>
												</div>
											)
										})
									}
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
									{
										textData.layer1.collapsibles_right.map((item, index) => {
											index = index + left_index + 1
											return(
												<div className="panel panel-default">
													<div className="panel-heading" role="tab" id={ "heading" + index }>
														<h4 className="panel-title"><a className={`collapsed ${activePanel === "collapse" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapse" + index)}
															aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={ "collapse" + index }> {item.heading} </a></h4>
													</div>
													<div className={`collapse ${activePanel === "collapse" + index ? 'show' : ''}`} id={"collapse" + index} aria-labelledby={"heading" + index}
														data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
														<div className="panel-body">
															<p>{item.body}</p>
														</div>
													</div>
												</div>
											)
										})
									}
									</div>
								</div>&nbsp;
								<p>{textData.layer1.body2}</p>
							</div>&nbsp;
							<h5><span>{textData.layer1.heading2}</span></h5>
							<p>{textData.layer1.body3.text1}</p> &nbsp;
							<p>{textData.layer1.body3.text2}</p>
							<ContactUsButton className="light mt-4" />
						</div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid chartiable">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<img src={textData.layer2.data.detail1.img} alt="gif" className="img-fluid" />
							<p>{textData.layer2.data.detail1.body}</p>
							<img src={textData.layer2.data.detail2.img} alt="gif" className="img-fluid" />
							<p>{textData.layer2.data.detail2.body}</p> &nbsp;
							<p>{textData.layer2.body1}</p> &nbsp;
							<div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
								<div className="row">
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
									{
										textData.layer2.collapsibles_left.map((item, index) => {
											left_index = index
											return(
												<div className="panel panel-default">
													<div className="panel-heading" role="tab" id={ "heading" + index }>
														<h4 className="panel-title"><a className={`collapsed ${activePanel === "collapseunit" + index ? 'active' : ''}`} role="button" onClick={() => togglePanel("collapseunit" + index)}
															aria-expanded={activePanel === "collapseunit" + index ? 'true' : 'false'} aria-controls={ "collapseunit" + index }> {item.heading} </a></h4>
													</div>
													<div className={`collapse ${activePanel === "collapseunit" + index ? 'show' : ''}`} id={"collapseunit" + index} aria-labelledby={"heading" + index}
														data-parent="#accordion"> <div id={"collapseunit" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
														<div className="panel-body">
															<p>{item.body}</p>
														</div>
													</div>
												</div>
											)
										})
									}
									</div>
								</div>&nbsp;
								<p>{textData.layer2.body2}</p>
							</div>
						</div>
					</div>
				</div>
			</div> */}

      {/* <div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
							<div className="heading">
								<h1><span>{textData.layer3.heading.text1}</span>{textData.layer3.heading.text2}<span>{textData.layer3.heading.text3}</span></h1>
							</div>
							<div className="more-2">
								<a href="#ContactForm">{textData.layer3.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
							<div className="gif">
								<img src={textData.layer3.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div> */}

      <TechnologyAlliances data={textData.alliacessection} />
      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h1>{textData.layer4.heading}</h1>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<img src={textData.layer4.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div> */}
    </div>
  );
}

export default CharitableOrganization;
