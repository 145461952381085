import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import HeroComponent from "./HeroComponent";
import MarqueeComponent from "./MarqueeComponent";
import CodeBlock from "./CodeBlock";
import TechnologyAlliances from "./TechnologyAlliances";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.MSAL}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function MASL() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" className="caseStudyPage MSAL">
      <Helmet>
        <meta name="description" content="Streamlining user authentication by leveraging MSAL’s silent token acquisition, ensuring seamless access while maintaining enterprise security." />
        <title>MSAL | Engineersmind</title>
      </Helmet>

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.banner}
        textContent={
          <>
            <p>Case Study</p>
            <h1>
              <span>{textData.layer1.title}</span>
            </h1>
          </>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Resources">Resources </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Resources/Blogs">Blogs </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              MSAL
            </li>
          </ol>
        }
      />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: textData.leftSectionContent.text2,
                    }}
                  />
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h3>{textData.introduction.title}</h3>
                <p className="mb-3">{textData.introduction.description}</p>
                {/* <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.introduction.img}
                    alt="overview-img"
                  />
                </div> */}
              </section>


              <div className="alliances d-flex flex-column justify-content-center align-items-center rounded mt-5 mb-5 pl-2 pr-2 bg-white">
                <h1 className="text-black text-center">
                  {textData.layer7.heading.text1}
                  <span>{textData.layer7.heading.text2}</span>
                  {textData.layer7.heading.text3}
                </h1>
                <ContactUsButton
                  text={textData.layer7.button.text}
                  link="#ContactForm"
                  className="light"
                  isLightText = {false}
                />
              </div>


              <section>
                <h3>{textData.backgroundOIDCAndLiferay.title}</h3>
                <p className="mb-3">
                  {textData.backgroundOIDCAndLiferay.description}
                </p>
                {/* <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.backgroundOIDCAndLiferay.img}
                    alt="overview-img"
                  />
                </div> */}
              </section>

              <hr />

              <section>
                <h3>{textData.leveragingMSALForSilentAuthentication.title}</h3>
                <p className="mb-3">
                  {textData.leveragingMSALForSilentAuthentication.description}
                </p>
              </section>

              <section>
                <h3 className="mt-5">{textData.implementationSteps.title}</h3>
                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {textData.implementationSteps.configuringMSAL.title}
                    </strong>
                  </p>
                  <p className="mb-3">
                    {textData.implementationSteps.configuringMSAL.description1}
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={textData.implementationSteps.configuringMSAL.code}
                  />
                  <p className="mb-3 mt-2">
                    {textData.implementationSteps.configuringMSAL.description2}
                  </p>
                </div>

                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.implementationSteps.silentTokenAcquisition
                          .title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.implementationSteps.silentTokenAcquisition
                        .description1
                    }
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={
                      textData.implementationSteps.silentTokenAcquisition.code
                    }
                  />
                  <p className="mb-3 mt-2">
                    {
                      textData.implementationSteps.silentTokenAcquisition
                        .description2
                    }
                  </p>
                </div>

                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.implementationSteps.callingMicrosoftGraphAPI
                          .title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.implementationSteps.callingMicrosoftGraphAPI
                        .description1
                    }
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={
                      textData.implementationSteps.callingMicrosoftGraphAPI.code
                    }
                  />
                  <p className="mb-3 mt-2">
                    {
                      textData.implementationSteps.callingMicrosoftGraphAPI
                        .description2
                    }
                  </p>
                </div>

                <div className="mt-5">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.implementationSteps.mappingUserGroupsSection
                          .title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.implementationSteps.mappingUserGroupsSection
                        .description
                    }
                  </p>
                  <p>
                    {
                      textData.implementationSteps.mappingUserGroupsSection
                        .stepsHeading
                    }
                  </p>
                  <p>
                    {
                      textData.implementationSteps.mappingUserGroupsSection
                        .step1
                    }
                  </p>
                  <p>
                    {
                      textData.implementationSteps.mappingUserGroupsSection
                        .step2
                    }
                  </p>
                  <p className="mb-3">
                    {
                      textData.implementationSteps.mappingUserGroupsSection
                        .step3
                    }
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={
                      textData.implementationSteps.mappingUserGroupsSection.code
                    }
                  />
                  <p className="mb-3 mt-2">
                    {
                      textData.implementationSteps.mappingUserGroupsSection
                        .description2
                    }
                  </p>
                </div>
              </section>

              <hr />

              <section className="diagram-section">
                <h3>{textData.diagramSilentAuthenticationFlow.title}</h3>
                <p className="mb-3">
                  {textData.diagramSilentAuthenticationFlow.description}
                </p>
                <p className="mb-3">
                  <strong>
                    {textData.diagramSilentAuthenticationFlow.diagramTitle}
                  </strong>
                </p>
                <div className="d-flex justify-content-center">
                  <img
                    className="w-100 desktop-img"
                    src={textData.diagramSilentAuthenticationFlow.img}
                    alt="overview-img"
                  />
                  <img
                    className="AIChatbot-introduction-img mobile-img"
                    src={textData.diagramSilentAuthenticationFlow.mobileimg}
                    alt="overview-img"
                  />
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.enhancingAuth0JWTEnrichmentInLiferay.title}</h3>
                <div className="mt-4">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.enhancingAuth0JWTEnrichmentInLiferay.objective
                          .title
                      }
                    </strong>
                  </p>
                  <p className="mb-3">
                    {
                      textData.enhancingAuth0JWTEnrichmentInLiferay.objective
                        .description
                    }
                  </p>
                  <ol>
                    {textData.enhancingAuth0JWTEnrichmentInLiferay.objective.points.map(
                      (point, index) => {
                        return (
                          <li
                            key={index}
                            dangerouslySetInnerHTML={{ __html: point }}
                          />
                        );
                      }
                    )}
                  </ol>
                </div>
              </section>

              <hr />

              <section>
                <h3 className="mt-5">
                  {textData.currentTokenStructureInLiferay.title}
                </h3>
                <p className="mb-3">
                  {textData.currentTokenStructureInLiferay.description1}
                </p>
                <CodeBlock
                  language="javascript"
                  code={textData.currentTokenStructureInLiferay.code}
                />
                <p
                  className="mb-3 mt-3"
                  dangerouslySetInnerHTML={{
                    __html:
                      textData.currentTokenStructureInLiferay.description2,
                  }}
                />
                <p
                  className="mb-3 mt-3"
                  dangerouslySetInnerHTML={{
                    __html:
                      textData.currentTokenStructureInLiferay.description3,
                  }}
                />
                <p
                  className="mb-3 mt-3"
                  dangerouslySetInnerHTML={{
                    __html:
                      textData.currentTokenStructureInLiferay.description4,
                  }}
                />
              </section>

              <hr />

              <section>
                <h3>{textData.conclusion.title}</h3>
                <p className="mb-3">{textData.conclusion.description}</p>
                {/* <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.diagramSilentAuthenticationFlow.img}
                    alt="overview-img"
                  />
                </div> */}
              </section>
            </div>
          </div>
        </div>
      </div>

      
      <TechnologyAlliances data={textData.alliacessection} />

      <MarqueeComponent assets={textData.clients} />


      {/* <div className="alliances d-flex flex-column justify-content-center align-items-center">
        <h1 className="text-white text-center">
          {textData.layer7.heading.text1}
          <span>{textData.layer7.heading.text2}</span>
          {textData.layer7.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.layer7.button.text}
          link="#ContactForm"
          className="light"
        />
      </div> */}
      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer8.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer8.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default MASL;
