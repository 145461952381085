import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import HeroComponent from "./HeroComponent";
import MarqueeComponent from "./MarqueeComponent";
import CodeBlock from "./CodeBlock";
import TechnologyAlliances from "./TechnologyAlliances";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.JWKS}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function JWKS() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" className="caseStudyPage MSAL">
      <Helmet>
        <meta name="description" content="Securing Token Validation in JavaScript: Exploring introspection and JWKS-based approaches to efficiently validate tokens, enhance security, and optimize authentication workflows." />
        <title>JWKS | Engineersmind</title>
      </Helmet>

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.banner}
        textContent={
          <>
            <p>Case Study</p>
            <h1>
              <span>{textData.layer1.title}</span>
            </h1>
          </>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Resources">Resources </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Resources/Blogs">Blogs </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              JWKS
            </li>
          </ol>
        }
      />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: textData.leftSectionContent.text2,
                    }}
                  />
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section>
                {/* <h3>{textData.introduction.title}</h3> */}
                <p className="mb-3" dangerouslySetInnerHTML={{ __html: textData.introduction.description }} />
                {/* <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.introduction.img}
                    alt="overview-img"
                  />
                </div> */}
              </section>


              <div className="alliances d-flex flex-column justify-content-center align-items-center rounded mt-5 mb-5 pl-2 pr-2 bg-white">
                <h1 className="text-black text-center">
                  {textData.layer7.heading.text1}
                  <span>{textData.layer7.heading.text2}</span>
                  {textData.layer7.heading.text3}
                </h1>
                <ContactUsButton
                  text={textData.layer7.button.text}
                  link="#ContactForm"
                  className="light"
                  isLightText = {false}
                />
              </div>


              <section>
                <h3>{textData.whatAreTokens.title}</h3>
                <p className="mb-3">{textData.whatAreTokens.description}</p>
                {/* <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.whatAreTokens.img}
                    alt="overview-img"
                  />
                </div> */}
                <div className="mt-3">
                  <p>
                    <strong>{textData.whatAreTokens.codeOverview.title}</strong>
                  </p>
                  <p className="mb-3">
                    {textData.whatAreTokens.codeOverview.description}
                  </p>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.introspectionBasedTokenValidation.title}</h3>
                {/* <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.whatAreTokens.img}
                    alt="overview-img"
                  />
                </div> */}
                <div className="mt-3">
                  <p>
                    <strong>
                      {textData.introspectionBasedTokenValidation.theIntrospectionFlow.title}
                    </strong>
                  </p>
                  <p
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        textData.introspectionBasedTokenValidation.theIntrospectionFlow
                          .description1,
                    }}
                  />
                                    <p
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        textData.introspectionBasedTokenValidation.theIntrospectionFlow
                          .description2,
                    }}
                  />
                </div>
                <div className="mt-4">
                  <p className="mb-3">
                    <strong>
                      {textData.introspectionBasedTokenValidation.codeWalkthrough.title}
                    </strong>
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={
                      textData.introspectionBasedTokenValidation.codeWalkthrough.code1
                    }
                  />
                  <div className="mt-3">
                    <CodeBlock
                      language="javascript"
                      code={
                        textData.introspectionBasedTokenValidation.codeWalkthrough.code2
                      }
                    />
                  </div>
                  <div className="mt-4">
                    {textData.introspectionBasedTokenValidation.codeWalkthrough.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-3">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <p className="mb-3 mt-2">
                    {
                      textData.introspectionBasedTokenValidation.codeWalkthrough
                        .description
                    }
                  </p>
                </div>
              </section>

              <hr/>

              <section>
                <h3>{textData.jwksBasedTokenValidation.title}</h3>
                <div className="mt-3">
                  <p>
                    <strong>
                      {textData.jwksBasedTokenValidation.theJWKSFlow.title}
                    </strong>
                  </p>
                  <p
                    className="mb-3"
                    dangerouslySetInnerHTML={{
                      __html:
                        textData.jwksBasedTokenValidation.theJWKSFlow
                          .description,
                    }}
                  />
                </div>
                  <div className="d-flex justify-content-center">
                  <img
                    className="w-100"
                    src={textData.jwksBasedTokenValidation.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-4">
                  <p className="mb-3">
                    <strong>
                      {textData.jwksBasedTokenValidation.codeWalkthrough.title}
                    </strong>
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={
                      textData.jwksBasedTokenValidation.codeWalkthrough.code1
                    }
                  />
                  <div className="mt-3">
                    <CodeBlock
                      language="javascript"
                      code={
                        textData.jwksBasedTokenValidation.codeWalkthrough.code2
                      }
                    />
                  </div>
                  <div className="mt-4">
                    {textData.jwksBasedTokenValidation.codeWalkthrough.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-3">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                  <p className="mb-3 mt-2">
                    {
                      textData.jwksBasedTokenValidation.codeWalkthrough
                        .description
                    }
                  </p>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.cachingAndTokenManagement.title}</h3>
                <p className="mb-3" dangerouslySetInnerHTML={{__html : textData.cachingAndTokenManagement.description}} />
                
                {/* <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.whatAreTokens.img}
                    alt="overview-img"
                  />
                </div> */}
                <div className="mt-4">
                  <p className="mb-3">
                    <strong>
                      {textData.cachingAndTokenManagement.codeWalkthrough.title}
                    </strong>
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={
                      textData.cachingAndTokenManagement.codeWalkthrough.code
                    }
                  />
                  <div className="mt-4">
                    {textData.cachingAndTokenManagement.codeWalkthrough.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-3">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <p className="mb-3">
                    <strong>
                      {
                        textData.cachingAndTokenManagement
                          .mainTokenRetrievalLogic.title
                      }
                    </strong>
                  </p>
                  <CodeBlock
                    language="javascript"
                    code={
                      textData.cachingAndTokenManagement.mainTokenRetrievalLogic
                        .code
                    }
                  />
                  <div className="mt-4">
                    {textData.cachingAndTokenManagement.mainTokenRetrievalLogic.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-3">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.Conclusion.title}</h3>
                <p className="mb-3">{textData.Conclusion.description1}</p>
                {/* <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.introduction.img}
                    alt="overview-img"
                  />
                </div> */}
                <div>
                  <ol>
                    {textData.Conclusion.points.map((point, index) => {
                      return (
                        <li
                          key={index}
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      );
                    })}
                  </ol>
                </div>

                <p className="mb-3">{textData.Conclusion.description2}</p>
                <p className="mb-3">{textData.Conclusion.description3}</p>
              </section>
            </div>
          </div>
        </div>
      </div>

      <TechnologyAlliances data={textData.alliacessection} />

      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer8.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer8.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default JWKS;
