import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import HeroComponent from "./HeroComponent";
import TechnologyAlliances from "./TechnologyAlliances";
import MarqueeComponent from "./MarqueeComponent";
import SuccessStoriesList from "./SuccessStoriesList";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.Healthcare}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function Healthcare() {
  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const renderFullPageSection = (data, index) => {
    return (
      <div className="full-page-container">
        <div className="container inner-container">
          <div className="content col-lg-8 col-md-8 col-sm-12 col-xs-12">
            <p data-is-light-text={index % 2 === 0}>{data.body.text1}</p>
            <p className="description">{data.description.text}</p>
            <ContactUsButton
              isLightText={index % 2 === 0 ? true : false}
              link="#ContactForm"
              text={data.button.text}
              className={`${index % 2 === 0 ? "dark" : "light"} mt-3`}
            />
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 image-wrapper">
            <img src={data.img} alt="img" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div data-aos="fade-up" className="healthcarepage">
      <Helmet>
        <meta
          name="description"
          content="Leading Digital Transformation in Healthcare"
        />
        <title>Healthcare | Engineersmind</title>
      </Helmet>
      {/* <div className="container-fluid m-0 p-0 brd-crmb">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home </a></li>
                                    <li className="breadcrumb-item"><a href="/Industries/Financial-Services">Industries </a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Healthcare</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div> */}

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.img}
        textContent={
          <h1>
            {textData.layer1.heading.text1}{" "}
            <span>
              {textData.layer1.heading.text2}
              <br />
            </span>
            {textData.layer1.heading.text3}
          </h1>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home </a>
            </li>
            <li className="breadcrumb-item">
              <a href="/Industries/Financial-Services">Industries </a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Healthcare
            </li>
          </ol>
        }
      />

      {/* <div className="container-fluid healthcare">
                <div className="container">
                    <div className="row">
                        <div className="healthcare-inner">
                            <h1><span>{textData.layer2.heading.text1}</span>{textData.layer2.heading.text2}</h1>
                            <p>{textData.layer2.body}</p>
                            <a href="#ContactForm">{textData.layer2.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="sticky-layers">
        {renderFullPageSection(textData.subHeaderSection.details.detail1, 1)}
      </div>

      <div className="alliances d-flex flex-column justify-content-center align-items-center ">
        <h1 className="text-white text-center">
          {textData.layer7.heading.text1}
          <span>{textData.layer7.heading.text2}</span>
          {textData.layer7.heading.text3}
        </h1>
        <ContactUsButton
          text={textData.layer7.button.text}
          link="#ContactForm"
          className="light"
        />
      </div>

      {/* <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="ai-text">
                            <h1>{textData.layer3.heading}</h1>
                            <p>{textData.layer3.body}</p>

                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
                        <div className="ai-img">
                            <img src={textData.layer3.img} className="img-fluid" alt="industry" />
                        </div>
                    </div>
                </div>
            </div> */}

      {/* <div className="container-fluid Empathetic">
                <div className="container">
                    <h1><span>{textData.layer4.heading.text1}</span> {textData.layer4.heading.text2}</h1>
                    <p>{textData.layer4.body}</p>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution1}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution2}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution3}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution4}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution5}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution6}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution7}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution8}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution9}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p>{textData.layer4.data.solution10}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution11}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <div className="module-border-wrap">
                                <div className="module">
                                    <p id="pdng">{textData.layer4.data.solution12}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      <div className="container-fluid ModernPortals">
        <div className="container">
          <h1 className="mb-5">
            <span>{textData.engagingDigitalExperiences.title}</span>
          </h1>
          <div className="row modernPortal-left-img-row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.engagingDigitalExperiences.img}
                className="img-fluid ModernPortals-left-img"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <div className="p-0 text-20">
                <p>{textData.engagingDigitalExperiences.description1}</p>
              </div>
              <div className="pt-2 text-20">
                <ul className="healthcare-list">
                  {textData.engagingDigitalExperiences.points.map(
                    (point, index) => {
                      return (
                        <li
                          className="healthcare-list-point"
                          key={index}
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="pt-2 text-20">
                <p>{textData.engagingDigitalExperiences.description2}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals bg-color">
        <div className="container">
          <h1 className="mb-5">
            <span>
              {textData.DigitalTransformationAndWorkflowAutomation.title}
            </span>
          </h1>
          <div className="row modernPortal-right-img-row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <div className="p-0 text-20">
                <p>
                  {
                    textData.DigitalTransformationAndWorkflowAutomation
                      .description1
                  }
                </p>
              </div>
              <div className="pt-2 text-20">
                <ul className="healthcare-list">
                  {textData.DigitalTransformationAndWorkflowAutomation.points.map(
                    (point, index) => {
                      return (
                        <li
                          className="healthcare-list-point"
                          key={index}
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="pt-2 text-20">
                <p>
                  {
                    textData.DigitalTransformationAndWorkflowAutomation
                      .description2
                  }
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.DigitalTransformationAndWorkflowAutomation.img}
                className="img-fluid ModernPortals-left-img"
                alt="Resilient_solutions"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals">
        <div className="container">
          <h1 className="mb-5">
            <span>{textData.DataTransformationAndAIPoweredInsights.title}</span>
          </h1>
          <div className="row modernPortal-left-img-row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.DataTransformationAndAIPoweredInsights.img}
                className="img-fluid ModernPortals-left-img"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <div className="p-0 text-20">
                <p>
                  {textData.DataTransformationAndAIPoweredInsights.description1}
                </p>
              </div>
              <div className="pt-2 text-20">
                <ul className="healthcare-list">
                  {textData.DataTransformationAndAIPoweredInsights.points.map(
                    (point, index) => {
                      return (
                        <li
                          className="healthcare-list-point"
                          key={index}
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="pt-2 text-20">
                <p>
                  {textData.DataTransformationAndAIPoweredInsights.description2}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals bg-color">
        <div className="container">
          <h1 className="mb-5">
            <span>
              {textData.externalIntegrationsAndInteroperability.title}
            </span>
          </h1>
          <div className="row modernPortal-right-img-row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <div className="p-0 text-20">
                <p>
                  {
                    textData.externalIntegrationsAndInteroperability
                      .description1
                  }
                </p>
              </div>
              <div className="pt-2 text-20">
                <ul className="healthcare-list">
                  {textData.externalIntegrationsAndInteroperability.points.map(
                    (point, index) => {
                      return (
                        <li
                          className="healthcare-list-point"
                          key={index}
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="pt-2 text-20">
                <p>
                  {
                    textData.externalIntegrationsAndInteroperability
                      .description2
                  }
                </p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.externalIntegrationsAndInteroperability.img}
                className="img-fluid ModernPortals-left-img img-full-width"
                alt="Resilient_solutions"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals">
        <div className="container">
          <h1 className="mb-5">
            <span>
              {textData.streamlinedClaimsAndEligibilityManagement.title}
            </span>
          </h1>
          <div className="row modernPortal-left-img-row">
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.streamlinedClaimsAndEligibilityManagement.img}
                className="img-fluid ModernPortals-left-img img2"
                alt="Resilient_solutions"
              />
            </div>
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <div className="p-0 text-20">
                <p>
                  {
                    textData.streamlinedClaimsAndEligibilityManagement
                      .description1
                  }
                </p>
              </div>
              <div className="pt-2 text-20">
                <ul className="healthcare-list">
                  {textData.streamlinedClaimsAndEligibilityManagement.points.map(
                    (point, index) => {
                      return (
                        <li
                          className="healthcare-list-point"
                          key={index}
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="pt-2 text-20">
                <p>
                  {
                    textData.streamlinedClaimsAndEligibilityManagement
                      .description2
                  }
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid ModernPortals bg-color">
        <div className="container">
          <h1 className="mb-5">
            <span>{textData.comprehensiveTechnicalSolutions.title}</span>
          </h1>
          <div className="row modernPortal-right-img-row">
            <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12  d-flex flex-column justify-content-center">
              <div className="p-0 text-20">
                <p>{textData.comprehensiveTechnicalSolutions.description1}</p>
              </div>
              <div className="pt-2 text-20">
                <ul className="healthcare-list">
                  {textData.comprehensiveTechnicalSolutions.points.map(
                    (point, index) => {
                      return (
                        <li
                          className="healthcare-list-point"
                          key={index}
                          dangerouslySetInnerHTML={{ __html: point }}
                        />
                      );
                    }
                  )}
                </ul>
              </div>
              <div className="pt-2 text-20">
                <p>{textData.comprehensiveTechnicalSolutions.description2}</p>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12  d-flex justify-content-center align-items-center">
              <img
                src={textData.comprehensiveTechnicalSolutions.img}
                className="img-fluid ModernPortals-left-img"
                alt="Resilient_solutions"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid whyChoose ModernPortals">
        <div className="container">
          <h1>
            <span>{textData.WhyChooseEngineersmind.title}</span>
          </h1>
          <p className="text-20 mb-4">
            {textData.WhyChooseEngineersmind.description1}
          </p>
          <div className="pt-0 text-20">
            <ul className="whychoose-points">
              {textData.WhyChooseEngineersmind.points.map((point, index) => {
                return (
                  <li className="" key={index}>
                    <p dangerouslySetInnerHTML={{ __html: point }}></p>
                  </li>
                );
              })}
            </ul>
          </div>
          <p className="pt-2 text-20">
            {textData.WhyChooseEngineersmind.description2}
          </p>
        </div>
      </div>

      <SuccessStoriesList  StoriesList={textData.storiesList} />

      {/* <div className="container-fluid Resilient">
        <div className="container">
          <div className="success-heading">
            <h1>{textData.layer5.heading}</h1>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div id="carouselExample" className="carousel slide">
                <div className="carousel-inner">
                  {textData.layer5.editable_data.map((item, index) => {
                    if (index === 0) {
                      return (
                        <div
                          className={"carousel-item carousel-item-"
                            .concat(String.toString(index + 1))
                            .concat(" active")}
                        >
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                              <div className="integrations">
                                <img
                                  src={item.img}
                                  className="img-fluid"
                                  alt="industry"
                                />
                              </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                              <div className="integrations-text">
                                <h5>{item.heading}</h5>&nbsp;
                                <p>{item.body}</p>
                                <br />
                                <a href={item.button.url}>
                                  {item.button.text}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          className={"carousel-item carousel-item-".concat(
                            String.toString(index + 1)
                          )}
                        >
                          <div className="row">
                            <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                              <div className="integrations">
                                <img
                                  src={item.img}
                                  className="img-fluid"
                                  alt="industry"
                                />
                              </div>
                            </div>
                            <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                              <div className="integrations-text">
                                <h5>{item.heading}</h5>&nbsp;
                                <p>{item.body}</p>
                                <br />
                                <a href={item.button.url}>
                                  {item.button.text}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="control-btn-13">
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="next"
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                    <span
                      className="carousel-control-next-icon visually-hidden"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">
                      {textData.layer5.button_next}
                    </span>
                  </button>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExample"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon visually-hidden"
                      aria-hidden="true"
                    ></span>
                    <i className="fa-solid fa-arrow-left"></i>
                    <span className="visually-hidden">
                      {textData.layer5.button_previous}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="container-fluid trust">
                <div className="container trusted-img">
                    <div className="row">
                        <h1>{textData.layer6.heading}</h1>
                        <div className="col-lg-12 col-md-12 col-sm-12 ol-xs-">
                            <img src={textData.layer6.img} alt="logos" className="img-fluid" />

                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid alliance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
                            <div className="heading">
                                <h1><span>{textData.layer7.heading.text1}</span>{textData.layer7.heading.text2}<span>{textData.layer7.heading.text3}</span></h1>
                            </div>
                            <div className="more-2">
                                <a href="#ContactForm">{textData.layer7.button.text}<i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
                            <div className="gif">
                                <img src={textData.layer7.button.img} alt="gif" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

      <TechnologyAlliances data={textData.alliacessection} />

      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
                <div className="TA-bg">
                    <div className="container">
                        <div className="row">
                            <h1>{textData.layer8.heading}</h1>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <img src={textData.layer8.img} alt="logos" className="img-fluid" />

                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </div>
  );
}
export default Healthcare;
