
import { useRouteError } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import Header from "./Header";
import Footer from "./Footer";
import { Provider } from "react-redux";
import userContext from "../utils/userContext";
import appStore from "../utils/appStore";

import AOS from 'aos';
import page_json from './config/config.json'
import config from '../config';

const textDataUrl = `${page_json.page_jsons.Body}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

const Error = () => {
  useEffect(() => {
        AOS.init();
    }, []);
  const err = useRouteError();
  const { data, status, statusText } = err;
  const [userName, setUserName] = useState("");

  return (
    <>
      <Provider store={appStore}>
        <userContext.Provider value={{ currentUser: userName, setUserName}}>
          <Header />
          <Helmet>
            <meta name="description" content="Reimagine and Transform your Enterprise, Empower your organization with Data and AI, EM Tech Talent Solutions Your gateway to innovation, excellence & growth" />
            <title>Engineersmind</title>
          </Helmet>
          <div data-aos="fade-up">
            <div id="carouselExampleAutoplaying" className="carousel slide">
              <div className="carousel-inner">
                {
                  textData.editable_carousel_banner.map((item, index) => {
                    if (index === 0 ) {
                    return( 
                      <div className={ "carousel-item carousel-item-".concat(String.toString(index)).concat(" active") }>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img src={item.img} className="d-block w-100" alt="banner-img" />
                            <div className="banner-text">
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
                                    <h1 className="animate__animated animate__fadeInLeft">
                                      Oops<br />
                                      Something went wrong<br /> 
                                      {data}<br />
                                      {`${status} ${statusText}`}<br />
                                    </h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  })
                }
              </div>
            </div>
          </div>
          <Footer />
        </userContext.Provider>
      </Provider>
    </>
  );
};

export default Error;