
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
//import AWS from 'aws-sdk';
import page_json from './config/config.json'
import ReactModal from 'react-modal';
//import { SES } from '@aws-sdk/client-ses';
import ReCaptcha from './ReCaptcha';
import { Helmet } from 'react-helmet';
import ContactForm from './ContactForm';
import ContactUsButton from './ContactUsButton';
import HeroComponent from './HeroComponent';
import config from '../config';

var textDataUrl = `${page_json.page_jsons.Countries}?v=${config.VERSION}`;
var textDataResponse = await fetch(textDataUrl);
const countryList = await textDataResponse.json();

textDataUrl = `${page_json.page_jsons.Careers}?v=${config.VERSION}`;
textDataResponse = await fetch(textDataUrl);
var textData = await textDataResponse.json();

const contactLocsUrl = `${page_json.page_jsons.Contact}?v=${config.VERSION}`;
const contactLocsResponse = await fetch(contactLocsUrl);
const contactLocs = await contactLocsResponse.json();

function Careers() {
	useEffect(() => {
		AOS.init(); // Initialize AOS
	}, []);
	const [activePanel, setActivePanel] = useState(null);
	const [modalButton, setModalButton] = useState(false);
	const [isCaptchaValid, setIsCaptchaValid] = useState(false);
	const [fileError, setFileError] = useState('');
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
	
	let isFileError = false

	useEffect(() => {
		handleChange({})
	}, [isCaptchaValid])

	const customStyles = {
		content: {
			background: 'black',
			color: 'white'
		},
	};
	const [jobName, setJobName] = useState(null);
	const [formData, setFormData] = useState({});
	const handleClose = () => {
		handleChange({
			target: {
				name: 'attachment',
				files: [''],
				value: ''
			}
		})
		setModalButton(false);
		window.location.reload();
	};
	const handleOpen = (event) => {
		setModalButton(true);
		setJobName(event.target.name);
	};
	const togglePanel = (panelId) => {
		setActivePanel(activePanel === panelId ? null : panelId);
	};
	//console.log('env : ',process.env.REACT_APP_AWS_S3_QAT_BUCKET)
	const handleChange = (event) => {
		var value = { ...formData };
		isFileError = checkFileValidation(value?.attachment);
		if (event?.target?.name === "Firstname") {
			value.firstname = event.target.value
		} else if (event?.target?.name === "attachment") {
			value.attachment = event.target.files[0];
			isFileError = checkFileValidation(value?.attachment);
			value.attachmentName = "";
			if (isFileError) {
				value.attachmentName = event.target.value.split(/[\\]+/).pop();
				value.attachmentName = value.attachmentName.replaceAll(" ", "_");
			}
		} else if (event?.target?.name === "Lastname") {
			value.lastname = event.target.value
		} else if (event?.target?.name === "Email") {
			value.email = event.target.value
		} else if (event?.target?.name === "Phone") {
			value.phone = event.target.value
		} else if (event?.target?.name === "City") {
			value.city = event.target.value
		} else if (event?.target?.name === "Country") {
			value.country = event.target.value
		} else if (event?.target?.name === "Comments") {
			value.comment = event.target.value
		} else if (event?.target?.name === "check1") {
			value.check1 = event.currentTarget.checked
		} else if (event?.target?.name === "check2") {
			value.check2 = event.currentTarget.checked
		}
		
		// File validation
		const isFileValid = value.attachment ? checkFileValidation(value.attachment) : false;

		/* if (value.firstname !== "" && value.attachment !== "" && value.lastname !== "" && value.email !== ""
			&& value.phone !== "" && value.city !== "" && value.country !== "" && value.check1 === true && isCaptchaValid && isFileError) {
				console.log(value.firstname);
		console.log(value.attachment);
		console.log(value.lastname);
		console.log(value.email);
		console.log(value.phone);
		console.log(value.city);
		console.log(value.country);
		console.log(value.check1);
		console.log(isCaptchaValid);
		console.log(isFileError);
			document.getElementById("clickButton") && (document.getElementById("clickButton").disabled = false);
		} else {
			document.getElementById("clickButton") && (document.getElementById("clickButton").disabled = true);
		}
		setFormData(value);*/

		const isFormValid =
        value.firstname &&
        value.lastname &&
        value.email &&
        value.phone &&
        value.city &&
        value.country &&
        value.check1 &&
        isCaptchaValid &&
        isFileValid;

		setIsSubmitDisabled(!isFormValid); // Update submit button state
		setFormData(value);
	};
	const btnclick = async (e) => {
		if (formData.firstname !== "") {
			e.preventDefault();
			var bodyTxt = '<h3>New Application for: ' + jobName + textData.layer4.applicant_mail.body.body2 + formData.firstname + textData.layer4.applicant_mail.body.body3 + formData.lastname + textData.layer4.applicant_mail.body.body4 + formData.email + textData.layer4.applicant_mail.body.body5 + formData.phone + textData.layer4.applicant_mail.body.body6 + formData.city + textData.layer4.applicant_mail.body.body7 + formData.country + textData.layer4.applicant_mail.body.body8 + formData.comment
			var body = `<html><body><h3>New Application for: ${jobName}${textData.layer4.applicant_mail.body.body2}${formData.firstname}${textData.layer4.applicant_mail.body.body3}${formData.lastname}${textData.layer4.applicant_mail.body.body4}${formData.email}${textData.layer4.applicant_mail.body.body5}${formData.phone}${textData.layer4.applicant_mail.body.body6}${formData.city}${textData.layer4.applicant_mail.body.body7}${formData.country}${textData.layer4.applicant_mail.body.body8}${formData.comment}${textData.layer4.applicant_mail.body.body9}`;
			//console.log(body);
			var getBase64 = file => {
				return new Promise(resolve => {
					let baseURL = "";
					let reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => {
						baseURL = reader.result;
						resolve(baseURL);
					};
				});
			};
			try {
				const emailAPI = page_json.backend_apis.email_api;
				const result = await getBase64(formData.attachment);
				const attachmentData = result.split(/[,]+/).pop();
				//const apiUrl = emailAPI;
				let emailData = {
					sender: page_json.career_api.fromEmail,
					recipient: page_json.career_api.toEmail,
					subject: `${textData.layer4.applicant_acknowledgement.subject}`,
					body_text: `${bodyTxt}`,
					body_html: `${body}`,
					attachments: [{ "filename": `${formData.attachmentName}`, "content": `${attachmentData}` }],
				};
				//console.log(emailData);
				const response = await fetch(emailAPI, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify(emailData),
				});
				if (response.ok) {
					const responseData = await response.json();
					console.log('Response:', responseData);
					alert('Application sent successfully');
					window.location.reload();
				} else {
					console.error('Error:', response.statusText);
					alert('Query failed, try again!');
				}
			} catch (error) {
				console.error('Exception:', error);
				alert('An error occurred, please try again!');
			}
		}
	};
	const checkFileValidation = (value) => {
		const file = value;
		const allowedExtensions = /(\.pdf|\.doc|\.docx)$/i;
		const maxSizeInBytes = 15 * 1024 * 1024;
		if (value) {
			if (!allowedExtensions.exec(file.name)) {
				setFileError('Invalid file type. Please upload a PDF or Word document.');
				return false
			} else if (file.size > maxSizeInBytes) {
				setFileError('File size exceeds 15 MB. Please upload a smaller file.');
				return false
			} else {
				setFileError(null);
				return true
			}
		}

		return true

	}

	return (
		<div data-aos="fade-up">
			<Helmet>
                <meta name="description" content="Leading the transformation journey with passion and our commitment" />
                <title>Careers | About | Engineersmind</title>
            </Helmet>
			{/* <div className="container-fluid m-0 p-0 brd-crmb">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home </a></li>
									<li className="breadcrumb-item"><a href="/About">About US </a></li>
									<li className="breadcrumb-item active" aria-current="page">Careers</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div> */}

			<HeroComponent
      bgImageMobile={ textData.layer1.mobilebannerimg }
        bgImage={textData.layer1.img}
        textContent={
          <h1>
            {textData.layer1.heading.text1}{" "}
            <span>
              {textData.layer1.heading.text2}
            </span>
             {textData.layer1.heading.text3}
            {textData.layer1.heading.text4}
			<span>
            {textData.layer1.heading.text5}
			</span>
          </h1>
        }
        breadcrumbs={
			<ol className="breadcrumb">
			<li className="breadcrumb-item"><a href="/">Home </a></li>
			<li className="breadcrumb-item"><a href="/About">About US </a></li>
			<li className="breadcrumb-item active" aria-current="page">Careers</li>
		</ol>
        }
      />

			{/* <div className="container-fluid p-0">
				<div className="inner-banner">
					<img src={textData.layer1.img} className="d-block w-100" alt="banner-img" />
					<div className="inner-banner-text">
						<div className="container">
							<div className="row">
								<div className="col-lg-8 col-md-8 col-sm-8 col-xs-8">
									<h1 className="animate__animated animate__fadeInLeft">{textData.layer1.heading.text1} <span>{textData.layer1.heading.text2}</span><br /> {textData.layer1.heading.text3}<br /> {textData.layer1.heading.text4} <span>{textData.layer1.heading.text5}</span></h1>
								</div>
							</div>
							<ContactUsButton />
						</div>
					</div>
				</div>
			</div> */}

			<div className="container-fluid our-values">
				<div className="container">
					<h1>{textData.layer2.heading.text1} <span>{textData.layer2.heading.text2}</span></h1>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values1}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values2}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values3}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values4}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values5}</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
							<div className="module-border-wrap">
								<div className="module">
									<p id="pdng">{textData.layer2.details.values6}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="container-fluid liferay-inner">
				<div className="container">
					<h1>{textData.layer3.heading}</h1>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							<p>{textData.layer3.body.text1}</p>&nbsp;
							<p>{textData.layer3.body.text2}</p>&nbsp;
							<p>{textData.layer3.body.text3}</p>&nbsp;
						</div>

						<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
							{
								textData.layer3.editable_collapsibles.map((item, index) => {
									return (
										<div className="panel panel-default">
											<div className="panel-heading" role="tab" id={"heading" + index}>
												<h4 className="panel-title"><a className={`collapsed ${activePanel === 'collapse' + index ? 'active' : ''}`} role="button" onClick={() => togglePanel('collapse' + index)}
													aria-expanded={activePanel === "collapse" + index ? 'true' : 'false'} aria-controls={"collapse" + index}>{item.job}</a></h4>
											</div>
											<div className={`collapse ${activePanel === 'collapse' + index ? 'show' : ''}`}
												id={"collapse" + index} aria-labelledby={"heading" + index} data-parent="#accordion"> <div id={"collapse" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading" + index}></div>
												<div className="panel-body">
													{/* <p>open</p> */}
													{
														item.details.map((sub_item, sub_index) => {
															if (sub_item.bullets) {
																return (
																	<p>
																		<h5>{sub_item.heading}</h5>
																		<ul className="disc collapseul" >
																			{
																				sub_item.body.map((body_val, body_idx) => {
																					return (
																						<li>{body_val}</li>
																					)
																				})
																			}
																		</ul>
																		<br />
																	</p>
																)
															} else {
																return (
																	<p>
																		<h5>{sub_item.heading}</h5>
																		{
																			sub_item.body.map((body_val, body_idx) => {
																				return (
																					<p>{body_val}</p>
																				)
																			})
																		}
																		<br />
																	</p>
																)
															}
														})
													}
													<div className="apply">
														<a className="btn btn-tertiary" type="button" name={item.job} onClick={handleOpen}>{item.button.text}</a>
													</div>
												</div>
											</div>
										</div>
									)
								})
							}

						</div>
						<ReactModal isOpen={modalButton} style={customStyles} >
							<div><button onClick={handleClose} className='close-btn'>{textData.layer4.closebtn}</button></div>
							<form onSubmit={btnclick} className='contact career-form'>
								<h1>{textData.layer4.heading}{jobName}</h1>
								<div className="row">
									<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<div className="success1">
											<input type="text" value={formData.firstname} onChange={handleChange} className="form-control" name="Firstname" id="FirstName" required />
											<label htmlFor="FirstName" className="form-label">{textData.layer4.form.field1}</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<div className="success1">
											<input type="text" value={formData.lastname} onChange={handleChange} className="form-control" name="Lastname" id="LastName" required />
											<label htmlFor="LastName" className="form-label">{textData.layer4.form.field2}</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<div className="success1">
											<input type="email" value={formData.email} onChange={handleChange} className="form-control" name="Email" id="Email" required />
											<label htmlFor="Email" className="form-label">{textData.layer4.form.field3}</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<div className="success1">
											<input type="number" className="form-control" value={formData.phone} onChange={handleChange} name="Phone" id="Phone" title="Only numbers" pattern="[0-9]{10}" required />
											<label htmlFor="Phone" className="form-label">{textData.layer4.form.field4}</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<div className="success1">
											<input type="text" value={formData.city} onChange={handleChange} className="form-control" name="City" id="City" required />
											<label htmlFor="city" className="form-label">{textData.layer4.form.field5}</label>
										</div>
									</div>
									<div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
										<div className="success1">
											<select id="Country" name="Country" className="form-control" onChange={handleChange} required>
												<option value="">Select</option>
												{
													countryList.data.map(function (item) {
														return (<option value={item.name}>{item.name}</option>)
													})
												}
											</select>
											<label htmlFor="Country" className="form-label">{textData.layer4.form.field6}</label>
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<div className="success1">
											<textarea className="form-control" value={formData.comment} onChange={handleChange} name="Comments" id="Comments" rows="3"></textarea>
											<label htmlFor="Comments" className="form-label">{textData.layer4.form.field7}</label>
										</div>
									</div>
									<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
										<div className="success1">
											<input type="file" accept=".pdf, .doc, .docx" className="form-control" onChange={handleChange} name="attachment" id="attachment" required></input>
											<label htmlFor="attachment" className="form-label" style={{
												marginRight: "12px"
											}}>{textData.layer4.form.field8}</label>
											{fileError && <p className="custom-error form-label">{fileError}</p>}
										</div>
									</div>&nbsp;
									<div className="mb-12 form-check">
										<input type="checkbox" value={formData.agree1} onClick={handleChange} name="check1" className="form-check-input" id="agree1" required />
										<label className="form-check-label" htmlFor="agree1">{textData.layer4.form.field9}</label>
									</div>
									<div className="mb-12 form-check">
										<input type="checkbox" value={formData.agree2} onClick={handleChange} name="check2" className="form-check-input" id="agree2" />
										<label className="form-check-label" htmlFor="agree2">{textData.layer4.form.field10}</label>
									</div>
									<div className="mb-12">
										<ReCaptcha setIsCaptchaValid={setIsCaptchaValid} />
									</div>
									<button type="submit" id="clickButton" className="btn" disabled={isSubmitDisabled} >{textData.layer4.formbutton}</button>
								</div>
							</form>
						</ReactModal>
					</div>
					<div className="container">
						<h1>{textData.layer5.heading}</h1>
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div id="carouselExample" className="carousel slide">
									<div className="carousel-inner">
										{
											textData.layer5.editable_carousel.map((item, index) => {
												if (index === 0) {
													return (
														<div className={"carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active")}>
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																	<div className="card-11">
																		<h5>{item.left.serial_no}</h5>
																		<h6>{item.left.heading}</h6>
																		<p>{item.left.body}</p>&nbsp;
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																	<div className="card-11">
																		<h5>{item.right.serial_no}</h5>
																		<h6>{item.right.heading}</h6>
																		<p>{item.right.body}</p>&nbsp;
																	</div>
																</div>
															</div>
														</div>
													)
												} else {
													return (
														<div className={"carousel-item carousel-item-".concat(String.toString(index + 1))}>
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																	<div className="card-11">
																		<h5>{item.left.serial_no}</h5>
																		<h6>{item.left.heading}</h6>
																		<p>{item.left.body}</p>&nbsp;
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																	<div className="card-11">
																		<h5>{item.right.serial_no}</h5>
																		<h6>{item.right.heading}</h6>
																		<p>{item.right.body}</p>&nbsp;
																	</div>
																</div>
															</div>
														</div>
													)
												}
											})
										}
									</div>
									<div className="control-btn-12">
										<button className="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
											<i className="fa-solid fa-arrow-right"></i>
											<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
											<span className="visually-hidden">{textData.layer5.button_next}</span>
										</button>
										<button className="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
											<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
											<i className="fa-solid fa-arrow-left"></i>
											<span className="visually-hidden">{textData.layer5.button_previous}</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<h1>{textData.layer6.heading} </h1>
						<p>{textData.layer6.body.text1}</p>&nbsp;
						<p>{textData.layer6.body.text2}</p>&nbsp;
						<p>{textData.layer6.body.text3}</p>&nbsp;
					</div>
					<div className="container">
						<h1>{textData.layer7.heading.text1}<br />{textData.layer7.heading.text2}</h1>
						<p>{textData.layer7.body}</p>&nbsp;
						<div className="row">
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<div id="carouselExampleTalent" className="carousel slide">
									<div className="carousel-inner">
										{
											textData.layer7.editable_carousel.map((item, index) => {
												if (index === 0) {
													return (
														<div className={"carousel-item carousel-item-".concat(String.toString(index + 1)).concat(" active")}>
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																	<div className="card-11">
																		<h5>{item.left.serial_no}</h5>
																		<h6>{item.left.heading}</h6>
																		<p>{item.left.body}</p>&nbsp;
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																	<div className="card-11">
																		<h5>{item.right.serial_no}</h5>
																		<h6>{item.right.heading}</h6>
																		<p>{item.right.body}</p>&nbsp;
																	</div>
																</div>
															</div>
														</div>
													)
												} else {
													return (
														<div className={"carousel-item carousel-item-".concat(String.toString(index + 1))}>
															<div className="row">
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																	<div className="card-11">
																		<h5>{item.left.serial_no}</h5>
																		<h6>{item.left.heading}</h6>
																		<p>{item.left.body}</p>&nbsp;
																	</div>
																</div>
																<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
																	<div className="card-11">
																		<h5>{item.right.serial_no}</h5>
																		<h6>{item.right.heading}</h6>
																		<p>{item.right.body}</p>&nbsp;
																	</div>
																</div>
															</div>
														</div>
													)
												}
											})
										}
									</div>
									<div className="control-btn-12">
										<button className="carousel-control-next" type="button" data-bs-target="#carouselExampleTalent" data-bs-slide="next">
											<i className="fa-solid fa-arrow-right"></i>
											<span className="carousel-control-next-icon visually-hidden" aria-hidden="true"></span>
											<span className="visually-hidden">{textData.layer7.button_next}</span>
										</button>
										<button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleTalent" data-bs-slide="prev">
											<span className="carousel-control-prev-icon visually-hidden" aria-hidden="true"></span>
											<i className="fa-solid fa-arrow-left"></i>
											<span className="visually-hidden">{textData.layer7.button_next}</span>
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="container">
						<h1 className='icon-heading'>{textData.layer11.heading}<img className='warning-sign' src={textData.layer11.headingImg} alt="warning_sign" /></h1>
						<p dangerouslySetInnerHTML={{__html: textData.layer11.body.text1 }}></p>&nbsp;
						<p>{textData.layer11.body.text2}</p>&nbsp;
					</div>
				</div>
			</div>&nbsp;

			<div className="container-fluid aboutusloc loc-img">
		    	<div className="container">
					<h5>{contactLocs.layer3.heading}</h5>
					<div className="row locationimages">
						{
							contactLocs.layer3.editableList_locations.map(function(item){
						    	return(
									<div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
									    <div className="location">
											<img src={item.img} alt="gif" className="img-fluid" />
											<div class="hover-text">
												<div class="loc_cityname">{item.name}</div>
												<p><span>{item.address_heading}</span></p>
												<p>{item.address1}</p>
												<p>{item.address2}</p>
												<p>{item.address3}</p>
												<p>{item.address4}</p>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>

			<div className="container-fluid alliance">
				<div className="container">
					<div className="row">
						<div className="col-lg-7 col-md-6 col-sm-12 col-xs-12">
							<div className="heading">
								<h1><span>{textData.layer9.heading.text1}</span>{textData.layer9.heading.text2}<span>{textData.layer9.heading.text3}</span></h1>
							</div>
							<div className="more-2">
								<a href="#ContactForm">{textData.layer9.button.text}<i className="fa-solid fa-arrow-right"></i></a>
							</div>
						</div>
						<div className="col-lg-5 col-md-6 col-sm-12 col-xs-12">
							<div className="gif">
								<img src={textData.layer9.button.img} alt="gif" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className="container-fluid ContactForm" id="ContactForm">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ContactForm />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid tabg alliance">
				<div className="TA-bg">
					<div className="container">
						<div className="row">
							<h1>{textData.layer10.heading}</h1>
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
								<img src={textData.layer10.img} alt="logos" className="img-fluid" />

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

export default Careers;