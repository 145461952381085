import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import HeroComponent from "./HeroComponent";
import MarqueeComponent from "./MarqueeComponent";
import TechnologyAlliances from "./TechnologyAlliances";
import config from '../config';

const textDataUrl = `${page_json.page_jsons.HealthBus}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function HealthBus() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" className="caseStudyPage HealthBus">
      <Helmet>
        <meta
          name="description"
          content="Building a Modern Healthcare Aggregator Platform by leveraging cutting-edge tools to create secure, scalable, and efficient enterprise solution."
        />
        <title>Digital Experiences | Engineersmind</title>
      </Helmet>

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.banner}
        textContent={
          <>
            <p>Case Study</p>
            <h1>
              <span>{textData.layer1.title}</span>
            </h1>
          </>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
            <a href="/Industries/Healthcare">Healthcare</a>
          </li>
            <li className="breadcrumb-item active" aria-current="page">
            Digital Experiences
            </li>
          </ol>
        }
      />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong dangerouslySetInnerHTML={{__html: textData.leftSectionContent.text2}} />
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h3>{textData.introduction.title}</h3>
                <p className="mb-3">{textData.introduction.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.introduction.img}
                    alt="overview-img"
                  />
                </div>
                <p className="mb-3">{textData.introduction.description2}</p>
              </section>


              <div className="alliances d-flex flex-column justify-content-center align-items-center rounded mt-5 mb-5 pl-2 pr-2 bg-white">
                <h1 className="text-black text-center">
                  {textData.layer7.heading.text1}
                  <span>{textData.layer7.heading.text2}</span>
                  {textData.layer7.heading.text3}
                </h1>
                <ContactUsButton
                  text={textData.layer7.button.text}
                  link="#ContactForm"
                  className="light"
                  isLightText = {false}
                />
              </div>


              <section>
                <h3>{textData.problemStatement.title}</h3>
                <p className="mb-3">{textData.problemStatement.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.problemStatement.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                      {
                        textData.problemStatement
                          .pointsTitle
                      }
                  </p>
                  <ul>
                    {textData.problemStatement.points.map(
                      (point, index) => {
                        return <li key={index}>{point}</li>;
                      }
                    )}
                  </ul>
                  </div>
                <p className="mb-3">{textData.problemStatement.description2}</p>
              </section>

              <hr />

              <section>
                <h3>{textData.keyRequirements.title}</h3>
                <p className="mb-3">{textData.keyRequirements.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.keyRequirements.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <div>
                    {textData.keyRequirements.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <p className="mb-3">{feature.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.proposedSolution.title}</h3>
                <p className="mb-3">{textData.proposedSolution.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="mb-3 AIChatbot-introduction-img3"
                    src={textData.proposedSolution.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <p className="mb-3">
                      {
                        textData.proposedSolution
                          .keyFeaturesTitle
                      }
                  </p>
                  <div>
                    {textData.proposedSolution.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <ul>
                              <li>{feature.point1}</li>
                              <li>{feature.point2}</li>
                              {feature.point3 && <li>{feature.point3}</li> }
                              {feature.point4 && <li>{feature.point4}</li> }
                              {feature.point5 && <li>{feature.point5}</li> }
                              {feature.point6 && <li>{feature.point6}</li> }
                              {feature.point7 && <li>{feature.point7}</li> }
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p className="mb-3">{textData.proposedSolution.description2}</p>
              </section>

              <hr />


              <section>
                <h3>{textData.architectureAndTechnicalDetails.title}</h3>
                <p className="mb-3">{textData.architectureAndTechnicalDetails.description1}</p>
                <div className="mt-3">
                  <div>
                  <h4 className="mb-3">
                      {
                        textData.architectureAndTechnicalDetails
                          .overallStructure.title
                      }
                  </h4>
                  <div className="mb-3 overallstructure">
                  {textData.architectureAndTechnicalDetails.overallStructure.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <ul>
                              <li>{feature.point1}</li>
                              <li>{feature.point2}</li>
                              {feature.point3 &&<li>{feature.point3}</li>}
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                    </div>
                    </div>
                  <div>
                  <h4 className="mb-3">
                      {
                        textData.architectureAndTechnicalDetails
                          .securityAndCompliance.title
                      }
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.architectureAndTechnicalDetails.securityAndCompliance.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <ul>
                              <li>{feature.point1}</li>
                              <li>{feature.point2}</li>
                              {feature.point3 &&<li>{feature.point3}</li>}
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                    </div>
                  </div>
                  <div>
                  <h4 className="mb-3">
                      {
                        textData.architectureAndTechnicalDetails
                          .leveragingPreBuiltComponents.title
                      }
                  </h4>
                  <p className="mb-3">{textData.architectureAndTechnicalDetails.leveragingPreBuiltComponents.description}</p>
                  <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.architectureAndTechnicalDetails.leveragingPreBuiltComponents.img}
                    alt="overview-img"
                  />
                </div>
                  {textData.architectureAndTechnicalDetails.leveragingPreBuiltComponents.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <p className="mb-3">{feature.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.implementationApproach.title}</h3>
                <p className="mb-3">{textData.implementationApproach.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img2"
                    src={textData.implementationApproach.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                  <div className="mb-3">
                    {textData.implementationApproach.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-3">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <p className="mb-3">{textData.implementationApproach.description2}</p>
               </section>

               <hr />

               <section>
                <h3>{textData.timelineAndMilestones.title}</h3>
                <p className="mb-3">{textData.timelineAndMilestones.description}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img"
                    src={textData.timelineAndMilestones.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                    {textData.timelineAndMilestones.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <ul>
                              <li>{feature.point1}</li>
                              <li>{feature.point2}</li>
                              <li>{feature.point3}</li>
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.businessImpact.title}</h3>
                <p className="mb-3">{textData.businessImpact.description}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img2"
                    src={textData.businessImpact.img}
                    alt="overview-img"
                  />
                </div>
                <div className="mt-3">
                    {textData.businessImpact.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <ul>
                              <li>{feature.point1}</li>
                              <li>{feature.point2}</li>
                            </ul>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.conclusion.title}</h3>
                <p className="mb-3">{textData.conclusion.description1}</p>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img2"
                    src={textData.conclusion.img}
                    alt="overview-img"
                  />
                </div>
                  <div className="mt-3">
                  <p className="mb-3">
                      {
                        textData.conclusion
                          .keyFeaturesTitle
                      }
                  </p>
                  <div className="mb-3">
                    {textData.conclusion.keyFeatures.map((feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                              <p className="mb-3">{feature.description}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <p className="mb-3">{textData.conclusion.description2}</p>
              </section>





            </div>
          </div>
        </div>
      </div>

      <TechnologyAlliances data={textData.alliacessection} />

      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container-fluid tabg alliance">
        <div className="TA-bg">
          <div className="container">
            <div className="row">
              <h1>{textData.layer8.heading}</h1>
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <img
                  src={textData.layer8.img}
                  alt="logos"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default HealthBus;
