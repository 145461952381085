import React, { useEffect } from "react";
import AOS from "aos";
import page_json from "./config/config.json";
import ContactForm from "./ContactForm";
import { Helmet } from "react-helmet";
import ContactUsButton from "./ContactUsButton";
import HeroComponent from "./HeroComponent";
import MarqueeComponent from "./MarqueeComponent";
import TechnologyAlliances from "./TechnologyAlliances";
import config from "../config";

const textDataUrl = `${page_json.page_jsons.AIInHealthcare}?v=${config.VERSION}`;
const textDataResponse = await fetch(textDataUrl);
const textData = await textDataResponse.json();

function AIInHealthcare() {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <div data-aos="fade-up" className="caseStudyPage AIInHealthcare">
      <Helmet>
        <meta
          name="description"
          content="Implementing AI-driven solutions to personalize patient education, enhance engagement, and optimize content distribution for better healthcare outcomes."
        />
        <title>Revolutionizing Healthcare Content Delivery with AI | Engineersmind</title>
      </Helmet>

      <HeroComponent
        bgImageMobile={textData.layer1.mobilebannerimg}
        bgImage={textData.layer1.banner}
        textContent={
          <>
            <p>Case Study</p>
            <h1>
              <span>{textData.layer1.title}</span>
            </h1>
          </>
        }
        breadcrumbs={
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/">Home</a>
            </li>
            <li className="breadcrumb-item">
            <a href="/Industries/Healthcare">Healthcare</a>
          </li>
            <li className="breadcrumb-item active" aria-current="page">
              AI-In-Healthcare
            </li>
          </ol>
        }
      />

      <div className="container-fluid m-0 caseStudyContent">
        <div className="container">
          <div className="row">
            <div className="col-lg-2 col-md-12 col-sm-12 col-xs-12">
              <div className="d-flex flex-column leftSection w-max">
                <div className="logo-container">
                  <a href="/">
                    <img src={textData.leftSectionContent.logo} alt="EM_Logo" />
                  </a>
                </div>
                <p className="mt-1">
                  {textData.leftSectionContent.text1}{" "}
                  <strong
                    dangerouslySetInnerHTML={{
                      __html: textData.leftSectionContent.text2,
                    }}
                  />
                </p>
                <hr />
                <p>{textData.leftSectionContent.text3}</p>
                <hr className="mb-5" />
              </div>
            </div>

            <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12">
              <section>
                <h3>{textData.overview.title}</h3>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img mt-3"
                    src={textData.overview.img}
                    alt="overview-img"
                  />
                </div>
                <p className="mb-3">{textData.overview.description1}</p>
                <p className="mb-3">{textData.overview.description2}</p>
              </section>

              <div className="alliances d-flex flex-column justify-content-center align-items-center rounded mt-5 mb-5 pl-2 pr-2 bg-white">
                <h1 className="text-black text-center">
                  {textData.layer7.heading.text1}
                  <span>{textData.layer7.heading.text2}</span>
                  {textData.layer7.heading.text3}
                </h1>
                <ContactUsButton
                  text={textData.layer7.button.text}
                  link="#ContactForm"
                  className="light"
                  isLightText={false}
                />
              </div>

              <section>
                <h3>
                  {
                    textData.overview.challengesInHealthcareContentDelivery
                      .title
                  }
                </h3>
                <ul>
                  {textData.overview.challengesInHealthcareContentDelivery.points.map(
                    (point, index) => {
                      return <li key={index}>{point}</li>;
                    }
                  )}
                </ul>
              </section>

              <hr />

              <section>
                <h3>
                  {
                    textData.HowEngineersmindDeliveredACustomAIPoweredSolution
                      .title
                  }
                </h3>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img mt-3"
                    src={
                      textData.HowEngineersmindDeliveredACustomAIPoweredSolution
                        .img
                    }
                    alt="overview-img"
                  />
                </div>
                <p className="mb-3">
                  {
                    textData.HowEngineersmindDeliveredACustomAIPoweredSolution
                      .description
                  }
                </p>
                <div className="mt-4">
                  <h4 className="mb-3">
                    {
                      textData.HowEngineersmindDeliveredACustomAIPoweredSolution
                        .intelligentContentIntegrationAndCategorization.title
                    }
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.HowEngineersmindDeliveredACustomAIPoweredSolution.intelligentContentIntegrationAndCategorization.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-3">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="mb-3">
                    {
                      textData.HowEngineersmindDeliveredACustomAIPoweredSolution
                        .personalizedContentRecommendations.title
                    }
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.HowEngineersmindDeliveredACustomAIPoweredSolution.personalizedContentRecommendations.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-2">{feature.description}</p>
                              {feature.points && (
                                <ul>
                                  {feature.points.map((point, index) => {
                                    return <li key={index}>{point}</li>;
                                  })}
                                </ul>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="mb-3">
                    {
                      textData.HowEngineersmindDeliveredACustomAIPoweredSolution
                        .aIDrivenChatbotForHealthcareAssistance.title
                    }
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.HowEngineersmindDeliveredACustomAIPoweredSolution.aIDrivenChatbotForHealthcareAssistance.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-2">{feature.description}</p>
                              {feature.points && (
                                <ul>
                                  {feature.points.map((point, index) => {
                                    return <li key={index}>{point}</li>;
                                  })}
                                </ul>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="mb-3">
                    {
                      textData.HowEngineersmindDeliveredACustomAIPoweredSolution
                        .actionableInsightsForHealthcare.title
                    }
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.HowEngineersmindDeliveredACustomAIPoweredSolution.actionableInsightsForHealthcare.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-2">{feature.description}</p>
                              {feature.points && (
                                <ul>
                                  {feature.points.map((point, index) => {
                                    return <li key={index}>{point}</li>;
                                  })}
                                </ul>
                              )}
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.architectureOverview.title}</h3>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img w-75 mt-3 architectureOverview"
                    src={textData.architectureOverview.img}
                    alt="overview-img"
                  />
                </div>
                <p className="mb-3">
                  {textData.architectureOverview.description}
                </p>
                <div className="mt-4">
                  <h4 className="mb-3">
                    {
                      textData.architectureOverview
                        .contentIngestionAndProcessing.title
                    }
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.architectureOverview.contentIngestionAndProcessing.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-2">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="mb-3">
                    {
                      textData.architectureOverview
                        .personalizationAndAIBasedRecommendations.title
                    }
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.architectureOverview.personalizationAndAIBasedRecommendations.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-2">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="mb-3">
                    {
                      textData.architectureOverview
                        .contentDistributionAndEngagement.title
                    }
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.architectureOverview.contentDistributionAndEngagement.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-2">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="mb-3">
                    {
                      textData.architectureOverview
                        .chatbotAndInteractiveLearning.title
                    }
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.architectureOverview.chatbotAndInteractiveLearning.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-2">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h4 className="mb-3">
                    {textData.architectureOverview.analyticsAndMonitoring.title}
                  </h4>
                  <div className="mb-3 overallstructure">
                    {textData.architectureOverview.analyticsAndMonitoring.keyFeatures.map(
                      (feature, index) => {
                        return (
                          <div className="d-flex w-100 list-item" key={index}>
                            <img
                              src={textData.bulletPoints.img}
                              alt="bullet-point"
                            />
                            <div>
                              <p>
                                <strong>{feature.title}</strong>
                              </p>
                              <p className="mb-2">{feature.description}</p>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.impactAndBenefits.title}</h3>
                <div className="mb-3 mt-3 overallstructure">
                  {textData.impactAndBenefits.keyFeatures.map(
                    (feature, index) => {
                      return (
                        <div className="d-flex w-100 list-item" key={index}>
                          <img
                            src={textData.bulletPoints.img}
                            alt="bullet-point"
                          />
                          <div>
                            <p>
                              <strong>{feature.title}</strong>
                            </p>
                            <p className="mb-2">{feature.description}</p>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </section>

              <hr />

              <section>
                <h3>{textData.conclusion.title}</h3>
                <div className="d-flex justify-content-center">
                  <img
                    className="AIChatbot-introduction-img mt-3"
                    src={textData.conclusion.img}
                    alt="overview-img"
                  />
                </div>
                <p className="mb-3">{textData.conclusion.description1}</p>
                <p className="mb-3">{textData.conclusion.description2}</p>
                <p className="mb-3">{textData.conclusion.description3}</p>
              </section>
            </div>
          </div>
        </div>
      </div>

      <TechnologyAlliances data={textData.alliacessection} />
      <MarqueeComponent assets={textData.clients} />

      <div className="container-fluid ContactForm" id="ContactForm">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AIInHealthcare;
